import PropTypes from "prop-types";
import { find, map, merge, pipe, fromPairs, prop, propEq } from "ramda";
import React from "react";
import moment from "moment-timezone";
import $ from "jquery";

import RawChart from "../../../components/RawChart";

import { intervalsAndRowsToC3Columns } from "../../../modules/C3Helpers";

import {
  formatNumberWithUnitType,
  formatDateForTooltip
} from "../../../skeletons/dataVisualization/modules/Chart";

import { timesBetweenDates } from "../../../modules/TimeHelpers";

import DerivesUnitTypePropertiesFromStreams from "../../../modules/DerivesUnitTypePropertiesFromStreams";

const oneDayInSeconds = moment.duration(1, "day").asSeconds();

export default class GraphChart extends React.Component {
  static propTypes = {
    connectNull: PropTypes.bool.isRequired,
    graphs: PropTypes.arrayOf(PropTypes.object).isRequired,
    interval: PropTypes.object.isRequired,
    startMoment: PropTypes.object.isRequired,
    timeZone: PropTypes.string.isRequired,
    zoomSetting: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      dataColumnsAndDeriver: this.getDataColumnsAndDeriver(props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps === this.props) {
      return;
    }

    this.setState({
      dataColumnsAndDeriver: this.getDataColumnsAndDeriver(nextProps)
    });
  }

  shouldComponentUpdate(nextProps) {
    const { props } = this;
    return props.graphs !== nextProps.graphs;
  }

  getDataColumnsAndDeriver = props => {
    const { graphs, startMoment, zoomSetting, interval } = props;

    const intervals = timesBetweenDates(
      startMoment.unix(),
      startMoment
        .clone()
        .add(zoomSetting.timeSpan)
        .unix(),
      interval.asSeconds()
    );

    let rows = map(
      graph =>
        // We could use the graph label here but we use r{n} because we use CSS
        // to make the colors consistent between the graph and the legend on the
        // right.
        merge(graph, { name: graph.id }),
      graphs
    );

    const deriver = new DerivesUnitTypePropertiesFromStreams(rows);

    rows = rows.map(row => deriver.scaleRow(row));

    // This will be used for simulations later.
    // this.props.graphs.forEach((graph) => {
    //   if (graph.projection) {
    //     rows.push({
    //       name: 'p' + graph.value,
    //       data: graph.projection,
    //     });
    //   }
    // });

    const columns = intervalsAndRowsToC3Columns(intervals, rows);

    return { columns, deriver };
  };

  getDataClasses = () => {
    const classes = {};
    this.props.graphs.forEach(graph => {
      classes[`r${graph.value}`] = "real";
      // TODO: projections
      // classes[`p${graph.value}`] = 'projection'
    });
    return classes;
  };

  getLegendHide = () => {
    const { graphs } = this.props;

    return graphs.map(graph => `r${graph.value}`);
  };

  formatXLabel = value => {
    const { timeZone, zoomSetting } = this.props;

    const dateFormat =
      zoomSetting.id <= oneDayInSeconds ? "hh:mm A" : "MM/DD/YY";

    return moment.tz(value, timeZone).format(dateFormat);
  };

  getChartSettings = component => {
    const { timeZone, graphs, connectNull } = component.props;

    const { columns } = this.state.dataColumnsAndDeriver;

    return {
      size: {
        width: 550,
        height: 300
      },
      line: {
        connectNull
      },
      axis: {
        x: {
          tick: {
            count: 7,
            format: this.formatXLabel
          },
          type: "timeseries"
        },
        y: {
          min: -0.01,
          show: false
        }
      },
      color: {
        pattern: map(prop("color"), graphs)
      },
      padding: {
        top: 5,
        left: 50,
        right: 0,
        bottom: 0
      },
      data: {
        x: "x",
        type: "area",
        unload: true,
        colors: pipe(
          map(g => [g.id, g.color]),
          fromPairs
        )(graphs),
        classes: this.getDataClasses(),
        columns,
        names: pipe(
          map(data => [data.id, data.name]),
          fromPairs
        )(component.props.graphs)
      },
      legend: {
        hide: this.getLegendHide()
      },
      grid: {
        focus: {
          show: false
        }
      },
      point: {
        show: true,
        r: 0,
        focus: {
          expand: {
            enabled: true,
            r: 3
          }
        }
      },
      onrendered: this.onRendered,
      tooltip: {
        format: {
          title: formatDateForTooltip(timeZone),
          name: (_, __, id) =>
            find(propEq("id", Number(id)), component.props.graphs).name,
          value: (rawValue, ratio, id) => {
            const { graphs } = component.props;
            const graph = find(propEq("id", Number(id)), graphs);

            const { deriver } = component.state.dataColumnsAndDeriver;
            const value = deriver.unscaleData(graph.unitType, rawValue);

            return formatNumberWithUnitType(value, graph.unitType);
          }
        }
      }
    };
  };

  onRendered = () => {
    $("#chart-visualization-chart")
      .find(".c3-axis-x .domain")
      .attr("d", (idx, val) => val.replace(/\,6/, ",0").replace(/V6/, "V0"))
      .end()
      .find(".c3-axis-y .domain")
      .attr("d", (idx, val) => val.replace(/\-6/g, "0"));
  };

  getID = () => `visualization-chart-${this.props.zoomSetting.id}`;

  render() {
    return (
      <RawChart id={this.getID()} settings={this.getChartSettings(this)} />
    );
  }
}
