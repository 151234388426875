import PropTypes from "prop-types";
import { map, prop } from "ramda";
import React from "react";

import Chart from "../../../components/MiniChart";
import request from "superagent";
import { streamDataToMap } from "../../../modules/StreamHelpers";

import { timesBetweenDates } from "../../../modules/TimeHelpers";
import { arrayMin, arrayMax } from "../../../modules/ArrayHelpers";

export default class extends React.Component {
  static propTypes = {
    endTime: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired,
    site: PropTypes.object.isRequired,
    startTime: PropTypes.number.isRequired
  };

  static contextTypes = {
    authToken: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const { interval, startTime, endTime } = props;

    this.state = {
      intervals: timesBetweenDates(startTime, endTime, interval),
      loading: true
    };
  }

  componentDidMount() {
    const { interval, endTime, site, startTime } = this.props;
    const { authToken } = this.context;

    request
      .get(site.urls.api.stream)
      .setAuthToken(authToken)
      .query({
        interval,
        start_time: startTime,
        end_time: endTime
      })
      .then(res => {
        const root = res.body.stream || res.body.data_stream;
        const { data } = root;

        const values = map(prop("value"), data);

        this.setState({
          loading: false,
          row: {
            id: site.id,
            name: site.name,
            data: streamDataToMap(data),
            max: arrayMax(values),
            min: arrayMin(values),
            unitType: site.unit_type
          }
        });
      });
  }

  chart = () => {
    const { loading, row, intervals } = this.state;

    if (!loading && row) {
      return (
        <Chart
          intervals={intervals}
          row={row}
          width={850}
          height={250}
          yMin={0}
          yMax={Math.ceil(row.max)}
          xTickFormat="%m-%d %I:%M %p"
          unitType={row.unitType}
        />
      );
    }
  };

  render() {
    const { site } = this.props;

    return (
      <div className="row">
        <div className="site-container">
          <table className="streams-view">
            <colgroup>
              <col className="streams-view__meta-col" />
              <col />
            </colgroup>
            <tbody>
              <tr className="streams-view__row">
                <td className="streams-view__stream-info">
                  <h1 className="streams-view__name streams-view__name--small">
                    <a href={site.urls.self}>{site.name}</a>
                  </h1>
                  <p>
                    {site.street_address}
                    <br />
                    {site.zipcode}
                  </p>
                  <ul className="stats-list">
                    <li className="stats-list__item">
                      <strong>{site.counts.data_streams} </strong>
                      DATA STREAMS
                    </li>
                    <li className="stats-list__item">
                      <strong>{site.counts.equipment} </strong>
                      EQUIPMENT
                    </li>
                  </ul>
                </td>
                <td className="streams-view__graph-cell">
                  <div
                    className="energy-usage-graph"
                    id={`site-stream-graph-${site.id}`}
                  >
                    {this.chart()}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
