import { pipe, toPairs, map, join } from "ramda";

export const getParameterByName = param => {
  var name, regex, results;

  name = param.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  results = regex.exec(location.search);

  if (results === null) {
    return null;
  }

  return decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const hashToID = hash => {
  const testNumber = hash.replace("#", "");

  if (isNaN(testNumber)) {
    return null;
  }

  return Number(testNumber);
};

/*
stringifyQueryOptions({ a: 1, b: 2 }) // a=1&b=2
*/
export const stringifyQueryOptions = pipe(
  toPairs,
  map(join("=")),
  join("&")
);
