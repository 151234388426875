import { SET_INTERVAL, SET_ZOOM_SETTING } from "../constants/actionTypes";

import { defaultSetting } from "../../../constants/zoomSettings";

const defaultInterval = defaultSetting.intervals[0];

export default (state = defaultInterval, action) => {
  switch (action.type) {
    case SET_ZOOM_SETTING:
      return action.zoomSetting.intervals[0];
    case SET_INTERVAL:
      return action.interval;
    default:
      return state;
  }
};
