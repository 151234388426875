import { LOAD_DATA_STREAMS_SUCCESS } from "../constants/actionTypes";

export default (state = [], action) => {
  switch (action.type) {
    case LOAD_DATA_STREAMS_SUCCESS:
      return action.data_streams;
    default:
      return state;
  }
};
