import merge from "deepmerge";
import PropTypes from "prop-types";
import React from "react";

import CHART_SETTINGS from "../config/MINI_CHART_SETTINGS";
import { intervalsAndRowsToC3Columns } from "../modules/C3Helpers";
import RawChart from "./RawChart";

export default class extends React.Component {
  static propTypes = {
    height: PropTypes.number,
    intervals: PropTypes.array.isRequired,
    row: PropTypes.object.isRequired,
    unitType: PropTypes.string,
    width: PropTypes.number,
    xTicks: PropTypes.number,
    yMax: PropTypes.number,
    yMin: PropTypes.number
  };

  getSettings = () => {
    const {
      xTicks,
      height,
      intervals,
      row,
      width,
      yMin,
      yMax,
      yTicks,
      unitType
    } = this.props;

    return merge(CHART_SETTINGS, {
      axis: {
        x: {
          tick: {
            count: xTicks || 5
          }
        },
        y: {
          max: yMax,
          min: yMin,
          label: unitType,
          tick: {
            count: yTicks || 10
          }
        }
      },
      line: { connectNull: true },
      data: {
        columns: intervalsAndRowsToC3Columns(intervals, [row])
      },

      size: { width, height }
    });
  };

  render() {
    return <RawChart id={this.props.row.id} settings={this.getSettings()} />;
  }
}
