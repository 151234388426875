import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import Icon from "./Icon";

export default class extends React.Component {
  static propTypes = {
    activeItem: PropTypes.any,
    component: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelectItem: PropTypes.func.isRequired
  };

  renderItem = item => {
    const active = item.id === this.props.activeItem;
    const itemClasses = cx({
      accordion__item: true,
      "accordion__item--news": !!item.news,
      "accordion__item--active": active
    });
    const onClick = () => this.props.onSelectItem(item);
    return (
      <div key={item.id} className={itemClasses}>
        <h4 onClick={onClick}>
          {item.title}
          {!!item.news && <span>{`${item.news} NEW`}</span>}
          <Icon name={active ? "chevron-up" : "chevron-down"} />
        </h4>
        {active && React.createElement(this.props.component, item)}
      </div>
    );
  };

  render() {
    return (
      <div className="accordion">
        {this.props.items.map(this.renderItem, this)}
      </div>
    );
  }
}
