import { map, pipe, prop, uniq, lt } from "ramda";

import { dateFromUnixSeconds } from "./TimeHelpers";
import { isNumeric } from "./NumberHelpers";

const intervalsAndRowsToC3Columns = (intervals, rows) => {
  var intervalsAsDates;

  var columns = rows.map(row => rowToC3ArrayMatchingIntervals(row, intervals));

  intervalsAsDates = map(dateFromUnixSeconds, intervals);
  intervalsAsDates.unshift("x");

  columns.unshift(intervalsAsDates);

  return columns;
};

const multipleUnitTypesInRows = pipe(
  map(prop("unitType")),
  uniq,
  prop("length"),
  lt(1) // Will return true if the length is greater than 1.
);

const rowToC3ArrayMatchingIntervals = (row, intervals) => {
  const { name, data } = row;

  const values = map(unixTime => {
    var value = data[unixTime];

    return isNumeric(value) ? value : null;
  }, intervals);

  values.unshift(name);

  return values;
};

export {
  multipleUnitTypesInRows,
  intervalsAndRowsToC3Columns,
  rowToC3ArrayMatchingIntervals
};
