import { equals, map, prop, curry, join } from "ramda";

export const domainsEq = (a, b) =>
  (!a && !b) || (a && b && a[0].isSame(b[0]) && a[1].isSame(b[1]));

const rowNames = map(prop("name"));

export const rowsContainSameNames = (a, b) => equals(rowNames(a), rowNames(b));

export const signatureFromParams = curry((paramNames, params) => {
  const paramValues = map(paramName => {
    const result = params[paramName];

    if (!result) {
      console.warn(`Expected param ${paramName} to be truthy.`);
    }

    return result;
  }, paramNames);

  return join("-", paramValues);
});
