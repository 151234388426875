// based on https://github.com/willhoag/scroll-to-element/blob/master/index.js

export default function(elem, addOffset, alignment) {
  const body = document.body;
  const html = document.documentElement;
  const elemRect = elem.getBoundingClientRect();
  const clientHeight = html.clientHeight;
  const documentHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  const additionalOffset = addOffset || 0;
  let scrollPosition;
  if (alignment === "bottom") {
    scrollPosition = elemRect.bottom - clientHeight;
  } else if (alignment === "middle") {
    scrollPosition = elemRect.bottom - clientHeight / 2 - elemRect.height / 2;
  } else {
    scrollPosition = elemRect.top;
  }
  scrollPosition = scrollPosition + additionalOffset + window.pageYOffset;

  const maxScrollPosition = documentHeight - clientHeight;
  return Math.min(scrollPosition, maxScrollPosition);
}
