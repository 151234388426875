import {
  LOAD_SITE_SUCCESS,
  SET_START_TIME
} from "../../../skeletons/dataVisualization/constants/actionTypes";

const LOAD_DATA_STREAMS_SUCCESS = "LOAD_DATA_STREAMS_SUCCESS";
const LOAD_STORIES_SUCCESS = "LOAD_STORIES_SUCCESS";
const LOAD_STREAM_DATA = "LOAD_STREAM_DATA";
const SELECT_DATA_STREAM = "SELECT_DATA_STREAM";
const SELECT_ALERT = "SELECT_ALERT";

export {
  LOAD_SITE_SUCCESS,
  SET_START_TIME,
  LOAD_DATA_STREAMS_SUCCESS,
  LOAD_STORIES_SUCCESS,
  LOAD_STREAM_DATA,
  SELECT_DATA_STREAM,
  SELECT_ALERT
};
