import { SET_ZOOM_SETTING } from "../constants/actionTypes";

import { defaultSetting } from "../../../constants/zoomSettings";

export default (state = defaultSetting, action) => {
  switch (action.type) {
    case SET_ZOOM_SETTING:
      return action.zoomSetting;
    default:
      return state;
  }
};
