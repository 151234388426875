(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}

console.warn('Compiled in DEV mode. Follow the advice at https://elm-lang.org/0.19.1/optimize for better performance and smaller assets.');


var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log_UNUSED = F2(function(tag, value)
{
	return value;
});

var _Debug_log = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString_UNUSED(value)
{
	return '<internals>';
}

function _Debug_toString(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash_UNUSED(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.start.line === region.end.line)
	{
		return 'on line ' + region.start.line;
	}
	return 'on lines ' + region.start.line + ' through ' + region.end.line;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**_UNUSED/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**_UNUSED/
	if (typeof x.$ === 'undefined')
	//*/
	/**/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0_UNUSED = 0;
var _Utils_Tuple0 = { $: '#0' };

function _Utils_Tuple2_UNUSED(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3_UNUSED(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr_UNUSED(c) { return c; }
function _Utils_chr(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil_UNUSED = { $: 0 };
var _List_Nil = { $: '[]' };

function _List_Cons_UNUSED(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap(value) { return { $: 0, a: value }; }
function _Json_unwrap(value) { return value.a; }

function _Json_wrap_UNUSED(value) { return value; }
function _Json_unwrap_UNUSED(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**_UNUSED/
	var node = args['node'];
	//*/
	/**/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		message: func(record.message),
		stopPropagation: record.stopPropagation,
		preventDefault: record.preventDefault
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.message;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.stopPropagation;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.preventDefault) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var view = impl.view;
			/**_UNUSED/
			var domNode = args['node'];
			//*/
			/**/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.setup && impl.setup(sendToApp)
			var view = impl.view;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.body);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.title) && (_VirtualDom_doc.title = title = doc.title);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.onUrlChange;
	var onUrlRequest = impl.onUrlRequest;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		setup: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.protocol === next.protocol
							&& curr.host === next.host
							&& curr.port_.a === next.port_.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		init: function(flags)
		{
			return A3(impl.init, flags, _Browser_getUrl(), key);
		},
		view: impl.view,
		update: impl.update,
		subscriptions: impl.subscriptions
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hidden: 'hidden', change: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hidden: 'mozHidden', change: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hidden: 'msHidden', change: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hidden: 'webkitHidden', change: 'webkitvisibilitychange' }
		: { hidden: 'hidden', change: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		scene: _Browser_getScene(),
		viewport: {
			x: _Browser_window.pageXOffset,
			y: _Browser_window.pageYOffset,
			width: _Browser_doc.documentElement.clientWidth,
			height: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		width: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		height: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			scene: {
				width: node.scrollWidth,
				height: node.scrollHeight
			},
			viewport: {
				x: node.scrollLeft,
				y: node.scrollTop,
				width: node.clientWidth,
				height: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			scene: _Browser_getScene(),
			viewport: {
				x: x,
				y: y,
				width: _Browser_doc.documentElement.clientWidth,
				height: _Browser_doc.documentElement.clientHeight
			},
			element: {
				x: x + rect.left,
				y: y + rect.top,
				width: rect.width,
				height: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.multiline) { flags += 'm'; }
	if (options.caseInsensitive) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.expect.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.expect.b, xhr)); });
		$elm$core$Maybe$isJust(request.tracker) && _Http_track(router, xhr, request.tracker.a);

		try {
			xhr.open(request.method, request.url, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.url));
		}

		_Http_configureRequest(xhr, request);

		request.body.a && xhr.setRequestHeader('Content-Type', request.body.a);
		xhr.send(request.body.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.headers; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.timeout.a || 0;
	xhr.responseType = request.expect.d;
	xhr.withCredentials = request.allowCookiesFromOtherDomains;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		url: xhr.responseURL,
		statusCode: xhr.status,
		statusText: xhr.statusText,
		headers: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			sent: event.loaded,
			size: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			received: event.loaded,
			size: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$Maybe$Just = function (a) {
	return {$: 'Just', a: a};
};
var $elm$core$Maybe$Nothing = {$: 'Nothing'};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (node.$ === 'SubTree') {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0.a;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = {$: 'EQ'};
var $elm$core$Basics$GT = {$: 'GT'};
var $elm$core$Basics$LT = {$: 'LT'};
var $elm$core$Result$Err = function (a) {
	return {$: 'Err', a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 'Failure', a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 'Field', a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 'Index', a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 'Ok', a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 'OneOf', a: a};
};
var $elm$core$Basics$False = {$: 'False'};
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 'Field':
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 'Nothing') {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'Index':
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'OneOf':
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 'Array_elm_builtin', a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 'Leaf', a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 'SubTree', a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.nodeListSize) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.tail);
		} else {
			var treeLen = builder.nodeListSize * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.nodeList) : builder.nodeList;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.nodeListSize);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.tail);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{nodeList: nodeList, nodeListSize: (len / $elm$core$Array$branchFactor) | 0, tail: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = {$: 'True'};
var $elm$core$Result$isOk = function (result) {
	if (result.$ === 'Ok') {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 'Normal':
			return 0;
		case 'MayStopPropagation':
			return 1;
		case 'MayPreventDefault':
			return 2;
		default:
			return 3;
	}
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Help$Html$blank = $elm$html$Html$text('');
var $elm$browser$Browser$External = function (a) {
	return {$: 'External', a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 'Internal', a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = function (a) {
	return {$: 'NotFound', a: a};
};
var $elm$url$Url$Http = {$: 'Http'};
var $elm$url$Url$Https = {$: 'Https'};
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {fragment: fragment, host: host, path: path, port_: port_, protocol: protocol, query: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 'Nothing') {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Http,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Https,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0.a;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = function (a) {
	return {$: 'Perform', a: a};
};
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(_Utils_Tuple0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0.a;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return _Utils_Tuple0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(_Utils_Tuple0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0.a;
		return $elm$core$Task$Perform(
			A2($elm$core$Task$map, tagger, task));
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			$elm$core$Task$Perform(
				A2($elm$core$Task$map, toMessage, task)));
	});
var $elm$browser$Browser$element = _Browser_element;
var $alex_tan$loadable$Loadable$Types$Loading = function (a) {
	return {$: 'Loading', a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			$elm$core$Task$Perform(
				A2(
					$elm$core$Task$onError,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Err),
					A2(
						$elm$core$Task$andThen,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
							$elm$core$Result$Ok),
						task))));
	});
var $alex_tan$loadable$Loadable$Types$LoadError = function (a) {
	return {$: 'LoadError', a: a};
};
var $alex_tan$loadable$Loadable$Types$LoadSuccess = F2(
	function (a, b) {
		return {$: 'LoadSuccess', a: a, b: b};
	});
var $alex_tan$loadable$Loadable$Types$resultToMsg = function (result) {
	if (result.$ === 'Ok') {
		var _v1 = result.a;
		var innerModel = _v1.a;
		var innerInitialMsg = _v1.b;
		return A2($alex_tan$loadable$Loadable$Types$LoadSuccess, innerModel, innerInitialMsg);
	} else {
		var e = result.a;
		return $alex_tan$loadable$Loadable$Types$LoadError(e);
	}
};
var $alex_tan$loadable$Loadable$Element$init = F2(
	function (load, flags) {
		return _Utils_Tuple2(
			$alex_tan$loadable$Loadable$Types$Loading(flags),
			A2(
				$elm$core$Task$attempt,
				$alex_tan$loadable$Loadable$Types$resultToMsg,
				load(flags)));
	});
var $alex_tan$loadable$Loadable$Types$ToInner = function (a) {
	return {$: 'ToInner', a: a};
};
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $alex_tan$loadable$Loadable$Element$subscriptions = F2(
	function (innerSubscriptions, outerModel) {
		if (outerModel.$ === 'Loaded') {
			var model = outerModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$alex_tan$loadable$Loadable$Types$ToInner,
				innerSubscriptions(model));
		} else {
			return $elm$core$Platform$Sub$none;
		}
	});
var $alex_tan$loadable$Loadable$Types$Error = function (a) {
	return {$: 'Error', a: a};
};
var $alex_tan$loadable$Loadable$Types$Loaded = function (a) {
	return {$: 'Loaded', a: a};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $alex_tan$loadable$Loadable$Element$update = F4(
	function (innerUpdate, failCmd, msg, outerModel) {
		var _v0 = _Utils_Tuple2(outerModel, msg);
		_v0$3:
		while (true) {
			switch (_v0.b.$) {
				case 'LoadError':
					var e = _v0.b.a;
					var f = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Basics$always($elm$core$Platform$Cmd$none),
						failCmd);
					return _Utils_Tuple2(
						$alex_tan$loadable$Loadable$Types$Error(e),
						f(e));
				case 'LoadSuccess':
					if (_v0.a.$ === 'Loading') {
						var flags = _v0.a.a;
						var _v1 = _v0.b;
						var load = _v1.a;
						var innerInitialMsg = _v1.b;
						return _Utils_Tuple2(
							$alex_tan$loadable$Loadable$Types$Loaded(load),
							A2($elm$core$Platform$Cmd$map, $alex_tan$loadable$Loadable$Types$ToInner, innerInitialMsg));
					} else {
						break _v0$3;
					}
				default:
					if (_v0.a.$ === 'Loaded') {
						var model = _v0.a.a;
						var msg_ = _v0.b.a;
						var _v2 = A2(innerUpdate, msg_, model);
						var newModel = _v2.a;
						var cmd = _v2.b;
						return _Utils_Tuple2(
							$alex_tan$loadable$Loadable$Types$Loaded(newModel),
							A2($elm$core$Platform$Cmd$map, $alex_tan$loadable$Loadable$Types$ToInner, cmd));
					} else {
						break _v0$3;
					}
			}
		}
		return _Utils_Tuple2(outerModel, $elm$core$Platform$Cmd$none);
	});
var $alex_tan$loadable$Loadable$Element$blank = $elm$html$Html$text('');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $alex_tan$loadable$Loadable$Element$view = F4(
	function (loadingView, errorView, innerView, outerModel) {
		switch (outerModel.$) {
			case 'Loading':
				var flags = outerModel.a;
				return A3(
					$elm$core$Maybe$withDefault,
					$elm$core$Basics$always($alex_tan$loadable$Loadable$Element$blank),
					loadingView,
					flags);
			case 'Error':
				var e = outerModel.a;
				return A3(
					$elm$core$Maybe$withDefault,
					$elm$core$Basics$always($alex_tan$loadable$Loadable$Element$blank),
					errorView,
					e);
			default:
				var model = outerModel.a;
				return A2(
					$elm$html$Html$map,
					$alex_tan$loadable$Loadable$Types$ToInner,
					innerView(model));
		}
	});
var $alex_tan$loadable$Loadable$element = function (_v0) {
	var update = _v0.update;
	var view = _v0.view;
	var subscriptions = _v0.subscriptions;
	var load = _v0.load;
	var failCmd = _v0.failCmd;
	var loadingView = _v0.loadingView;
	var errorView = _v0.errorView;
	return $elm$browser$Browser$element(
		{
			init: $alex_tan$loadable$Loadable$Element$init(load),
			subscriptions: $alex_tan$loadable$Loadable$Element$subscriptions(subscriptions),
			update: A2($alex_tan$loadable$Loadable$Element$update, update, failCmd),
			view: A3($alex_tan$loadable$Loadable$Element$view, loadingView, errorView, view)
		});
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Api$Types$errToErrorsList = function (err) {
	switch (err.$) {
		case 'Errors':
			var errs = err.b;
			return errs;
		case 'BadUrl':
			return _List_fromArray(
				['Bad URL']);
		case 'Timeout':
			return _List_fromArray(
				['Timeout']);
		case 'NetworkError':
			return _List_fromArray(
				['Network Error']);
		case 'NotFound':
			return _List_fromArray(
				['Not Found']);
		case 'BadStatus':
			var code = err.a;
			return _List_fromArray(
				[
					'Bad Status ' + $elm$core$String$fromInt(code)
				]);
		default:
			var body = err.a;
			return _List_fromArray(
				['Bad body ' + body]);
	}
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Components$Loadable$errorView = function (e) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin', '50px auto')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('info-alert info-alert--error')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Error while loading'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$ul,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function (a) {
								return A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(a)
										]));
							},
							$author$project$Api$Types$errToErrorsList(e)))
					]))
			]));
};
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 'Nothing') {
		return false;
	} else {
		return true;
	}
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Alerts$Types$AlertConditionNew = {$: 'AlertConditionNew'};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$Asc = F2(
	function (a, b) {
		return {$: 'Asc', a: a, b: b};
	});
var $alex_tan$postgrest_client$Postgrest$Client$asc = function (s) {
	return A2($alex_tan$postgrest_client$Postgrest$Internal$Params$Asc, s, $elm$core$Maybe$Nothing);
};
var $elm$core$Set$Set_elm_builtin = function (a) {
	return {$: 'Set_elm_builtin', a: a};
};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: 'RBEmpty_elm_builtin'};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$empty = $elm$core$Set$Set_elm_builtin($elm$core$Dict$empty);
var $author$project$Api$AlertConditions$Types$AllYear = {$: 'AllYear'};
var $author$project$Api$AlertConditions$Types$AnyTime = {$: 'AnyTime'};
var $author$project$Help$ClockTime$ClockTime = F3(
	function (hour, minute, pm) {
		return {hour: hour, minute: minute, pm: pm};
	});
var $elm$time$Time$Dec = {$: 'Dec'};
var $elm$time$Time$Jan = {$: 'Jan'};
var $author$project$Api$AlertConditions$Types$LessThan = {$: 'LessThan'};
var $author$project$Api$AlertConditions$Types$Me = {$: 'Me'};
var $author$project$Help$MonthDays$MonthDay = F2(
	function (month, day) {
		return {day: day, month: month};
	});
var $author$project$Api$AlertConditions$Types$DaysSelection = F7(
	function (sunday, monday, tuesday, wednesday, thursday, friday, saturday) {
		return {friday: friday, monday: monday, saturday: saturday, sunday: sunday, thursday: thursday, tuesday: tuesday, wednesday: wednesday};
	});
var $author$project$Api$AlertConditions$Types$emptyDaysSelection = A7($author$project$Api$AlertConditions$Types$DaysSelection, true, true, true, true, true, true, true);
var $author$project$Api$AlertConditions$Types$emptyAlertConditionForm = function (dataStreamID) {
	return {
		comparisonType: $author$project$Api$AlertConditions$Types$LessThan,
		conditionValue: '',
		dataStreamID: dataStreamID,
		datesSelection: $author$project$Api$AlertConditions$Types$AllYear,
		daysSelection: $author$project$Api$AlertConditions$Types$emptyDaysSelection,
		endMonthDay: A2($author$project$Help$MonthDays$MonthDay, $elm$time$Time$Dec, 31),
		endTime: A3($author$project$Help$ClockTime$ClockTime, 5, 0, true),
		startMonthDay: A2($author$project$Help$MonthDays$MonthDay, $elm$time$Time$Jan, 1),
		startTime: A3($author$project$Help$ClockTime$ClockTime, 9, 0, false),
		timesSelection: $author$project$Api$AlertConditions$Types$AnyTime,
		userIDs: $elm$core$Set$empty,
		usersSelection: $author$project$Api$AlertConditions$Types$Me
	};
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$Eq = function (a) {
	return {$: 'Eq', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Client$eq = $alex_tan$postgrest_client$Postgrest$Internal$Params$Eq;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $author$project$Api$Types$BadBody = function (a) {
	return {$: 'BadBody', a: a};
};
var $author$project$Api$Types$BadStatus = function (a) {
	return {$: 'BadStatus', a: a};
};
var $author$project$Api$Types$BadUrl = function (a) {
	return {$: 'BadUrl', a: a};
};
var $author$project$Api$Types$NetworkError = {$: 'NetworkError'};
var $author$project$Api$Types$Timeout = {$: 'Timeout'};
var $author$project$Api$Types$fromHttpError = function (e) {
	switch (e.$) {
		case 'BadUrl':
			var url = e.a;
			return $author$project$Api$Types$BadUrl(url);
		case 'Timeout':
			return $author$project$Api$Types$Timeout;
		case 'NetworkError':
			return $author$project$Api$Types$NetworkError;
		case 'BadStatus':
			var s = e.a;
			return $author$project$Api$Types$BadStatus(s);
		default:
			var s = e.a;
			return $author$project$Api$Types$BadBody(s);
	}
};
var $elm$http$Http$BadBody = function (a) {
	return {$: 'BadBody', a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 'BadStatus', a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 'BadUrl', a: a};
};
var $elm$http$Http$NetworkError = {$: 'NetworkError'};
var $elm$http$Http$Timeout = {$: 'Timeout'};
var $alex_tan$postgrest_client$Postgrest$Client$toHttpError = function (e) {
	switch (e.$) {
		case 'Timeout':
			return $elm$http$Http$Timeout;
		case 'BadUrl':
			var s = e.a;
			return $elm$http$Http$BadUrl(s);
		case 'NetworkError':
			return $elm$http$Http$NetworkError;
		case 'BadStatus':
			var i = e.a;
			return $elm$http$Http$BadStatus(i);
		default:
			var s = e.a;
			return $elm$http$Http$BadBody(s);
	}
};
var $author$project$Api$Types$fromPostgrestError = A2($elm$core$Basics$composeR, $alex_tan$postgrest_client$Postgrest$Client$toHttpError, $author$project$Api$Types$fromHttpError);
var $author$project$Api$AlertConditions$Types$AlertCondition = F8(
	function (id, startOffsetFromMidnightInMinutes, endOffsetFromMidnightInMinutes, startDateMonth, endDateMonth, description, subscribers, dataStream) {
		return {dataStream: dataStream, description: description, endDateMonth: endDateMonth, endOffsetFromMidnightInMinutes: endOffsetFromMidnightInMinutes, id: id, startDateMonth: startDateMonth, startOffsetFromMidnightInMinutes: startOffsetFromMidnightInMinutes, subscribers: subscribers};
	});
var $author$project$Api$AlertConditions$Types$DataStream = F2(
	function (id, name) {
		return {id: id, name: name};
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Api$AlertConditions$Decoders$decodeDataStream = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Api$AlertConditions$Types$DataStream,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Help$Json$failType = F2(
	function (typeStr, valStr) {
		return $elm$json$Json$Decode$fail('Trying to decode ' + (typeStr + (', but ' + (valStr + (' is not a valid ' + typeStr)))));
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1.$) {
					case 'LT':
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 'EQ':
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$time$Time$Apr = {$: 'Apr'};
var $elm$time$Time$Aug = {$: 'Aug'};
var $elm$time$Time$Feb = {$: 'Feb'};
var $elm$time$Time$Jul = {$: 'Jul'};
var $elm$time$Time$Jun = {$: 'Jun'};
var $elm$time$Time$Mar = {$: 'Mar'};
var $elm$time$Time$May = {$: 'May'};
var $elm$time$Time$Nov = {$: 'Nov'};
var $elm$time$Time$Oct = {$: 'Oct'};
var $elm$time$Time$Sep = {$: 'Sep'};
var $elm$core$Dict$Black = {$: 'Black'};
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: 'RBNode_elm_builtin', a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = {$: 'Red'};
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Red')) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) && (left.d.$ === 'RBNode_elm_builtin')) && (left.d.a.$ === 'Red')) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1.$) {
				case 'LT':
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 'EQ':
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$Help$Months$numberToMonth = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2(1, $elm$time$Time$Jan),
			_Utils_Tuple2(2, $elm$time$Time$Feb),
			_Utils_Tuple2(3, $elm$time$Time$Mar),
			_Utils_Tuple2(4, $elm$time$Time$Apr),
			_Utils_Tuple2(5, $elm$time$Time$May),
			_Utils_Tuple2(6, $elm$time$Time$Jun),
			_Utils_Tuple2(7, $elm$time$Time$Jul),
			_Utils_Tuple2(8, $elm$time$Time$Aug),
			_Utils_Tuple2(9, $elm$time$Time$Sep),
			_Utils_Tuple2(10, $elm$time$Time$Oct),
			_Utils_Tuple2(11, $elm$time$Time$Nov),
			_Utils_Tuple2(12, $elm$time$Time$Dec)
		]));
var $author$project$Api$AlertConditions$Decoders$monthDayDecoder = F2(
	function (monthField, dayField) {
		return A3(
			$elm$json$Json$Decode$map2,
			$author$project$Help$MonthDays$MonthDay,
			A2(
				$elm$json$Json$Decode$andThen,
				function (i) {
					var _v0 = A2($elm$core$Dict$get, i, $author$project$Help$Months$numberToMonth);
					if (_v0.$ === 'Just') {
						var a = _v0.a;
						return $elm$json$Json$Decode$succeed(a);
					} else {
						return A2(
							$author$project$Help$Json$failType,
							monthField,
							$elm$core$String$fromInt(i));
					}
				},
				A2($elm$json$Json$Decode$field, monthField, $elm$json$Json$Decode$int)),
			A2($elm$json$Json$Decode$field, dayField, $elm$json$Json$Decode$int));
	});
var $author$project$Api$AlertConditions$Decoders$decodeEndDateMonth = A2($author$project$Api$AlertConditions$Decoders$monthDayDecoder, 'end_date_month', 'end_date_day');
var $author$project$Api$AlertConditions$Decoders$decodeStartDateMonth = A2($author$project$Api$AlertConditions$Decoders$monthDayDecoder, 'start_date_month', 'start_date_day');
var $author$project$Api$AlertConditionSubscribers$Types$AlertConditionSubscriber = F2(
	function (id, user) {
		return {id: id, user: user};
	});
var $author$project$Api$Users$Types$User = F2(
	function (id, email) {
		return {email: email, id: id};
	});
var $author$project$Api$Users$Decoders$decodeUnit = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Api$Users$Types$User,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string));
var $author$project$Api$AlertConditionSubscribers$Decoders$decodeUnit = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Api$AlertConditionSubscribers$Types$AlertConditionSubscriber,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'user', $author$project$Api$Users$Decoders$decodeUnit));
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Api$AlertConditions$Decoders$decodeUnit = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'data_stream',
	$author$project$Api$AlertConditions$Decoders$decodeDataStream,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'alert_condition_subscribers',
		$elm$json$Json$Decode$list($author$project$Api$AlertConditionSubscribers$Decoders$decodeUnit),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'description',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'end_date_month',
				$elm$json$Json$Decode$maybe($author$project$Api$AlertConditions$Decoders$decodeEndDateMonth),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'start_date_month',
					$elm$json$Json$Decode$maybe($author$project$Api$AlertConditions$Decoders$decodeStartDateMonth),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'end_offset_from_midnight_in_minutes',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'start_offset_from_midnight_in_minutes',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Api$AlertConditions$Types$AlertCondition)))))))));
var $author$project$Api$AlertConditions$Decoders$decodePlural = A2(
	$elm$json$Json$Decode$field,
	'alert_conditions',
	$elm$json$Json$Decode$list($author$project$Api$AlertConditions$Decoders$decodeUnit));
var $author$project$Api$Bep$Get = function (a) {
	return {$: 'Get', a: a};
};
var $author$project$Api$Bep$get = F2(
	function (url, options) {
		return _Utils_Tuple2(
			url,
			$author$project$Api$Bep$Get(options));
	});
var $author$project$Api$AlertConditions$getPlural = function (siteID) {
	return A2(
		$author$project$Api$Bep$get,
		'/api/alert_conditions',
		{
			decoder: $author$project$Api$AlertConditions$Decoders$decodePlural,
			params: _List_fromArray(
				[
					_Utils_Tuple2(
					'site_id',
					$elm$core$String$fromInt(siteID))
				])
		});
};
var $author$project$Api$Alerts$Types$Alert = F7(
	function (id, alertConditionID, description, occurredAt, metadata, identifier, dataStream) {
		return {alertConditionID: alertConditionID, dataStream: dataStream, description: description, id: id, identifier: identifier, metadata: metadata, occurredAt: occurredAt};
	});
var $author$project$Api$AlertConditions$Types$GreaterThan = {$: 'GreaterThan'};
var $author$project$Api$AlertConditions$Decoders$decodeCondition = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'threshold.less_than':
				return $elm$json$Json$Decode$succeed($author$project$Api$AlertConditions$Types$LessThan);
			case 'threshold.greater_than':
				return $elm$json$Json$Decode$succeed($author$project$Api$AlertConditions$Types$GreaterThan);
			default:
				return A2($author$project$Help$Json$failType, 'identifier', s);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Alerts$Types$DataStream = F3(
	function (id, name, unitType) {
		return {id: id, name: name, unitType: unitType};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Api$Alerts$Decoders$decodeDataStream = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Api$Alerts$Types$DataStream,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'unit_type', $elm$json$Json$Decode$string));
var $author$project$Api$Alerts$Types$AlertMetadata = F2(
	function (condition, value) {
		return {condition: condition, value: value};
	});
var $author$project$Api$Alerts$Types$AlertMetadataCondition = function (value) {
	return {value: value};
};
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Help$Json$decodeResult = function (result) {
	if (result.$ === 'Just') {
		var v = result.a;
		return $elm$json$Json$Decode$succeed(v);
	} else {
		return $elm$json$Json$Decode$fail('decodeResult failed');
	}
};
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Help$Json$floatString = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeR, $elm$core$String$toFloat, $author$project$Help$Json$decodeResult),
	$elm$json$Json$Decode$string);
var $author$project$Help$Json$flexibleFloat = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$elm$json$Json$Decode$float, $author$project$Help$Json$floatString]));
var $author$project$Api$Alerts$Decoders$decodeCondition = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$Alerts$Types$AlertMetadataCondition,
	A2($elm$json$Json$Decode$field, 'value', $author$project$Help$Json$flexibleFloat));
var $author$project$Api$Alerts$Decoders$decodeMetadata = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Api$Alerts$Types$AlertMetadata,
	A2($elm$json$Json$Decode$field, 'condition', $author$project$Api$Alerts$Decoders$decodeCondition),
	A2($elm$json$Json$Decode$field, 'value', $author$project$Help$Json$flexibleFloat));
var $elm$json$Json$Decode$map7 = _Json_map7;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {index: index, match: match, number: number, submatches: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{caseInsensitive: false, multiline: false},
		string);
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 'Bad', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 'Good', a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = function (a) {
	return {$: 'Parser', a: a};
};
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parseA(s0);
				if (_v1.$ === 'Bad') {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p1 = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					var _v2 = callback(a);
					var parseB = _v2.a;
					var _v3 = parseB(s1);
					if (_v3.$ === 'Bad') {
						var p2 = _v3.a;
						var x = _v3.b;
						return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
					} else {
						var p2 = _v3.a;
						var b = _v3.b;
						var s2 = _v3.c;
						return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
					}
				}
			});
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 'ExpectingEnd'};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 'AddRight', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {col: col, contextStack: contextStack, problem: problem, row: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 'Empty'};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.row, s.col, x, s.context));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return _Utils_eq(
				$elm$core$String$length(s.src),
				s.offset) ? A3($elm$parser$Parser$Advanced$Good, false, _Utils_Tuple0, s) : A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		});
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.src);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.offset, offset) < 0,
					_Utils_Tuple0,
					{col: col, context: s0.context, indent: s0.indent, offset: offset, row: row, src: s0.src});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.offset, s.row, s.col, s);
		});
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parse(s0);
				if (_v1.$ === 'Bad') {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p,
						A2(
							func,
							A3($elm$core$String$slice, s0.offset, s1.offset, s0.src),
							a),
						s1);
				}
			});
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 'Problem', a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		});
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$Good, false, a, s);
		});
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (_v0.$ === 'Just') {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = function (a) {
	return {$: 'Posix', a: a};
};
var $elm$time$Time$millisToPosix = $elm$time$Time$Posix;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0.a;
		var parseB = _v1.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v2 = parseA(s0);
				if (_v2.$ === 'Bad') {
					var p = _v2.a;
					var x = _v2.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p1 = _v2.a;
					var a = _v2.b;
					var s1 = _v2.c;
					var _v3 = parseB(s1);
					if (_v3.$ === 'Bad') {
						var p2 = _v3.a;
						var x = _v3.b;
						return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
					} else {
						var p2 = _v3.a;
						var b = _v3.b;
						var s2 = _v3.c;
						return A3(
							$elm$parser$Parser$Advanced$Good,
							p1 || p2,
							A2(func, a, b),
							s2);
					}
				}
			});
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 'Append', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (_v1.$ === 'Good') {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
		});
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 'Done', a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 'Loop', a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 'UnexpectedChar'};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return $elm$parser$Parser$Advanced$Parser(
			function (s) {
				var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.offset, s.src);
				return _Utils_eq(newOffset, -1) ? A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
					$elm$parser$Parser$Advanced$Good,
					true,
					_Utils_Tuple0,
					{col: 1, context: s.context, indent: s.indent, offset: s.offset + 1, row: s.row + 1, src: s.src}) : A3(
					$elm$parser$Parser$Advanced$Good,
					true,
					_Utils_Tuple0,
					{col: s.col + 1, context: s.context, indent: s.indent, offset: newOffset, row: s.row, src: s.src}));
			});
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0.a;
			var _v1 = parse(s0);
			if (_v1.$ === 'Good') {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (step.$ === 'Loop') {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return $elm$parser$Parser$Advanced$Parser(
			function (s) {
				return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
			});
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parse(s0);
				if (_v1.$ === 'Good') {
					var p = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p,
						func(a),
						s1);
				} else {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				}
			});
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 'Done', a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 'Loop', a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (step.$ === 'Loop') {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (_v0.$ === 'Just') {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 'ExpectingSymbol', a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 'Token', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.offset, s.row, s.col, s.src);
			var newOffset = _v1.a;
			var newRow = _v1.b;
			var newCol = _v1.c;
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
				$elm$parser$Parser$Advanced$Good,
				progress,
				_Utils_Tuple0,
				{col: newCol, context: s.context, indent: s.indent, offset: newOffset, row: newRow, src: s.src});
		});
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {col: col, problem: problem, row: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.row, p.col, p.problem);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 'Empty':
					return list;
				case 'AddRight':
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0.a;
		var _v1 = parse(
			{col: 1, context: _List_Nil, indent: 1, offset: 0, row: 1, src: src});
		if (_v1.$ === 'Good') {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (_v0.$ === 'Ok') {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$Help$Json$time = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (dateString) {
				var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(dateString);
				if (_v0.$ === 'Ok') {
					var v = _v0.a;
					return $elm$json$Json$Decode$succeed(v);
				} else {
					return $elm$json$Json$Decode$fail('Expecting an ISO-8601 formatted date+time string');
				}
			},
			A2(
				$elm$json$Json$Decode$map,
				function (timeString) {
					return A2(
						$elm$core$Maybe$withDefault,
						timeString,
						A2(
							$elm$core$Maybe$map,
							function (regex) {
								return A3(
									$elm$regex$Regex$replace,
									regex,
									$elm$core$Basics$always('-'),
									timeString);
							},
							$elm$regex$Regex$fromString('\\.\\d{3}\\-')));
				},
				$elm$json$Json$Decode$string))
		]));
var $author$project$Api$Alerts$Decoders$decodeUnit = A8(
	$elm$json$Json$Decode$map7,
	$author$project$Api$Alerts$Types$Alert,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'alert_condition_id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'occurred_at', $author$project$Help$Json$time),
	A2($elm$json$Json$Decode$field, 'metadata', $author$project$Api$Alerts$Decoders$decodeMetadata),
	A2($elm$json$Json$Decode$field, 'identifier', $author$project$Api$AlertConditions$Decoders$decodeCondition),
	A2($elm$json$Json$Decode$field, 'data_stream', $author$project$Api$Alerts$Decoders$decodeDataStream));
var $author$project$Api$Alerts$Decoders$decodePlural = A2(
	$elm$json$Json$Decode$field,
	'alerts',
	$elm$json$Json$Decode$list($author$project$Api$Alerts$Decoders$decodeUnit));
var $author$project$Api$Alerts$getPlural = function (siteID) {
	return A2(
		$author$project$Api$Bep$get,
		'/api/alerts',
		{
			decoder: $author$project$Api$Alerts$Decoders$decodePlural,
			params: _List_fromArray(
				[
					_Utils_Tuple2(
					'site_id',
					$elm$core$String$fromInt(siteID))
				])
		});
};
var $author$project$Api$DataStreams$Types$DataStream = F8(
	function (id, name, streamType, unitType, equipmentSystemId, metadataType, siteID, hide) {
		return {equipmentSystemId: equipmentSystemId, hide: hide, id: id, metadataType: metadataType, name: name, siteID: siteID, streamType: streamType, unitType: unitType};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Api$DataStreams$Decoders$decodeUnitType = function (s) {
	if (s === 'celsius') {
		return 'fahrenheit';
	} else {
		return s;
	}
};
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $author$project$Help$Json$nullableString = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$null('')
		]));
var $author$project$Api$StreamTypes$Types$BAS = {$: 'BAS'};
var $author$project$Api$StreamTypes$Types$Equipment = {$: 'Equipment'};
var $author$project$Api$StreamTypes$Types$Pulse = {$: 'Pulse'};
var $author$project$Api$StreamTypes$Types$Virtual = {$: 'Virtual'};
var $author$project$Api$StreamTypes$Types$Weather = {$: 'Weather'};
var $author$project$Api$StreamTypes$Decoders$map = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('bas', $author$project$Api$StreamTypes$Types$BAS),
			_Utils_Tuple2('equipment', $author$project$Api$StreamTypes$Types$Equipment),
			_Utils_Tuple2('weather', $author$project$Api$StreamTypes$Types$Weather),
			_Utils_Tuple2('virtual', $author$project$Api$StreamTypes$Types$Virtual),
			_Utils_Tuple2('pulse', $author$project$Api$StreamTypes$Types$Pulse)
		]));
var $author$project$Api$StreamTypes$Decoders$decode = function (streamTypeStr) {
	var _v0 = A2($elm$core$Dict$get, streamTypeStr, $author$project$Api$StreamTypes$Decoders$map);
	if (_v0.$ === 'Just') {
		var r = _v0.a;
		return $elm$json$Json$Decode$succeed(r);
	} else {
		return A2($author$project$Help$Json$failType, 'StreamType', streamTypeStr);
	}
};
var $author$project$Api$StreamTypes$Decoders$streamType = A2($elm$json$Json$Decode$andThen, $author$project$Api$StreamTypes$Decoders$decode, $elm$json$Json$Decode$string);
var $author$project$Api$DataStreams$Decoders$decodeDataStream = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'hide',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'site_id',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'brick_class_name',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'equipment_system_id',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'unit_type',
					A2($elm$json$Json$Decode$map, $author$project$Api$DataStreams$Decoders$decodeUnitType, $author$project$Help$Json$nullableString),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'stream_type',
						$author$project$Api$StreamTypes$Decoders$streamType,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'name',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Api$DataStreams$Types$DataStream)))))))));
var $author$project$Api$DataStreams$Decoders$decodeDataStreams = $elm$json$Json$Decode$list($author$project$Api$DataStreams$Decoders$decodeDataStream);
var $alex_tan$postgrest_client$Postgrest$Internal$URL$BaseURL = function (a) {
	return {$: 'BaseURL', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Internal$Requests$Get = function (a) {
	return {$: 'Get', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Internal$Requests$Request = function (a) {
	return {$: 'Request', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Client$get = F2(
	function (baseURL, _v0) {
		var params = _v0.params;
		var decoder = _v0.decoder;
		return $alex_tan$postgrest_client$Postgrest$Internal$Requests$Request(
			{
				baseURL: $alex_tan$postgrest_client$Postgrest$Internal$URL$BaseURL(baseURL),
				defaultParams: _List_Nil,
				mandatoryParams: _List_Nil,
				options: $alex_tan$postgrest_client$Postgrest$Internal$Requests$Get(decoder),
				overrideParams: params,
				timeout: $elm$core$Maybe$Nothing
			});
	});
var $author$project$Help$Urls$postgrestURL = function (s) {
	return '/rest/' + s;
};
var $author$project$Api$DataStreams$getPlural = function (params) {
	return A2(
		$alex_tan$postgrest_client$Postgrest$Client$get,
		$author$project$Help$Urls$postgrestURL('data_streams_view'),
		{decoder: $author$project$Api$DataStreams$Decoders$decodeDataStreams, params: params});
};
var $author$project$Api$Users$Decoders$decodePlural = A2(
	$elm$json$Json$Decode$field,
	'users',
	$elm$json$Json$Decode$list($author$project$Api$Users$Decoders$decodeUnit));
var $author$project$Api$Users$getPlural = function (siteID) {
	return A2(
		$author$project$Api$Bep$get,
		'/api/users',
		{
			decoder: $author$project$Api$Users$Decoders$decodePlural,
			params: _List_fromArray(
				[
					_Utils_Tuple2(
					'site_id',
					$elm$core$String$fromInt(siteID))
				])
		});
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$Int = function (a) {
	return {$: 'Int', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Client$int = $alex_tan$postgrest_client$Postgrest$Internal$Params$Int;
var $alex_tan$postgrest_client$Postgrest$Internal$JWT$JWT = function (a) {
	return {$: 'JWT', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Internal$JWT$jwt = $alex_tan$postgrest_client$Postgrest$Internal$JWT$JWT;
var $alex_tan$postgrest_client$Postgrest$Client$jwt = $alex_tan$postgrest_client$Postgrest$Internal$JWT$jwt;
var $elm$core$Task$map4 = F5(
	function (func, taskA, taskB, taskC, taskD) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return A2(
							$elm$core$Task$andThen,
							function (c) {
								return A2(
									$elm$core$Task$andThen,
									function (d) {
										return $elm$core$Task$succeed(
											A4(func, a, b, c, d));
									},
									taskD);
							},
							taskC);
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$core$Task$mapError = F2(
	function (convert, task) {
		return A2(
			$elm$core$Task$onError,
			A2($elm$core$Basics$composeL, $elm$core$Task$fail, convert),
			task);
	});
var $alex_tan$postgrest_client$Postgrest$Internal$Params$Order = function (a) {
	return {$: 'Order', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$order = $alex_tan$postgrest_client$Postgrest$Internal$Params$Order;
var $alex_tan$postgrest_client$Postgrest$Client$order = $alex_tan$postgrest_client$Postgrest$Internal$Params$order;
var $alex_tan$postgrest_client$Postgrest$Internal$Params$Param = F2(
	function (a, b) {
		return {$: 'Param', a: a, b: b};
	});
var $alex_tan$postgrest_client$Postgrest$Client$param = $alex_tan$postgrest_client$Postgrest$Internal$Params$Param;
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 'Header', a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$Api$Bep$authTokenHeader = function (_v0) {
	var authToken = _v0.authToken;
	return A2($elm$http$Http$header, 'Authorization', 'Token token=\"' + (authToken + '\"'));
};
var $author$project$Api$Bep$defaultTimeout = $elm$core$Maybe$Nothing;
var $author$project$Api$Bep$deleteURL = F2(
	function (url, id) {
		return url + ('/' + $elm$core$String$fromInt(id));
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 'BadStatus_', a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 'BadUrl_', a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 'GoodStatus_', a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 'NetworkError_'};
var $elm$http$Http$Receiving = function (a) {
	return {$: 'Receiving', a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 'Sending', a: a};
};
var $elm$http$Http$Timeout_ = {$: 'Timeout_'};
var $elm$core$Maybe$isJust = function (maybe) {
	if (maybe.$ === 'Just') {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.e.d.$ === 'RBNode_elm_builtin') && (dict.e.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.d.d.$ === 'RBNode_elm_builtin') && (dict.d.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Black')) {
					if (right.d.$ === 'RBNode_elm_builtin') {
						if (right.d.a.$ === 'Black') {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor.$ === 'Black') {
			if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === 'RBNode_elm_builtin') {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Black')) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === 'RBNode_elm_builtin') {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBNode_elm_builtin') {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === 'RBNode_elm_builtin') {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (_v0.$ === 'Just') {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Api$Types$Errors = F2(
	function (a, b) {
		return {$: 'Errors', a: a, b: b};
	});
var $author$project$Api$Types$NotFound = {$: 'NotFound'};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$Api$Types$errorDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'errors',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
			A2(
			$elm$json$Json$Decode$map,
			function (m) {
				return _List_fromArray(
					[m]);
			},
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string))
		]));
var $author$project$Api$Types$resolution = F2(
	function (decoder, response) {
		switch (response.$) {
			case 'BadUrl_':
				var url = response.a;
				return $elm$core$Result$Err(
					$author$project$Api$Types$BadUrl(url));
			case 'Timeout_':
				return $elm$core$Result$Err($author$project$Api$Types$Timeout);
			case 'NetworkError_':
				return $elm$core$Result$Err($author$project$Api$Types$NetworkError);
			case 'BadStatus_':
				var metadata = response.a;
				var body = response.b;
				if (metadata.statusCode === 404) {
					return $elm$core$Result$Err($author$project$Api$Types$NotFound);
				} else {
					var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$Api$Types$errorDecoder, body);
					if (_v1.$ === 'Ok') {
						var errors = _v1.a;
						return $elm$core$Result$Err(
							A2($author$project$Api$Types$Errors, metadata.statusCode, errors));
					} else {
						return $elm$core$Result$Err(
							$author$project$Api$Types$BadStatus(metadata.statusCode));
					}
				}
			default:
				var body = response.b;
				var _v2 = A2($elm$json$Json$Decode$decodeString, decoder, body);
				if (_v2.$ === 'Ok') {
					var value = _v2.a;
					return $elm$core$Result$Ok(value);
				} else {
					var err = _v2.a;
					return $elm$core$Result$Err(
						$author$project$Api$Types$BadBody(
							$elm$json$Json$Decode$errorToString(err)));
				}
		}
	});
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$Api$Types$jsonResolver = A2($elm$core$Basics$composeL, $elm$http$Http$stringResolver, $author$project$Api$Types$resolution);
var $author$project$Help$Urls$paramToString = function (_v0) {
	var param = _v0.a;
	var value = _v0.b;
	return param + ('=' + value);
};
var $author$project$Help$Urls$urlParams = function (params) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $author$project$Help$Urls$paramToString, params));
};
var $author$project$Help$Urls$queryURL = F2(
	function (base, params) {
		return base + ('?' + $author$project$Help$Urls$urlParams(params));
	});
var $elm$http$Http$resultToTask = function (result) {
	if (result.$ === 'Ok') {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		_Utils_Tuple0,
		$elm$http$Http$resultToTask,
		{allowCookiesFromOtherDomains: false, body: r.body, expect: r.resolver, headers: r.headers, method: r.method, timeout: r.timeout, tracker: $elm$core$Maybe$Nothing, url: r.url});
};
var $author$project$Api$Bep$toTask = F2(
	function (apiConfig, _v0) {
		var url = _v0.a;
		var requestType = _v0.b;
		var resolver = function (decoder) {
			return $author$project$Api$Types$jsonResolver(decoder);
		};
		switch (requestType.$) {
			case 'Post':
				var body = requestType.a.body;
				var decoder = requestType.a.decoder;
				return $elm$http$Http$task(
					{
						body: $elm$http$Http$jsonBody(body),
						headers: _List_fromArray(
							[
								$author$project$Api$Bep$authTokenHeader(apiConfig)
							]),
						method: 'POST',
						resolver: resolver(decoder),
						timeout: $author$project$Api$Bep$defaultTimeout,
						url: url
					});
			case 'Get':
				var decoder = requestType.a.decoder;
				var params = requestType.a.params;
				return $elm$http$Http$task(
					{
						body: $elm$http$Http$emptyBody,
						headers: _List_fromArray(
							[
								$author$project$Api$Bep$authTokenHeader(apiConfig)
							]),
						method: 'GET',
						resolver: resolver(decoder),
						timeout: $author$project$Api$Bep$defaultTimeout,
						url: A2($author$project$Help$Urls$queryURL, url, params)
					});
			case 'Patch':
				var body = requestType.a.body;
				var decoder = requestType.a.decoder;
				return $elm$http$Http$task(
					{
						body: $elm$http$Http$jsonBody(body),
						headers: _List_fromArray(
							[
								$author$project$Api$Bep$authTokenHeader(apiConfig)
							]),
						method: 'PATCH',
						resolver: resolver(decoder),
						timeout: $author$project$Api$Bep$defaultTimeout,
						url: url
					});
			default:
				var decoder = requestType.a.decoder;
				var id = requestType.a.id;
				return $elm$http$Http$task(
					{
						body: $elm$http$Http$emptyBody,
						headers: _List_fromArray(
							[
								$author$project$Api$Bep$authTokenHeader(apiConfig)
							]),
						method: 'DELETE',
						resolver: resolver(decoder),
						timeout: $author$project$Api$Bep$defaultTimeout,
						url: A2($author$project$Api$Bep$deleteURL, url, id)
					});
		}
	});
var $alex_tan$postgrest_client$Postgrest$Internal$URL$baseURLToString = function (_v0) {
	var s = _v0.a;
	return s;
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamKey = function (p) {
	switch (p.$) {
		case 'Limit':
			return 'limit';
		case 'Offset':
			return 'offset';
		case 'Param':
			var k = p.a;
			return k;
		case 'Select':
			return 'select';
		case 'Order':
			return 'order';
		case 'Or':
			return 'or';
		case 'And':
			return 'and';
		default:
			var r = p.a;
			var param_ = p.b;
			return r + ('.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamKey(param_));
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$dictifyParams = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		function (p) {
			return _Utils_Tuple2(
				$alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamKey(p),
				p);
		}),
	$elm$core$Dict$fromList);
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$combineParams = F2(
	function (defaults, override) {
		return $elm$core$Dict$values(
			A2(
				$elm$core$Dict$union,
				$alex_tan$postgrest_client$Postgrest$Internal$Params$dictifyParams(override),
				$alex_tan$postgrest_client$Postgrest$Internal$Params$dictifyParams(defaults)));
	});
var $alex_tan$postgrest_client$Postgrest$Internal$Params$concatParams = A2(
	$elm$core$List$foldl,
	F2(
		function (a, acc) {
			return A2($alex_tan$postgrest_client$Postgrest$Internal$Params$combineParams, acc, a);
		}),
	_List_Nil);
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (_v0.$ === 'Just') {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $alex_tan$postgrest_client$Postgrest$Internal$Params$catMaybes = $elm$core$List$filterMap($elm$core$Basics$identity);
var $alex_tan$postgrest_client$Postgrest$Internal$Params$NestedParam = F2(
	function (a, b) {
		return {$: 'NestedParam', a: a, b: b};
	});
var $alex_tan$postgrest_client$Postgrest$Internal$Params$nestedParam = function (path) {
	return $alex_tan$postgrest_client$Postgrest$Internal$Params$NestedParam(
		A2($elm$core$String$join, '.', path));
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$paramToInnerString = function (_v0) {
	var k = _v0.a;
	var v = _v0.b;
	switch (k) {
		case 'and':
			return _Utils_ap(k, v);
		case 'or':
			return _Utils_ap(k, v);
		default:
			return k + ('.' + v);
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$String = function (a) {
	return {$: 'String', a: a};
};
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyValue = F2(
	function (quotes, val) {
		switch (val.$) {
			case 'String':
				var str = val.a;
				return quotes ? ('\"' + ($elm$url$Url$percentEncode(str) + '\"')) : $elm$url$Url$percentEncode(str);
			case 'Int':
				var i = val.a;
				return $elm$core$String$fromInt(i);
			default:
				var l = val.a;
				return A2(
					$elm$core$String$join,
					',',
					A2(
						$elm$core$List$map,
						$alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyValue(quotes),
						l));
		}
	});
var $alex_tan$postgrest_client$Postgrest$Internal$Params$surroundInParens = function (s) {
	return '(' + (s + ')');
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$fullTextSearch = F3(
	function (operator, lang, val) {
		return operator + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, $alex_tan$postgrest_client$Postgrest$Internal$Params$surroundInParens, lang)) + ('.' + A2(
			$alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyValue,
			false,
			$alex_tan$postgrest_client$Postgrest$Internal$Params$String(val))));
	});
var $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyQuoted = $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyValue(true);
var $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyUnquoted = $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyValue(false);
var $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyClause = function (operator) {
	switch (operator.$) {
		case 'Neq':
			var val = operator.a;
			return 'neq.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyUnquoted(val);
		case 'Eq':
			var val = operator.a;
			return 'eq.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyUnquoted(val);
		case 'In':
			var val = operator.a;
			return 'in.(' + ($alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyQuoted(val) + ')');
		case 'Value':
			var val = operator.a;
			return $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyUnquoted(val);
		case 'True':
			return 'is.true';
		case 'False':
			return 'is.false';
		case 'Null':
			return 'is.null';
		case 'LT':
			var val = operator.a;
			return 'lt.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyQuoted(val);
		case 'LTE':
			var val = operator.a;
			return 'lte.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyQuoted(val);
		case 'GT':
			var val = operator.a;
			return 'gt.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyQuoted(val);
		case 'GTE':
			var val = operator.a;
			return 'gte.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyQuoted(val);
		case 'Not':
			var o = operator.a;
			return 'not.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyClause(o);
		case 'Fts':
			var lang = operator.a;
			var val = operator.b;
			return A3($alex_tan$postgrest_client$Postgrest$Internal$Params$fullTextSearch, 'fts', lang, val);
		case 'Like':
			var s = operator.a;
			return 'like.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyQuoted(
				$alex_tan$postgrest_client$Postgrest$Internal$Params$String(s));
		case 'Ilike':
			var s = operator.a;
			return 'ilike.' + $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyQuoted(
				$alex_tan$postgrest_client$Postgrest$Internal$Params$String(s));
		case 'Plfts':
			var lang = operator.a;
			var val = operator.b;
			return A3($alex_tan$postgrest_client$Postgrest$Internal$Params$fullTextSearch, 'plfts', lang, val);
		default:
			var lang = operator.a;
			var val = operator.b;
			return A3($alex_tan$postgrest_client$Postgrest$Internal$Params$fullTextSearch, 'phfts', lang, val);
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyNullOption = $elm$core$Maybe$map(
	function (n_) {
		if (n_.$ === 'NullsFirst') {
			return 'nullsfirst';
		} else {
			return 'nullslast';
		}
	});
var $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifySelect = function (postgrestSelect) {
	if (postgrestSelect.$ === 'Attribute') {
		var attr = postgrestSelect.a;
		return attr;
	} else {
		var resourceName = postgrestSelect.a;
		var attrs = postgrestSelect.c;
		if (!attrs.b) {
			return resourceName;
		} else {
			return resourceName + ('(' + (A2(
				$elm$core$String$join,
				',',
				A2($elm$core$List$map, $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifySelect, attrs)) + ')'));
		}
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$normalizeParam = function (p) {
	if (p.$ === 'Select') {
		var selection = p.a;
		return A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				$alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamKey(p),
				$alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamValue(p)),
			$alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$selectionParams()(selection));
	} else {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				$alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamKey(p),
				$alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamValue(p))
			]);
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamValue = function (p) {
	postgrestParamValue:
	while (true) {
		switch (p.$) {
			case 'Param':
				var clause = p.b;
				return $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyClause(clause);
			case 'Select':
				var attrs = p.a;
				return A2(
					$elm$core$String$join,
					',',
					A2($elm$core$List$map, $alex_tan$postgrest_client$Postgrest$Internal$Params$stringifySelect, attrs));
			case 'Limit':
				var i = p.a;
				return $elm$core$String$fromInt(i);
			case 'Offset':
				var i = p.a;
				return $elm$core$String$fromInt(i);
			case 'Order':
				var os = p.a;
				return A2(
					$elm$core$String$join,
					',',
					A2(
						$elm$core$List$map,
						function (o) {
							if (o.$ === 'Asc') {
								var field = o.a;
								var nullOption = o.b;
								return A2(
									$elm$core$String$join,
									'.',
									$alex_tan$postgrest_client$Postgrest$Internal$Params$catMaybes(
										_List_fromArray(
											[
												$elm$core$Maybe$Just(field),
												$elm$core$Maybe$Just('asc'),
												$alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyNullOption(nullOption)
											])));
							} else {
								var field = o.a;
								var nullOption = o.b;
								return A2(
									$elm$core$String$join,
									'.',
									$alex_tan$postgrest_client$Postgrest$Internal$Params$catMaybes(
										_List_fromArray(
											[
												$elm$core$Maybe$Just(field),
												$elm$core$Maybe$Just('desc'),
												$alex_tan$postgrest_client$Postgrest$Internal$Params$stringifyNullOption(nullOption)
											])));
							}
						},
						os));
			case 'And':
				var c = p.a;
				return $alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$wrapConditions()(c);
			case 'Or':
				var c = p.a;
				return $alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$wrapConditions()(c);
			default:
				var nestedParam_ = p.b;
				var $temp$p = nestedParam_;
				p = $temp$p;
				continue postgrestParamValue;
		}
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$selectionParam = F2(
	function (context, s) {
		if (s.$ === 'Attribute') {
			return _List_Nil;
		} else {
			var name = s.a;
			var options_ = s.b;
			var nested = s.c;
			var newContext = _Utils_ap(
				context,
				_List_fromArray(
					[name]));
			return _Utils_ap(
				A2(
					$elm$core$List$map,
					function (item) {
						var p = A2($alex_tan$postgrest_client$Postgrest$Internal$Params$nestedParam, newContext, item);
						return _Utils_Tuple2(
							$alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamKey(p),
							$alex_tan$postgrest_client$Postgrest$Internal$Params$postgrestParamValue(p));
					},
					options_),
				A2(
					$elm$core$List$concatMap,
					$alex_tan$postgrest_client$Postgrest$Internal$Params$selectionParam(newContext),
					nested));
		}
	});
function $alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$wrapConditions() {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$concatMap($alex_tan$postgrest_client$Postgrest$Internal$Params$normalizeParam),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($alex_tan$postgrest_client$Postgrest$Internal$Params$paramToInnerString),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$String$join(','),
				$alex_tan$postgrest_client$Postgrest$Internal$Params$surroundInParens)));
}
function $alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$selectionParams() {
	return $elm$core$List$concatMap(
		$alex_tan$postgrest_client$Postgrest$Internal$Params$selectionParam(_List_Nil));
}
try {
	var $alex_tan$postgrest_client$Postgrest$Internal$Params$wrapConditions = $alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$wrapConditions();
	$alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$wrapConditions = function () {
		return $alex_tan$postgrest_client$Postgrest$Internal$Params$wrapConditions;
	};
	var $alex_tan$postgrest_client$Postgrest$Internal$Params$selectionParams = $alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$selectionParams();
	$alex_tan$postgrest_client$Postgrest$Internal$Params$cyclic$selectionParams = function () {
		return $alex_tan$postgrest_client$Postgrest$Internal$Params$selectionParams;
	};
} catch ($) {
	throw 'Some top-level definitions from `Postgrest.Internal.Params` are causing infinite recursion:\n\n  ┌─────┐\n  │    wrapConditions\n  │     ↓\n  │    normalizeParam\n  │     ↓\n  │    postgrestParamValue\n  │     ↓\n  │    selectionParams\n  │     ↓\n  │    selectionParam\n  └─────┘\n\nThese errors are very tricky, so read https://elm-lang.org/0.19.1/bad-recursion to learn how to fix it!';}
var $alex_tan$postgrest_client$Postgrest$Internal$Params$normalizeParams = $elm$core$List$concatMap($alex_tan$postgrest_client$Postgrest$Internal$Params$normalizeParam);
var $alex_tan$postgrest_client$Postgrest$Internal$Params$paramToString = function (_v0) {
	var k = _v0.a;
	var v = _v0.b;
	return k + ('=' + v);
};
var $alex_tan$postgrest_client$Postgrest$Internal$Params$toQueryString = A2(
	$elm$core$Basics$composeR,
	$alex_tan$postgrest_client$Postgrest$Internal$Params$normalizeParams,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map($alex_tan$postgrest_client$Postgrest$Internal$Params$paramToString),
		$elm$core$String$join('&')));
var $alex_tan$postgrest_client$Postgrest$Internal$Requests$fullURL = function (_v0) {
	var defaultParams = _v0.defaultParams;
	var overrideParams = _v0.overrideParams;
	var mandatoryParams = _v0.mandatoryParams;
	var baseURL = _v0.baseURL;
	var params = $alex_tan$postgrest_client$Postgrest$Internal$Params$concatParams(
		_List_fromArray(
			[defaultParams, overrideParams, mandatoryParams]));
	return A2(
		$elm$core$String$join,
		'?',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
			_List_fromArray(
				[
					$alex_tan$postgrest_client$Postgrest$Internal$URL$baseURLToString(baseURL),
					$alex_tan$postgrest_client$Postgrest$Internal$Params$toQueryString(params)
				])));
};
var $alex_tan$postgrest_client$Postgrest$Client$BadBody = function (a) {
	return {$: 'BadBody', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Client$BadUrl = function (a) {
	return {$: 'BadUrl', a: a};
};
var $alex_tan$postgrest_client$Postgrest$Client$NetworkError = {$: 'NetworkError'};
var $alex_tan$postgrest_client$Postgrest$Client$Timeout = {$: 'Timeout'};
var $alex_tan$postgrest_client$Postgrest$Client$BadStatus = F2(
	function (a, b) {
		return {$: 'BadStatus', a: a, b: b};
	});
var $alex_tan$postgrest_client$Postgrest$Client$PostgrestErrorJSON = F4(
	function (message, details, hint, code) {
		return {code: code, details: details, hint: hint, message: message};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $alex_tan$postgrest_client$Postgrest$Client$decodePostgrestError = A5(
	$elm$json$Json$Decode$map4,
	$alex_tan$postgrest_client$Postgrest$Client$PostgrestErrorJSON,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string)),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'details', $elm$json$Json$Decode$string)),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'hint', $elm$json$Json$Decode$string)),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'code', $elm$json$Json$Decode$string)));
var $alex_tan$postgrest_client$Postgrest$Client$emptyErrors = A4($alex_tan$postgrest_client$Postgrest$Client$PostgrestErrorJSON, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
var $alex_tan$postgrest_client$Postgrest$Client$badStatusBodyToPostgrestError = F2(
	function (statusCode, body) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $alex_tan$postgrest_client$Postgrest$Client$decodePostgrestError, body);
		if (_v0.$ === 'Ok') {
			var errors = _v0.a;
			return A2($alex_tan$postgrest_client$Postgrest$Client$BadStatus, statusCode, errors);
		} else {
			return A2($alex_tan$postgrest_client$Postgrest$Client$BadStatus, statusCode, $alex_tan$postgrest_client$Postgrest$Client$emptyErrors);
		}
	});
var $alex_tan$postgrest_client$Postgrest$Client$resolution = F2(
	function (decoder, response) {
		switch (response.$) {
			case 'BadUrl_':
				var url_ = response.a;
				return $elm$core$Result$Err(
					$alex_tan$postgrest_client$Postgrest$Client$BadUrl(url_));
			case 'Timeout_':
				return $elm$core$Result$Err($alex_tan$postgrest_client$Postgrest$Client$Timeout);
			case 'NetworkError_':
				return $elm$core$Result$Err($alex_tan$postgrest_client$Postgrest$Client$NetworkError);
			case 'BadStatus_':
				var metadata = response.a;
				var body = response.b;
				return $elm$core$Result$Err(
					A2($alex_tan$postgrest_client$Postgrest$Client$badStatusBodyToPostgrestError, metadata.statusCode, body));
			default:
				var body = response.b;
				var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
				if (_v1.$ === 'Ok') {
					var value_ = _v1.a;
					return $elm$core$Result$Ok(value_);
				} else {
					var err = _v1.a;
					return $elm$core$Result$Err(
						$alex_tan$postgrest_client$Postgrest$Client$BadBody(
							$elm$json$Json$Decode$errorToString(err)));
				}
		}
	});
var $alex_tan$postgrest_client$Postgrest$Client$jsonResolver = A2($elm$core$Basics$composeL, $elm$http$Http$stringResolver, $alex_tan$postgrest_client$Postgrest$Client$resolution);
var $alex_tan$postgrest_client$Postgrest$Internal$Requests$requestTypeToBody = function (r) {
	switch (r.$) {
		case 'Delete':
			return $elm$http$Http$emptyBody;
		case 'Get':
			return $elm$http$Http$emptyBody;
		case 'Post':
			var body = r.a;
			return $elm$http$Http$jsonBody(body);
		default:
			var body = r.a;
			return $elm$http$Http$jsonBody(body);
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$Requests$requestTypeToHTTPMethod = function (r) {
	switch (r.$) {
		case 'Post':
			return 'POST';
		case 'Patch':
			return 'PATCH';
		case 'Delete':
			return 'DELETE';
		default:
			return 'GET';
	}
};
var $alex_tan$postgrest_client$Postgrest$Internal$JWT$jwtHeader = function (_v0) {
	var jwt_ = _v0.a;
	return A2($elm$http$Http$header, 'Authorization', 'Bearer ' + jwt_);
};
var $alex_tan$postgrest_client$Postgrest$Internal$Requests$returnRepresentationHeader = A2($elm$http$Http$header, 'Prefer', 'return=representation');
var $alex_tan$postgrest_client$Postgrest$Internal$Requests$requestTypeToHeaders = F2(
	function (jwt_, r) {
		switch (r.$) {
			case 'Post':
				return _List_fromArray(
					[
						$alex_tan$postgrest_client$Postgrest$Internal$JWT$jwtHeader(jwt_),
						$alex_tan$postgrest_client$Postgrest$Internal$Requests$returnRepresentationHeader
					]);
			case 'Patch':
				return _List_fromArray(
					[
						$alex_tan$postgrest_client$Postgrest$Internal$JWT$jwtHeader(jwt_),
						$alex_tan$postgrest_client$Postgrest$Internal$Requests$returnRepresentationHeader
					]);
			case 'Get':
				return _List_fromArray(
					[
						$alex_tan$postgrest_client$Postgrest$Internal$JWT$jwtHeader(jwt_)
					]);
			default:
				return _List_fromArray(
					[
						$alex_tan$postgrest_client$Postgrest$Internal$JWT$jwtHeader(jwt_)
					]);
		}
	});
var $alex_tan$postgrest_client$Postgrest$Client$toTask = F2(
	function (jwt_, _v0) {
		var o = _v0.a;
		var _v1 = o;
		var options = _v1.options;
		return $elm$http$Http$task(
			{
				body: $alex_tan$postgrest_client$Postgrest$Internal$Requests$requestTypeToBody(options),
				headers: A2($alex_tan$postgrest_client$Postgrest$Internal$Requests$requestTypeToHeaders, jwt_, options),
				method: $alex_tan$postgrest_client$Postgrest$Internal$Requests$requestTypeToHTTPMethod(options),
				resolver: function () {
					switch (options.$) {
						case 'Delete':
							var returning = options.a;
							return $elm$http$Http$stringResolver(
								$elm$core$Basics$always(
									$elm$core$Result$Ok(returning)));
						case 'Get':
							var decoder = options.a;
							return $alex_tan$postgrest_client$Postgrest$Client$jsonResolver(decoder);
						case 'Post':
							var decoder = options.b;
							return $alex_tan$postgrest_client$Postgrest$Client$jsonResolver(decoder);
						default:
							var decoder = options.b;
							return $alex_tan$postgrest_client$Postgrest$Client$jsonResolver(decoder);
					}
				}(),
				timeout: o.timeout,
				url: $alex_tan$postgrest_client$Postgrest$Internal$Requests$fullURL(o)
			});
	});
var $author$project$Alerts$Load$load = function (flags) {
	var _v0 = flags;
	var apiConfig = _v0.apiConfig;
	var siteID = _v0.siteID;
	var alertConditionsTask = A2(
		$author$project$Api$Bep$toTask,
		apiConfig,
		$author$project$Api$AlertConditions$getPlural(siteID));
	var alertsTask = A2(
		$author$project$Api$Bep$toTask,
		apiConfig,
		$author$project$Api$Alerts$getPlural(siteID));
	var dataStreamsTask = A2(
		$elm$core$Task$mapError,
		$author$project$Api$Types$fromPostgrestError,
		A2(
			$alex_tan$postgrest_client$Postgrest$Client$toTask,
			$alex_tan$postgrest_client$Postgrest$Client$jwt(apiConfig.jwt),
			$author$project$Api$DataStreams$getPlural(
				_List_fromArray(
					[
						A2(
						$alex_tan$postgrest_client$Postgrest$Client$param,
						'site_id',
						$alex_tan$postgrest_client$Postgrest$Client$eq(
							$alex_tan$postgrest_client$Postgrest$Client$int(siteID))),
						$alex_tan$postgrest_client$Postgrest$Client$order(
						_List_fromArray(
							[
								$alex_tan$postgrest_client$Postgrest$Client$asc('name')
							]))
					]))));
	var usersTask = A2(
		$author$project$Api$Bep$toTask,
		apiConfig,
		$author$project$Api$Users$getPlural(siteID));
	return A5(
		$elm$core$Task$map4,
		F4(
			function (dataStreams, alertConditions, alerts, users) {
				var filteredAlertConditions = function () {
					var _v1 = flags.dataStreamID;
					if (_v1.$ === 'Just') {
						var id = _v1.a;
						return A2(
							$elm$core$List$filter,
							function (a) {
								return _Utils_eq(a.dataStream.id, id);
							},
							alertConditions);
					} else {
						return alertConditions;
					}
				}();
				return _Utils_Tuple2(
					{
						alertConditionForm: $author$project$Api$AlertConditions$Types$emptyAlertConditionForm(
							A2(
								$elm$core$Maybe$withDefault,
								0,
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.id;
									},
									$elm$core$List$head(dataStreams)))),
						alertConditionPopover: $elm$core$Maybe$Nothing,
						alertConditions: filteredAlertConditions,
						alerts: alerts,
						apiConfig: apiConfig,
						backfillCompleteMessageVisible: false,
						backfilling: false,
						checkedAlertConditions: $elm$core$Set$empty,
						creatingAlertCondition: false,
						dataStreamID: flags.dataStreamID,
						dataStreams: dataStreams,
						deletingAlertConditions: $elm$core$Set$empty,
						errors: _List_Nil,
						offsets: flags.offsets,
						route: $author$project$Alerts$Types$AlertConditionNew,
						updatingUsers: $elm$core$Set$empty,
						userID: flags.userID,
						users: users
					},
					$elm$core$Platform$Cmd$none);
			}),
		dataStreamsTask,
		alertConditionsTask,
		alertsTask,
		usersTask);
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Components$Icons$spinningIcon = function (name) {
	return A2(
		$elm$html$Html$span,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fa fa-spin fa-' + name)
					]),
				_List_Nil)
			]));
};
var $author$project$Components$Icons$spinner = $author$project$Components$Icons$spinningIcon('spinner');
var $author$project$Components$Loadable$loadingView = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('loading-screen')
			]),
		_List_fromArray(
			[
				$author$project$Components$Icons$spinner,
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('loading-screen__text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Loading')
					]))
			]));
};
var $author$project$Alerts$Types$OutsideClick = function (a) {
	return {$: 'OutsideClick', a: a};
};
var $author$project$Help$Html$Target = function (a) {
	return {$: 'Target', a: a};
};
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(_Utils_Tuple0));
};
function $author$project$Help$Html$cyclic$decodeTarget() {
	return A4(
		$elm$json$Json$Decode$map3,
		F3(
			function (a, b, c) {
				return $author$project$Help$Html$Target(
					{id: c, parentNode: b, tagName: a});
			}),
		A2($elm$json$Json$Decode$field, 'tagName', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'parentNode',
			$elm$json$Json$Decode$maybe(
				$elm$json$Json$Decode$lazy(
					function (_v0) {
						return $author$project$Help$Html$cyclic$decodeTarget();
					}))),
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'id',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
					$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
				])));
}
try {
	var $author$project$Help$Html$decodeTarget = $author$project$Help$Html$cyclic$decodeTarget();
	$author$project$Help$Html$cyclic$decodeTarget = function () {
		return $author$project$Help$Html$decodeTarget;
	};
} catch ($) {
	throw 'Some top-level definitions from `Help.Html` are causing infinite recursion:\n\n  ┌─────┐\n  │    decodeTarget\n  └─────┘\n\nThese errors are very tricky, so read https://elm-lang.org/0.19.1/bad-recursion to learn how to fix it!';}
var $elm$browser$Browser$Events$Document = {$: 'Document'};
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 'MySub', a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {pids: pids, subs: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (node.$ === 'Document') {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {event: event, key: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (node.$ === 'Document') {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.pids,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.key;
		var event = _v0.event;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.subs);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, $elm$browser$Browser$Events$Document, 'click');
var $author$project$Alerts$subscriptions = function (_v0) {
	return $elm$browser$Browser$Events$onClick(
		A2(
			$elm$json$Json$Decode$map,
			$author$project$Alerts$Types$OutsideClick,
			A2($elm$json$Json$Decode$field, 'target', $author$project$Help$Html$decodeTarget)));
};
var $author$project$Alerts$Types$BackfillComplete = function (a) {
	return {$: 'BackfillComplete', a: a};
};
var $author$project$Alerts$Types$CreateAlertConditionComplete = function (a) {
	return {$: 'CreateAlertConditionComplete', a: a};
};
var $author$project$Alerts$Types$CreateSubscriberComplete = F3(
	function (a, b, c) {
		return {$: 'CreateSubscriberComplete', a: a, b: b, c: c};
	});
var $author$project$Alerts$Types$DeleteAlertConditionComplete = F2(
	function (a, b) {
		return {$: 'DeleteAlertConditionComplete', a: a, b: b};
	});
var $author$project$Alerts$Types$DeleteSubscriberComplete = F2(
	function (a, b) {
		return {$: 'DeleteSubscriberComplete', a: a, b: b};
	});
var $author$project$Help$ClockTime$applyChange = F2(
	function (change, time) {
		switch (change.$) {
			case 'Hour':
				var i = change.a;
				return _Utils_update(
					time,
					{hour: i});
			case 'Minute':
				var i = change.a;
				return _Utils_update(
					time,
					{minute: i});
			default:
				var i = change.a;
				return _Utils_update(
					time,
					{pm: i});
		}
	});
var $author$project$Help$MonthDays$applyChange = F2(
	function (c, r) {
		if (c.$ === 'ChangeMonth') {
			var m = c.a;
			return _Utils_update(
				r,
				{month: m});
		} else {
			var d = c.a;
			return _Utils_update(
				r,
				{day: d});
		}
	});
var $author$project$Api$Bep$Delete = function (a) {
	return {$: 'Delete', a: a};
};
var $author$project$Api$Bep$DeleteOptions = F2(
	function (id, decoder) {
		return {decoder: decoder, id: id};
	});
var $author$project$Api$Bep$deleteByID = F2(
	function (url, id) {
		return _Utils_Tuple2(
			url,
			$author$project$Api$Bep$Delete(
				A2(
					$author$project$Api$Bep$DeleteOptions,
					id,
					$elm$json$Json$Decode$succeed(id))));
	});
var $author$project$Api$AlertConditionSubscribers$delete = $author$project$Api$Bep$deleteByID('/api/alert_condition_subscribers');
var $author$project$Api$AlertConditions$delete = $author$project$Api$Bep$deleteByID('/api/alert_conditions/');
var $author$project$Help$Records$idNotEqTo = F2(
	function (id, subject) {
		return !_Utils_eq(subject.id, id);
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return $elm$core$Set$Set_elm_builtin(
			A3($elm$core$Dict$insert, key, _Utils_Tuple0, dict));
	});
var $bChiquet$elm_accessors$Accessors$Relation = function (a) {
	return {$: 'Relation', a: a};
};
var $bChiquet$elm_accessors$Accessors$makeOneToOne = F3(
	function (getter, mapper, _v0) {
		var sub = _v0.a;
		return $bChiquet$elm_accessors$Accessors$Relation(
			{
				get: function (_super) {
					return sub.get(
						getter(_super));
				},
				over: F2(
					function (change, _super) {
						return A2(
							mapper,
							sub.over(change),
							_super);
					})
			});
	});
var $author$project$Alerts$Types$map = {
	alertConditionForm: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.alertConditionForm;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						alertConditionForm: f(r.alertConditionForm)
					});
			}))
};
var $author$project$Api$AlertConditions$Types$map = {
	comparisonType: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.comparisonType;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						comparisonType: f(r.comparisonType)
					});
			})),
	conditionValue: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.conditionValue;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						conditionValue: f(r.conditionValue)
					});
			})),
	dataStreamID: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.dataStreamID;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						dataStreamID: f(r.dataStreamID)
					});
			})),
	datesSelection: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.datesSelection;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						datesSelection: f(r.datesSelection)
					});
			})),
	daysSelection: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.daysSelection;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						daysSelection: f(r.daysSelection)
					});
			})),
	endMonthDay: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.endMonthDay;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						endMonthDay: f(r.endMonthDay)
					});
			})),
	endTime: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.endTime;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						endTime: f(r.endTime)
					});
			})),
	startMonthDay: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.startMonthDay;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						startMonthDay: f(r.startMonthDay)
					});
			})),
	startTime: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.startTime;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						startTime: f(r.startTime)
					});
			})),
	timesSelection: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.timesSelection;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						timesSelection: f(r.timesSelection)
					});
			})),
	userIDs: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.userIDs;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						userIDs: f(r.userIDs)
					});
			})),
	usersSelection: A2(
		$bChiquet$elm_accessors$Accessors$makeOneToOne,
		function ($) {
			return $.usersSelection;
		},
		F2(
			function (f, r) {
				return _Utils_update(
					r,
					{
						usersSelection: f(r.usersSelection)
					});
			}))
};
var $bChiquet$elm_accessors$Accessors$id = $bChiquet$elm_accessors$Accessors$Relation(
	{
		get: function (a) {
			return a;
		},
		over: function (change) {
			return function (a) {
				return change(a);
			};
		}
	});
var $bChiquet$elm_accessors$Accessors$over = F3(
	function (accessor, change, s) {
		var _v0 = accessor($bChiquet$elm_accessors$Accessors$id);
		var relation = _v0.a;
		return A2(relation.over, change, s);
	});
var $author$project$Help$Json$decodeUnit = $elm$json$Json$Decode$succeed(_Utils_Tuple0);
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(_Utils_Tuple0),
				entries));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(_Utils_Tuple0),
			pairs));
};
var $author$project$Api$Bep$Post = function (a) {
	return {$: 'Post', a: a};
};
var $author$project$Api$Bep$post = F2(
	function (url, options) {
		return _Utils_Tuple2(
			url,
			$author$project$Api$Bep$Post(options));
	});
var $author$project$Api$AlertBackfills$post = function (ids) {
	return A2(
		$author$project$Api$Bep$post,
		'/api/alert_backfills',
		{
			body: $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'alert_backfill',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'alert_condition_ids',
									A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, ids))
								])))
					])),
			decoder: $author$project$Help$Json$decodeUnit
		});
};
var $author$project$Api$AlertConditionSubscribers$Decoders$decodeSingular = A2($elm$json$Json$Decode$field, 'alert_condition_subscriber', $author$project$Api$AlertConditionSubscribers$Decoders$decodeUnit);
var $author$project$Api$AlertConditionSubscribers$Encoders$encodeInner = function (d) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'user_id',
				$elm$json$Json$Encode$int(d.userID)),
				_Utils_Tuple2(
				'alert_condition_id',
				$elm$json$Json$Encode$int(d.alertConditionID))
			]));
};
var $author$project$Api$AlertConditionSubscribers$Encoders$encode = function (d) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'alert_condition_subscriber',
				$author$project$Api$AlertConditionSubscribers$Encoders$encodeInner(d))
			]));
};
var $author$project$Api$AlertConditionSubscribers$post = function (subscriber) {
	return A2(
		$author$project$Api$Bep$post,
		'/api/alert_condition_subscribers',
		{
			body: $author$project$Api$AlertConditionSubscribers$Encoders$encode(subscriber),
			decoder: $author$project$Api$AlertConditionSubscribers$Decoders$decodeSingular
		});
};
var $author$project$Api$AlertConditions$Decoders$decodeSingular = A2($elm$json$Json$Decode$field, 'alert_condition', $author$project$Api$AlertConditions$Decoders$decodeUnit);
var $author$project$Api$AlertConditions$Types$conditionToString = function (c) {
	if (c.$ === 'LessThan') {
		return 'less_than';
	} else {
		return 'greater_than';
	}
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Api$AlertConditions$Encoders$encodeDaysSelection = function (a) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'sunday',
			$elm$json$Json$Encode$bool(a.sunday)),
			_Utils_Tuple2(
			'monday',
			$elm$json$Json$Encode$bool(a.monday)),
			_Utils_Tuple2(
			'tuesday',
			$elm$json$Json$Encode$bool(a.tuesday)),
			_Utils_Tuple2(
			'wednesday',
			$elm$json$Json$Encode$bool(a.wednesday)),
			_Utils_Tuple2(
			'thursday',
			$elm$json$Json$Encode$bool(a.thursday)),
			_Utils_Tuple2(
			'friday',
			$elm$json$Json$Encode$bool(a.friday)),
			_Utils_Tuple2(
			'saturday',
			$elm$json$Json$Encode$bool(a.saturday))
		]);
};
var $author$project$Help$Months$monthToInt = function (m) {
	switch (m.$) {
		case 'Jan':
			return 1;
		case 'Feb':
			return 2;
		case 'Mar':
			return 3;
		case 'Apr':
			return 4;
		case 'May':
			return 5;
		case 'Jun':
			return 6;
		case 'Jul':
			return 7;
		case 'Aug':
			return 8;
		case 'Sep':
			return 9;
		case 'Oct':
			return 10;
		case 'Nov':
			return 11;
		default:
			return 12;
	}
};
var $author$project$Help$ClockTime$offsetFromMidnightInMinutes = function (_v0) {
	var hour = _v0.hour;
	var minute = _v0.minute;
	var pm = _v0.pm;
	var adjustedHour = function () {
		if (hour === 12) {
			return 0;
		} else {
			return hour;
		}
	}();
	var absoluteHour = pm ? (12 + adjustedHour) : adjustedHour;
	return (absoluteHour * 60) + minute;
};
var $author$project$Api$AlertConditions$Encoders$encodeInner = F2(
	function (userID, a) {
		return $elm$json$Json$Encode$object(
			$elm$core$List$concat(
				_List_fromArray(
					[
						$author$project$Api$AlertConditions$Encoders$encodeDaysSelection(a.daysSelection),
						_List_fromArray(
						[
							_Utils_Tuple2(
							'subscriber_ids',
							A2(
								$elm$json$Json$Encode$list,
								$elm$json$Json$Encode$int,
								function () {
									var _v0 = a.usersSelection;
									switch (_v0.$) {
										case 'SpecificPeople':
											return $elm$core$Set$toList(a.userIDs);
										case 'Me':
											return _List_fromArray(
												[userID]);
										default:
											return _List_Nil;
									}
								}())),
							_Utils_Tuple2(
							'data_stream',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'id',
										$elm$json$Json$Encode$int(a.dataStreamID))
									]))),
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('threshold')),
							_Utils_Tuple2(
							'comparison_type',
							$elm$json$Json$Encode$string(
								$author$project$Api$AlertConditions$Types$conditionToString(a.comparisonType))),
							_Utils_Tuple2(
							'value',
							$elm$json$Json$Encode$string(a.conditionValue))
						]),
						function () {
						var _v1 = a.timesSelection;
						if (_v1.$ === 'AnyTime') {
							return _List_fromArray(
								[
									_Utils_Tuple2(
									'start_offset_from_midnight_in_minutes',
									$elm$json$Json$Encode$int(0)),
									_Utils_Tuple2(
									'end_offset_from_midnight_in_minutes',
									$elm$json$Json$Encode$int(1440))
								]);
						} else {
							return _List_fromArray(
								[
									_Utils_Tuple2(
									'start_offset_from_midnight_in_minutes',
									$elm$json$Json$Encode$int(
										$author$project$Help$ClockTime$offsetFromMidnightInMinutes(a.startTime))),
									_Utils_Tuple2(
									'end_offset_from_midnight_in_minutes',
									$elm$json$Json$Encode$int(
										$author$project$Help$ClockTime$offsetFromMidnightInMinutes(a.endTime)))
								]);
						}
					}(),
						function () {
						var _v2 = a.datesSelection;
						if (_v2.$ === 'AllYear') {
							return _List_Nil;
						} else {
							return _List_fromArray(
								[
									_Utils_Tuple2(
									'start_date_month',
									$elm$json$Json$Encode$int(
										$author$project$Help$Months$monthToInt(a.startMonthDay.month))),
									_Utils_Tuple2(
									'start_date_day',
									$elm$json$Json$Encode$int(a.startMonthDay.day)),
									_Utils_Tuple2(
									'end_date_month',
									$elm$json$Json$Encode$int(
										$author$project$Help$Months$monthToInt(a.endMonthDay.month))),
									_Utils_Tuple2(
									'end_date_day',
									$elm$json$Json$Encode$int(a.endMonthDay.day))
								]);
						}
					}()
					])));
	});
var $author$project$Api$AlertConditions$Encoders$encode = F2(
	function (userID, d) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'alert_condition',
					A2($author$project$Api$AlertConditions$Encoders$encodeInner, userID, d))
				]));
	});
var $author$project$Api$AlertConditions$post = F2(
	function (userID, alert) {
		return A2(
			$author$project$Api$Bep$post,
			'/api/alert_conditions',
			{
				body: A2($author$project$Api$AlertConditions$Encoders$encode, userID, alert),
				decoder: $author$project$Api$AlertConditions$Decoders$decodeSingular
			});
	});
var $author$project$Help$Lists$rejectID = F2(
	function (id, list) {
		return A2(
			$elm$core$List$filter,
			$author$project$Help$Records$idNotEqTo(id),
			list);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return $elm$core$Set$Set_elm_builtin(
			A2($elm$core$Dict$remove, key, dict));
	});
var $bChiquet$elm_accessors$Accessors$set = F3(
	function (accessor, value, s) {
		var _v0 = accessor($bChiquet$elm_accessors$Accessors$id);
		var relation = _v0.a;
		return A2(
			relation.over,
			function (_v1) {
				return value;
			},
			s);
	});
var $author$project$Help$Html$targetWithinID = F2(
	function (comparisonTagName, _v0) {
		targetWithinID:
		while (true) {
			var parentNode = _v0.a.parentNode;
			var id = _v0.a.id;
			if (_Utils_eq(
				$elm$core$Maybe$Just(comparisonTagName),
				id)) {
				return true;
			} else {
				if (parentNode.$ === 'Just') {
					var parentNode_ = parentNode.a;
					var $temp$comparisonTagName = comparisonTagName,
						$temp$_v0 = parentNode_;
					comparisonTagName = $temp$comparisonTagName;
					_v0 = $temp$_v0;
					continue targetWithinID;
				} else {
					return false;
				}
			}
		}
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$Api$Types$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$author$project$Api$Types$resolution(decoder));
	});
var $elm$http$Http$Request = function (a) {
	return {$: 'Request', a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {reqs: reqs, subs: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (cmd.$ === 'Cancel') {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 'Nothing') {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.tracker;
							if (_v4.$ === 'Nothing') {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.reqs));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.subs)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 'Cancel', a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (cmd.$ === 'Cancel') {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					allowCookiesFromOtherDomains: r.allowCookiesFromOtherDomains,
					body: r.body,
					expect: A2(_Http_mapExpect, func, r.expect),
					headers: r.headers,
					method: r.method,
					timeout: r.timeout,
					tracker: r.tracker,
					url: r.url
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 'MySub', a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{allowCookiesFromOtherDomains: false, body: r.body, expect: r.expect, headers: r.headers, method: r.method, timeout: r.timeout, tracker: r.tracker, url: r.url}));
};
var $author$project$Api$Bep$toCmd = F3(
	function (toMsg, apiConfig, _v0) {
		var url = _v0.a;
		var requestType = _v0.b;
		switch (requestType.$) {
			case 'Post':
				var body = requestType.a.body;
				var decoder = requestType.a.decoder;
				return $elm$http$Http$request(
					{
						body: $elm$http$Http$jsonBody(body),
						expect: A2($author$project$Api$Types$expectJson, toMsg, decoder),
						headers: _List_fromArray(
							[
								$author$project$Api$Bep$authTokenHeader(apiConfig)
							]),
						method: 'POST',
						timeout: $author$project$Api$Bep$defaultTimeout,
						tracker: $elm$core$Maybe$Nothing,
						url: url
					});
			case 'Get':
				var decoder = requestType.a.decoder;
				var params = requestType.a.params;
				return $elm$http$Http$request(
					{
						body: $elm$http$Http$emptyBody,
						expect: A2($author$project$Api$Types$expectJson, toMsg, decoder),
						headers: _List_fromArray(
							[
								$author$project$Api$Bep$authTokenHeader(apiConfig)
							]),
						method: 'GET',
						timeout: $author$project$Api$Bep$defaultTimeout,
						tracker: $elm$core$Maybe$Nothing,
						url: A2($author$project$Help$Urls$queryURL, url, params)
					});
			case 'Patch':
				var body = requestType.a.body;
				var decoder = requestType.a.decoder;
				return $elm$http$Http$request(
					{
						body: $elm$http$Http$jsonBody(body),
						expect: A2($author$project$Api$Types$expectJson, toMsg, decoder),
						headers: _List_fromArray(
							[
								$author$project$Api$Bep$authTokenHeader(apiConfig)
							]),
						method: 'PATCH',
						timeout: $author$project$Api$Bep$defaultTimeout,
						tracker: $elm$core$Maybe$Nothing,
						url: url
					});
			default:
				var decoder = requestType.a.decoder;
				var id = requestType.a.id;
				return $elm$http$Http$request(
					{
						body: $elm$http$Http$emptyBody,
						expect: A2($author$project$Api$Types$expectJson, toMsg, decoder),
						headers: _List_fromArray(
							[
								$author$project$Api$Bep$authTokenHeader(apiConfig)
							]),
						method: 'DELETE',
						timeout: $author$project$Api$Bep$defaultTimeout,
						tracker: $elm$core$Maybe$Nothing,
						url: A2($author$project$Api$Bep$deleteURL, url, id)
					});
		}
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (_v0.$ === 'Just') {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return A2($elm$core$Dict$member, key, dict);
	});
var $author$project$Help$Sets$toggle = F2(
	function (val, set) {
		return A2($elm$core$Set$member, val, set) ? A2($elm$core$Set$remove, val, set) : A2($elm$core$Set$insert, val, set);
	});
var $author$project$Api$AlertConditions$Types$toggleWeekday = F2(
	function (w, d) {
		switch (w.$) {
			case 'Sun':
				return _Utils_update(
					d,
					{sunday: !d.sunday});
			case 'Mon':
				return _Utils_update(
					d,
					{monday: !d.monday});
			case 'Tue':
				return _Utils_update(
					d,
					{tuesday: !d.tuesday});
			case 'Wed':
				return _Utils_update(
					d,
					{wednesday: !d.wednesday});
			case 'Thu':
				return _Utils_update(
					d,
					{thursday: !d.thursday});
			case 'Fri':
				return _Utils_update(
					d,
					{friday: !d.friday});
			default:
				return _Utils_update(
					d,
					{saturday: !d.saturday});
		}
	});
var $author$project$Api$Types$updateErrors = F2(
	function (err, model) {
		var errors = $author$project$Api$Types$errToErrorsList(err);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{errors: errors}),
			$elm$core$Platform$Cmd$none);
	});
var $elm_community$list_extra$List$Extra$updateIf = F3(
	function (predicate, update, list) {
		return A2(
			$elm$core$List$map,
			function (item) {
				return predicate(item) ? update(item) : item;
			},
			list);
	});
var $author$project$Alerts$State$update = F2(
	function (msg, model) {
		var _v0 = model;
		var apiConfig = _v0.apiConfig;
		switch (msg.$) {
			case 'ChangeAttribute':
				var change = msg.a;
				var f = function () {
					switch (change.$) {
						case 'DataStreamID':
							var i = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$set,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.dataStreamID),
								i);
						case 'ComparisonType':
							var m = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$set,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.comparisonType),
								m);
						case 'ConditionValue':
							var v = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$set,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.conditionValue),
								v);
						case 'ToggleWeekday':
							var weekday = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$over,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.daysSelection),
								$author$project$Api$AlertConditions$Types$toggleWeekday(weekday));
						case 'TimeSelection':
							var t = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$set,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.timesSelection),
								t);
						case 'DateSelection':
							var d = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$set,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.datesSelection),
								d);
						case 'UserSelection':
							var u = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$set,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.usersSelection),
								u);
						case 'ToggleUser':
							var id = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$over,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.userIDs),
								$author$project$Help$Sets$toggle(id));
						case 'ChangeStartTime':
							var clockChange = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$over,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.startTime),
								$author$project$Help$ClockTime$applyChange(clockChange));
						case 'ChangeEndTime':
							var clockChange = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$over,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.endTime),
								$author$project$Help$ClockTime$applyChange(clockChange));
						case 'ChangeStartMonthDay':
							var monthDayChange = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$over,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.startMonthDay),
								$author$project$Help$MonthDays$applyChange(monthDayChange));
						default:
							var monthDayChange = change.a;
							return A2(
								$bChiquet$elm_accessors$Accessors$over,
								A2($elm$core$Basics$composeL, $author$project$Alerts$Types$map.alertConditionForm, $author$project$Api$AlertConditions$Types$map.endMonthDay),
								$author$project$Help$MonthDays$applyChange(monthDayChange));
					}
				}();
				return _Utils_Tuple2(
					f(model),
					$elm$core$Platform$Cmd$none);
			case 'Noop':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'SetRoute':
				var route = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{route: route}),
					$elm$core$Platform$Cmd$none);
			case 'CreateAlertCondition':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{creatingAlertCondition: true}),
					A3(
						$author$project$Api$Bep$toCmd,
						$author$project$Alerts$Types$CreateAlertConditionComplete,
						apiConfig,
						A2($author$project$Api$AlertConditions$post, model.userID, model.alertConditionForm)));
			case 'CreateAlertConditionComplete':
				if (msg.a.$ === 'Ok') {
					var alertCondition = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								alertConditions: A2($elm$core$List$cons, alertCondition, model.alertConditions),
								creatingAlertCondition: false
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								creatingAlertCondition: false,
								errors: $author$project$Api$Types$errToErrorsList(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'DeleteAlertCondition':
				var alertCondition = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							deletingAlertConditions: A2($elm$core$Set$insert, alertCondition.id, model.deletingAlertConditions)
						}),
					A3(
						$author$project$Api$Bep$toCmd,
						$author$project$Alerts$Types$DeleteAlertConditionComplete(alertCondition.id),
						apiConfig,
						$author$project$Api$AlertConditions$delete(alertCondition.id)));
			case 'DeleteAlertConditionComplete':
				if (msg.b.$ === 'Ok') {
					var id = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								alertConditions: A2($author$project$Help$Lists$rejectID, id, model.alertConditions),
								checkedAlertConditions: A2($elm$core$Set$remove, id, model.checkedAlertConditions),
								creatingAlertCondition: false,
								deletingAlertConditions: A2($elm$core$Set$remove, id, model.deletingAlertConditions)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var id = msg.a;
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								deletingAlertConditions: A2($elm$core$Set$remove, id, model.deletingAlertConditions),
								errors: $author$project$Api$Types$errToErrorsList(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'ToggleAlertConditionPopover':
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							alertConditionPopover: (!_Utils_eq(
								model.alertConditionPopover,
								$elm$core$Maybe$Just(id))) ? $elm$core$Maybe$Just(id) : $elm$core$Maybe$Nothing
						}),
					$elm$core$Platform$Cmd$none);
			case 'OutsideClick':
				var target = msg.a;
				return A2($author$project$Help$Html$targetWithinID, 'popover', target) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{alertConditionPopover: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 'ToggleAlertCondition':
				var alertCondition = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							checkedAlertConditions: A2($author$project$Help$Sets$toggle, alertCondition.id, model.checkedAlertConditions)
						}),
					$elm$core$Platform$Cmd$none);
			case 'DeleteSubscriber':
				var subscriber = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							updatingUsers: A2($elm$core$Set$insert, subscriber.user.id, model.updatingUsers)
						}),
					A3(
						$author$project$Api$Bep$toCmd,
						$author$project$Alerts$Types$DeleteSubscriberComplete(subscriber.user.id),
						apiConfig,
						$author$project$Api$AlertConditionSubscribers$delete(subscriber.id)));
			case 'DeleteSubscriberComplete':
				if (msg.b.$ === 'Ok') {
					var userID = msg.a;
					var subscriberID = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								alertConditions: A2(
									$elm$core$List$map,
									function (a) {
										return _Utils_update(
											a,
											{
												subscribers: A2(
													$elm$core$List$filter,
													$author$project$Help$Records$idNotEqTo(subscriberID),
													a.subscribers)
											});
									},
									model.alertConditions),
								updatingUsers: A2($elm$core$Set$remove, userID, model.updatingUsers)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var userID = msg.a;
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								errors: $author$project$Api$Types$errToErrorsList(err),
								updatingUsers: A2($elm$core$Set$remove, userID, model.updatingUsers)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'CreateSubscriber':
				var alertCondition = msg.a;
				var userID = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							updatingUsers: A2($elm$core$Set$insert, userID, model.updatingUsers)
						}),
					A3(
						$author$project$Api$Bep$toCmd,
						A2($author$project$Alerts$Types$CreateSubscriberComplete, userID, alertCondition),
						apiConfig,
						$author$project$Api$AlertConditionSubscribers$post(
							{alertConditionID: alertCondition.id, userID: userID})));
			case 'CreateSubscriberComplete':
				if (msg.c.$ === 'Ok') {
					var alertCondition = msg.b;
					var subscriber = msg.c.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								alertConditions: A3(
									$elm_community$list_extra$List$Extra$updateIf,
									function (a) {
										return _Utils_eq(a.id, alertCondition.id);
									},
									function (a) {
										return _Utils_update(
											a,
											{
												subscribers: A2($elm$core$List$cons, subscriber, a.subscribers)
											});
									},
									model.alertConditions),
								updatingUsers: A2($elm$core$Set$remove, subscriber.user.id, model.updatingUsers)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var userID = msg.a;
					var err = msg.c.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								errors: $author$project$Api$Types$errToErrorsList(err),
								updatingUsers: A2($elm$core$Set$remove, userID, model.updatingUsers)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'BackfillAll':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{backfilling: true}),
					A3(
						$author$project$Api$Bep$toCmd,
						$author$project$Alerts$Types$BackfillComplete,
						apiConfig,
						$author$project$Api$AlertBackfills$post(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.id;
								},
								model.alertConditions))));
			case 'BackfillSelected':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{backfilling: true}),
					A3(
						$author$project$Api$Bep$toCmd,
						$author$project$Alerts$Types$BackfillComplete,
						apiConfig,
						$author$project$Api$AlertBackfills$post(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.id;
								},
								model.alertConditions))));
			default:
				if (msg.a.$ === 'Ok') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{backfillCompleteMessageVisible: true, backfilling: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return A2(
						$author$project$Api$Types$updateErrors,
						err,
						_Utils_update(
							model,
							{backfilling: false}));
				}
		}
	});
var $author$project$Alerts$Types$AlertsIndex = {$: 'AlertsIndex'};
var $author$project$Alerts$Types$ChangeAttribute = function (a) {
	return {$: 'ChangeAttribute', a: a};
};
var $author$project$Help$MonthDays$ChangeDay = function (a) {
	return {$: 'ChangeDay', a: a};
};
var $author$project$Api$AlertConditions$Types$ChangeEndMonthDay = function (a) {
	return {$: 'ChangeEndMonthDay', a: a};
};
var $author$project$Api$AlertConditions$Types$ChangeEndTime = function (a) {
	return {$: 'ChangeEndTime', a: a};
};
var $author$project$Help$MonthDays$ChangeMonth = function (a) {
	return {$: 'ChangeMonth', a: a};
};
var $author$project$Api$AlertConditions$Types$ChangeStartMonthDay = function (a) {
	return {$: 'ChangeStartMonthDay', a: a};
};
var $author$project$Api$AlertConditions$Types$ChangeStartTime = function (a) {
	return {$: 'ChangeStartTime', a: a};
};
var $author$project$Api$AlertConditions$Types$ComparisonType = function (a) {
	return {$: 'ComparisonType', a: a};
};
var $author$project$Api$AlertConditions$Types$ConditionValue = function (a) {
	return {$: 'ConditionValue', a: a};
};
var $author$project$Alerts$Types$CreateAlertCondition = {$: 'CreateAlertCondition'};
var $author$project$Api$AlertConditions$Types$Custom = {$: 'Custom'};
var $author$project$Api$AlertConditions$Types$DataStreamID = function (a) {
	return {$: 'DataStreamID', a: a};
};
var $author$project$Api$AlertConditions$Types$DateSelection = function (a) {
	return {$: 'DateSelection', a: a};
};
var $author$project$Help$ClockTime$Hour = function (a) {
	return {$: 'Hour', a: a};
};
var $author$project$Help$ClockTime$Minute = function (a) {
	return {$: 'Minute', a: a};
};
var $author$project$Api$AlertConditions$Types$NoOne = {$: 'NoOne'};
var $author$project$Alerts$Types$Noop = {$: 'Noop'};
var $author$project$Help$ClockTime$PM = function (a) {
	return {$: 'PM', a: a};
};
var $author$project$Api$AlertConditions$Types$SpecificPeople = {$: 'SpecificPeople'};
var $author$project$Api$AlertConditions$Types$SpecificTimes = {$: 'SpecificTimes'};
var $author$project$Api$AlertConditions$Types$TimeSelection = function (a) {
	return {$: 'TimeSelection', a: a};
};
var $author$project$Api$AlertConditions$Types$ToggleUser = function (a) {
	return {$: 'ToggleUser', a: a};
};
var $author$project$Api$AlertConditions$Types$ToggleWeekday = function (a) {
	return {$: 'ToggleWeekday', a: a};
};
var $author$project$Api$AlertConditions$Types$UserSelection = function (a) {
	return {$: 'UserSelection', a: a};
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Api$AlertConditions$Types$canSubmit = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.conditionValue;
	},
	A2($elm$core$Basics$composeR, $elm$core$String$toFloat, $elm_community$maybe_extra$Maybe$Extra$isJust));
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$Help$Maybe$maybe = F3(
	function (_default, f, value) {
		if (value.$ === 'Just') {
			var value_ = value.a;
			return f(value_);
		} else {
			return _default;
		}
	});
var $author$project$Help$Html$defaultBlank = $author$project$Help$Maybe$maybe($author$project$Help$Html$blank);
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Components$Form$errorLi = function (error) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-list__item')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(error)
			]));
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Components$Form$errorsList = function (errors) {
	return $elm$core$List$isEmpty(errors) ? $author$project$Help$Html$blank : A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-list')
			]),
		A2($elm$core$List$map, $author$project$Components$Form$errorLi, errors));
};
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Help$Lists$findByID = F2(
	function (list, id) {
		return A2(
			$elm_community$list_extra$List$Extra$find,
			function (e) {
				return _Utils_eq(e.id, id);
			},
			list);
	});
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Components$Icons$icon = function (name) {
	return A2(
		$elm$html$Html$span,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fa fa-' + name)
					]),
				_List_Nil)
			]));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Help$CSS$inlineBlock = A2($elm$html$Html$Attributes$style, 'display', 'inline-block');
var $author$project$Help$Months$monthToString = function (m) {
	switch (m.$) {
		case 'Jan':
			return 'Jan';
		case 'Feb':
			return 'Feb';
		case 'Mar':
			return 'Mar';
		case 'Apr':
			return 'Apr';
		case 'May':
			return 'May';
		case 'Jun':
			return 'Jun';
		case 'Jul':
			return 'Jul';
		case 'Aug':
			return 'Aug';
		case 'Sep':
			return 'Sep';
		case 'Oct':
			return 'Oct';
		case 'Nov':
			return 'Nov';
		default:
			return 'Dec';
	}
};
var $author$project$Components$Form$label_ = F2(
	function (labelText, htmlID) {
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form__label'),
					$elm$html$Html$Attributes$for(htmlID)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(labelText)
				]));
	});
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 'MayStopPropagation', a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Components$Form$selectOption = F2(
	function (selectedValue, opt) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(opt.b),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(selectedValue, opt.b))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(opt.a)
				]));
	});
var $author$project$Components$Form$select_ = F2(
	function (inputOptions, options) {
		var class_ = $elm$html$Html$Attributes$class(
			A2($elm$core$Maybe$withDefault, 'form__select distinct', inputOptions._class));
		var _v0 = inputOptions;
		var event = _v0.event;
		var value = _v0.value;
		var htmlID = _v0.htmlID;
		return A2(
			$elm$html$Html$select,
			_List_fromArray(
				[
					$elm$html$Html$Events$onInput(event),
					class_,
					$elm$html$Html$Attributes$id(htmlID)
				]),
			A2(
				$elm$core$List$map,
				$author$project$Components$Form$selectOption(value),
				options));
	});
var $author$project$Components$Form$selectWithLabel = F3(
	function (firstOption, inputOptions, options) {
		var allOptions = function () {
			if (firstOption.$ === 'Just') {
				var option = firstOption.a;
				return A2($elm$core$List$cons, option, options);
			} else {
				return options;
			}
		}();
		var _v0 = inputOptions;
		var htmlID = _v0.htmlID;
		var labelText = _v0.labelText;
		if (labelText.$ === 'Just') {
			var l = labelText.a;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2($author$project$Components$Form$label_, l, htmlID),
						A2($author$project$Components$Form$select_, inputOptions, allOptions)
					]));
		} else {
			return A2($author$project$Components$Form$select_, inputOptions, allOptions);
		}
	});
var $author$project$Help$Months$stringToMonth = function (s) {
	switch (s) {
		case 'Jan':
			return $elm$core$Maybe$Just($elm$time$Time$Jan);
		case 'Feb':
			return $elm$core$Maybe$Just($elm$time$Time$Feb);
		case 'Mar':
			return $elm$core$Maybe$Just($elm$time$Time$Mar);
		case 'Apr':
			return $elm$core$Maybe$Just($elm$time$Time$Apr);
		case 'May':
			return $elm$core$Maybe$Just($elm$time$Time$May);
		case 'Jun':
			return $elm$core$Maybe$Just($elm$time$Time$Jun);
		case 'Jul':
			return $elm$core$Maybe$Just($elm$time$Time$Jul);
		case 'Aug':
			return $elm$core$Maybe$Just($elm$time$Time$Aug);
		case 'Sep':
			return $elm$core$Maybe$Just($elm$time$Time$Sep);
		case 'Oct':
			return $elm$core$Maybe$Just($elm$time$Time$Oct);
		case 'Nov':
			return $elm$core$Maybe$Just($elm$time$Time$Nov);
		case 'Dec':
			return $elm$core$Maybe$Just($elm$time$Time$Dec);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Alerts$Views$monthDaySelect = function (_v0) {
	var monthDay = _v0.monthDay;
	var monthMsg = _v0.monthMsg;
	var noopMsg = _v0.noopMsg;
	var htmlNamespace = _v0.htmlNamespace;
	var dayMsg = _v0.dayMsg;
	var _v1 = monthDay;
	var month = _v1.month;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$author$project$Help$CSS$inlineBlock]),
		_List_fromArray(
			[
				A3(
				$author$project$Components$Form$selectWithLabel,
				$elm$core$Maybe$Nothing,
				{
					_class: $elm$core$Maybe$Nothing,
					event: A2(
						$elm$core$Basics$composeR,
						$author$project$Help$Months$stringToMonth,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$map(monthMsg),
							$elm$core$Maybe$withDefault(noopMsg))),
					htmlID: htmlNamespace + '-month',
					labelText: $elm$core$Maybe$Nothing,
					value: $author$project$Help$Months$monthToString(month)
				},
				A2(
					$elm$core$List$map,
					function (m) {
						return _Utils_Tuple2(
							$author$project$Help$Months$monthToString(m),
							$author$project$Help$Months$monthToString(m));
					},
					_List_fromArray(
						[$elm$time$Time$Jan, $elm$time$Time$Feb, $elm$time$Time$Mar, $elm$time$Time$Apr, $elm$time$Time$May, $elm$time$Time$Jun, $elm$time$Time$Jul, $elm$time$Time$Aug, $elm$time$Time$Sep, $elm$time$Time$Oct, $elm$time$Time$Nov, $elm$time$Time$Dec]))),
				A3(
				$author$project$Components$Form$selectWithLabel,
				$elm$core$Maybe$Nothing,
				{
					_class: $elm$core$Maybe$Nothing,
					event: A2(
						$elm$core$Basics$composeR,
						$elm$core$String$toInt,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$map(dayMsg),
							$elm$core$Maybe$withDefault(noopMsg))),
					htmlID: htmlNamespace + '-day',
					labelText: $elm$core$Maybe$Nothing,
					value: $elm$core$String$fromInt(monthDay.day)
				},
				A2(
					$elm$core$List$map,
					function (n) {
						return _Utils_Tuple2(
							$elm$core$String$fromInt(n),
							$elm$core$String$fromInt(n));
					},
					A2($elm$core$List$range, 1, 31)))
			]));
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 'Normal', a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$regex$Regex$never = _Regex_never;
var $author$project$Help$Regex$replaceNonalphaNumeric = function (replacement) {
	return A2(
		$elm$regex$Regex$replace,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('[^a-zA-Z]')),
		$elm$core$Basics$always(replacement));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Alerts$Views$radioSelect = function (_v0) {
	var items = _v0.items;
	var prefix = _v0.prefix;
	var toMsg = _v0.toMsg;
	var selected = _v0.selected;
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (_v1) {
				var labelText = _v1.a;
				var val = _v1.b;
				var htmlID = function (a) {
					return prefix + ('_' + a);
				}(
					A2($author$project$Help$Regex$replaceNonalphaNumeric, '_', labelText));
				return A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'block'),
							A2($elm$html$Html$Attributes$style, 'font-weight', 'normal'),
							$elm$html$Html$Attributes$for(htmlID),
							$elm$html$Html$Events$onClick(
							toMsg(val))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '12px'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 5px 0 0'),
									$elm$html$Html$Attributes$id(htmlID),
									$elm$html$Html$Attributes$name(prefix),
									$elm$html$Html$Attributes$type_('radio'),
									$elm$html$Html$Attributes$checked(
									_Utils_eq(val, selected))
								]),
							_List_Nil),
							$elm$html$Html$text(labelText)
						]));
			},
			items));
};
var $author$project$Api$AlertConditions$Types$stringToCondition = function (s) {
	switch (s) {
		case 'less_than':
			return $elm$core$Maybe$Just($author$project$Api$AlertConditions$Types$LessThan);
		case 'greater_than':
			return $elm$core$Maybe$Just($author$project$Api$AlertConditions$Types$GreaterThan);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Alerts$Views$minuteSelection = _List_fromArray(
	[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]);
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$Alerts$Views$timeSelect = function (_v0) {
	var hour = _v0.hour;
	var minute = _v0.minute;
	var pm = _v0.pm;
	var hourMsg = _v0.hourMsg;
	var minuteMsg = _v0.minuteMsg;
	var htmlNamespace = _v0.htmlNamespace;
	var noopMsg = _v0.noopMsg;
	var pmMsg = _v0.pmMsg;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('timepicker')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Components$Form$selectWithLabel,
				$elm$core$Maybe$Nothing,
				{
					_class: $elm$core$Maybe$Nothing,
					event: A2(
						$elm$core$Basics$composeR,
						$elm$core$String$toInt,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$map(hourMsg),
							$elm$core$Maybe$withDefault(noopMsg))),
					htmlID: htmlNamespace + '-hour',
					labelText: $elm$core$Maybe$Nothing,
					value: $elm$core$String$fromInt(hour)
				},
				A2(
					$elm$core$List$map,
					function (i) {
						return _Utils_Tuple2(
							A3(
								$elm$core$String$padLeft,
								2,
								_Utils_chr('0'),
								i),
							i);
					},
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						A2($elm$core$List$range, 1, 12)))),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('timpicker__separator')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(': ')
					])),
				A3(
				$author$project$Components$Form$selectWithLabel,
				$elm$core$Maybe$Nothing,
				{
					_class: $elm$core$Maybe$Nothing,
					event: A2(
						$elm$core$Basics$composeR,
						$elm$core$String$toInt,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$map(minuteMsg),
							$elm$core$Maybe$withDefault(noopMsg))),
					htmlID: htmlNamespace + '-minute',
					labelText: $elm$core$Maybe$Nothing,
					value: $elm$core$String$fromInt(minute)
				},
				A2(
					$elm$core$List$map,
					function (i) {
						return _Utils_Tuple2(
							A3(
								$elm$core$String$padLeft,
								2,
								_Utils_chr('0'),
								i),
							i);
					},
					A2($elm$core$List$map, $elm$core$String$fromInt, $author$project$Alerts$Views$minuteSelection))),
				A3(
				$author$project$Components$Form$selectWithLabel,
				$elm$core$Maybe$Nothing,
				{
					_class: $elm$core$Maybe$Nothing,
					event: function (a) {
						return pmMsg(a === 'PM');
					},
					htmlID: htmlNamespace + '-pm',
					labelText: $elm$core$Maybe$Nothing,
					value: pm ? 'PM' : 'AM'
				},
				A2(
					$elm$core$List$map,
					function (a) {
						return _Utils_Tuple2(a, a);
					},
					_List_fromArray(
						['AM', 'PM'])))
			]));
};
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Help$Time$weekdayToString = function (w) {
	switch (w.$) {
		case 'Sun':
			return 'Sunday';
		case 'Mon':
			return 'Monday';
		case 'Tue':
			return 'Tuesday';
		case 'Wed':
			return 'Wednesday';
		case 'Thu':
			return 'Thursday';
		case 'Fri':
			return 'Friday';
		default:
			return 'Saturday';
	}
};
var $elm$time$Time$Fri = {$: 'Fri'};
var $elm$time$Time$Mon = {$: 'Mon'};
var $elm$time$Time$Sat = {$: 'Sat'};
var $elm$time$Time$Sun = {$: 'Sun'};
var $elm$time$Time$Thu = {$: 'Thu'};
var $elm$time$Time$Tue = {$: 'Tue'};
var $elm$time$Time$Wed = {$: 'Wed'};
var $author$project$Api$AlertConditions$Types$weekdays = _List_fromArray(
	[
		_Utils_Tuple2(
		$elm$time$Time$Sun,
		function ($) {
			return $.sunday;
		}),
		_Utils_Tuple2(
		$elm$time$Time$Mon,
		function ($) {
			return $.monday;
		}),
		_Utils_Tuple2(
		$elm$time$Time$Tue,
		function ($) {
			return $.tuesday;
		}),
		_Utils_Tuple2(
		$elm$time$Time$Wed,
		function ($) {
			return $.wednesday;
		}),
		_Utils_Tuple2(
		$elm$time$Time$Thu,
		function ($) {
			return $.thursday;
		}),
		_Utils_Tuple2(
		$elm$time$Time$Fri,
		function ($) {
			return $.friday;
		}),
		_Utils_Tuple2(
		$elm$time$Time$Sat,
		function ($) {
			return $.saturday;
		})
	]);
var $author$project$Alerts$Views$alertConditionForm = function (model) {
	var form = model.alertConditionForm;
	var dataStream = A2($author$project$Help$Lists$findByID, model.dataStreams, form.dataStreamID);
	var _v0 = form;
	var startTime = _v0.startTime;
	var endTime = _v0.endTime;
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Create Alert Conditions')
					])),
				$author$project$Components$Form$errorsList(model.errors),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bordered-section distinct padded')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h3,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('When the hourly average for')
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A3(
										$author$project$Components$Form$selectWithLabel,
										$elm$core$Maybe$Nothing,
										{
											_class: $elm$core$Maybe$Just('form__select ac-form__stream-select'),
											event: A2(
												$elm$core$Basics$composeR,
												$elm$core$String$toInt,
												A2(
													$elm$core$Basics$composeR,
													$elm$core$Maybe$map(
														A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$DataStreamID)),
													$elm$core$Maybe$withDefault($author$project$Alerts$Types$Noop))),
											htmlID: 'data_stream',
											labelText: $elm$core$Maybe$Nothing,
											value: $elm$core$String$fromInt(form.dataStreamID)
										},
										A2(
											$elm$core$List$map,
											function (d) {
												return _Utils_Tuple2(
													d.name,
													$elm$core$String$fromInt(d.id));
											},
											model.dataStreams))
									])),
								A3(
								$author$project$Components$Form$selectWithLabel,
								$elm$core$Maybe$Nothing,
								{
									_class: $elm$core$Maybe$Just('form__select ac-form__comparison'),
									event: A2(
										$elm$core$Basics$composeR,
										$author$project$Api$AlertConditions$Types$stringToCondition,
										A2(
											$elm$core$Basics$composeR,
											$elm$core$Maybe$map(
												A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ComparisonType)),
											$elm$core$Maybe$withDefault($author$project$Alerts$Types$Noop))),
									htmlID: 'condition',
									labelText: $elm$core$Maybe$Nothing,
									value: $author$project$Api$AlertConditions$Types$conditionToString(form.comparisonType)
								},
								_List_fromArray(
									[
										_Utils_Tuple2('is less than', 'less_than'),
										_Utils_Tuple2('is greater than', 'greater_than')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form__input'),
										A2($elm$html$Html$Attributes$attribute, 'maxlength', '9'),
										$elm$html$Html$Attributes$placeholder('1.2345'),
										$elm$html$Html$Attributes$type_('text'),
										$elm$html$Html$Attributes$value(form.conditionValue),
										$elm$html$Html$Events$onInput(
										A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ConditionValue)),
										A2($elm$html$Html$Attributes$style, 'width', '100px')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'padding-top', '6px')
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Help$Html$defaultBlank,
										$elm$html$Html$text,
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.unitType;
											},
											dataStream))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('clear')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('distinct')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('on days')
									])),
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('day-picker')
									]),
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, _v1) {
											var w = _v1.a;
											var getter = _v1.b;
											var weekdayString = $author$project$Help$Time$weekdayToString(w);
											var htmlID = 'day_' + $elm$core$String$fromInt(i);
											var firstLetter = A2(
												$elm$core$Maybe$withDefault,
												'',
												A2(
													$elm$core$Maybe$map,
													A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $elm$core$String$fromChar),
													$elm$core$String$uncons(weekdayString)));
											return A2(
												$elm$html$Html$li,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('day-picker__day'),
														$elm$html$Html$Attributes$title(weekdayString)
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$label,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('day-picker__label'),
																$elm$html$Html$Attributes$for(htmlID)
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('day-picker__checkbox'),
																		$elm$html$Html$Attributes$id(htmlID),
																		$elm$html$Html$Attributes$type_('checkbox'),
																		$elm$html$Html$Events$onClick(
																		$author$project$Alerts$Types$ChangeAttribute(
																			$author$project$Api$AlertConditions$Types$ToggleWeekday(w))),
																		$elm$html$Html$Attributes$checked(
																		getter(form.daysSelection))
																	]),
																_List_Nil),
																$elm$html$Html$text(' ' + firstLetter)
															]))
													]));
										}),
									$author$project$Api$AlertConditions$Types$weekdays))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('distinct')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('at')
									])),
								$author$project$Alerts$Views$radioSelect(
								{
									items: _List_fromArray(
										[
											_Utils_Tuple2('any times', $author$project$Api$AlertConditions$Types$AnyTime),
											_Utils_Tuple2('specific times', $author$project$Api$AlertConditions$Types$SpecificTimes)
										]),
									prefix: 'times',
									selected: form.timesSelection,
									toMsg: A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$TimeSelection)
								}),
								_Utils_eq(form.timesSelection, $author$project$Api$AlertConditions$Types$SpecificTimes) ? A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Alerts$Views$timeSelect(
										{
											hour: startTime.hour,
											hourMsg: A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeStartTime),
												$author$project$Help$ClockTime$Hour),
											htmlNamespace: 'start-time',
											minute: startTime.minute,
											minuteMsg: A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeStartTime),
												$author$project$Help$ClockTime$Minute),
											noopMsg: $author$project$Alerts$Types$Noop,
											pm: startTime.pm,
											pmMsg: A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeStartTime),
												$author$project$Help$ClockTime$PM)
										}),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
												A2($elm$html$Html$Attributes$style, 'display', 'inline-block'),
												A2($elm$html$Html$Attributes$style, 'padding', '0 5px')
											]),
										_List_fromArray(
											[
												$author$project$Components$Icons$icon('arrows-h')
											])),
										$author$project$Alerts$Views$timeSelect(
										{
											hour: endTime.hour,
											hourMsg: A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeEndTime),
												$author$project$Help$ClockTime$Hour),
											htmlNamespace: 'end-time',
											minute: endTime.minute,
											minuteMsg: A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeEndTime),
												$author$project$Help$ClockTime$Minute),
											noopMsg: $author$project$Alerts$Types$Noop,
											pm: endTime.pm,
											pmMsg: A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeEndTime),
												$author$project$Help$ClockTime$PM)
										})
									])) : $author$project$Help$Html$blank
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('distinct')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('between dates')
									])),
								$author$project$Alerts$Views$radioSelect(
								{
									items: _List_fromArray(
										[
											_Utils_Tuple2('all year', $author$project$Api$AlertConditions$Types$AllYear),
											_Utils_Tuple2('custom', $author$project$Api$AlertConditions$Types$Custom)
										]),
									prefix: 'dates',
									selected: form.datesSelection,
									toMsg: A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$DateSelection)
								})
							])),
						_Utils_eq(form.datesSelection, $author$project$Api$AlertConditions$Types$Custom) ? A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Alerts$Views$monthDaySelect(
								{
									dayMsg: A2(
										$elm$core$Basics$composeL,
										A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeStartMonthDay),
										$author$project$Help$MonthDays$ChangeDay),
									htmlNamespace: 'start-month-day',
									monthDay: form.startMonthDay,
									monthMsg: A2(
										$elm$core$Basics$composeL,
										A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeStartMonthDay),
										$author$project$Help$MonthDays$ChangeMonth),
									noopMsg: $author$project$Alerts$Types$Noop
								}),
								$elm$html$Html$text(' to '),
								$author$project$Alerts$Views$monthDaySelect(
								{
									dayMsg: A2(
										$elm$core$Basics$composeL,
										A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeEndMonthDay),
										$author$project$Help$MonthDays$ChangeDay),
									htmlNamespace: 'end-month-day',
									monthDay: form.endMonthDay,
									monthMsg: A2(
										$elm$core$Basics$composeL,
										A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$ChangeEndMonthDay),
										$author$project$Help$MonthDays$ChangeMonth),
									noopMsg: $author$project$Alerts$Types$Noop
								})
							])) : $author$project$Help$Html$blank,
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('email:')
									])),
								$author$project$Alerts$Views$radioSelect(
								{
									items: _List_fromArray(
										[
											_Utils_Tuple2('me', $author$project$Api$AlertConditions$Types$Me),
											_Utils_Tuple2('no one', $author$project$Api$AlertConditions$Types$NoOne),
											_Utils_Tuple2('specific people', $author$project$Api$AlertConditions$Types$SpecificPeople)
										]),
									prefix: 'users',
									selected: form.usersSelection,
									toMsg: A2($elm$core$Basics$composeL, $author$project$Alerts$Types$ChangeAttribute, $author$project$Api$AlertConditions$Types$UserSelection)
								}),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('clear')
									]),
								_List_Nil)
							])),
						_Utils_eq(form.usersSelection, $author$project$Api$AlertConditions$Types$SpecificPeople) ? A2(
						$elm$html$Html$ul,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function (user) {
								var htmlID = 'user-' + $elm$core$String$fromInt(user.id);
								return A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('checkbox'),
													$elm$html$Html$Attributes$checked(
													A2($elm$core$Set$member, user.id, form.userIDs)),
													A2($elm$html$Html$Attributes$style, 'margin-right', '3px'),
													$elm$html$Html$Events$onClick(
													$author$project$Alerts$Types$ChangeAttribute(
														$author$project$Api$AlertConditions$Types$ToggleUser(user.id))),
													$elm$html$Html$Attributes$id(htmlID)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$for(htmlID)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(user.email)
												]))
										]));
							},
							model.users)) : $author$project$Help$Html$blank,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-controls')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-controls__inner')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('form__button'),
												$elm$html$Html$Attributes$disabled(
												!($author$project$Api$AlertConditions$Types$canSubmit(form) && (!model.creatingAlertCondition))),
												$elm$html$Html$Events$onClick($author$project$Alerts$Types$CreateAlertCondition)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Create Alert Condition')
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Alerts$Types$DeleteAlertCondition = function (a) {
	return {$: 'DeleteAlertCondition', a: a};
};
var $author$project$Alerts$Types$ToggleAlertCondition = function (a) {
	return {$: 'ToggleAlertCondition', a: a};
};
var $author$project$Alerts$Types$ToggleAlertConditionPopover = function (a) {
	return {$: 'ToggleAlertConditionPopover', a: a};
};
var $author$project$Help$Lists$catMaybes = $elm$core$List$filterMap($elm$core$Basics$identity);
var $author$project$Help$Color$linkBlue = 'rgb(68, 146, 198)';
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 'Custom', a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Help$Html$onWithoutPropagate = F2(
	function (evt, msg) {
		return A2(
			$elm$html$Html$Events$custom,
			evt,
			$elm$json$Json$Decode$succeed(
				{message: msg, preventDefault: false, stopPropagation: true}));
	});
var $author$project$Alerts$Types$CreateSubscriber = F2(
	function (a, b) {
		return {$: 'CreateSubscriber', a: a, b: b};
	});
var $author$project$Alerts$Types$DeleteSubscriber = F2(
	function (a, b) {
		return {$: 'DeleteSubscriber', a: a, b: b};
	});
var $author$project$Alerts$Views$usersList = F2(
	function (model, alertCondition) {
		var subscriberByUserID = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (s) {
					return _Utils_Tuple2(s.user.id, s);
				},
				alertCondition.subscribers));
		var _v0 = model;
		var users = _v0.users;
		return A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (u) {
					var subscriber = A2($elm$core$Dict$get, u.id, subscriberByUserID);
					var isUpdating = A2($elm$core$Set$member, u.id, model.updatingUsers);
					var isChecked = $elm_community$maybe_extra$Maybe$Extra$isJust(subscriber);
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								isUpdating ? $author$project$Components$Icons$spinner : A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('checkbox'),
										$author$project$Help$CSS$inlineBlock,
										A2($elm$html$Html$Attributes$style, 'margin-right', '3px'),
										$elm$html$Html$Attributes$checked(isChecked),
										$elm$html$Html$Events$onClick(
										function () {
											if (subscriber.$ === 'Just') {
												var s = subscriber.a;
												return A2($author$project$Alerts$Types$DeleteSubscriber, alertCondition, s);
											} else {
												return A2($author$project$Alerts$Types$CreateSubscriber, alertCondition, u.id);
											}
										}())
									]),
								_List_Nil),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'display', ' inline-block')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(u.email)
									]))
							]));
				},
				users));
	});
var $author$project$Alerts$Views$popover = F2(
	function (model, alertCondition) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
					A2($elm$html$Html$Attributes$style, 'color', 'white'),
					A2($elm$html$Html$Attributes$style, 'background-color', '#635e5e'),
					A2($elm$html$Html$Attributes$style, 'padding', '10px'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '5px'),
					A2($elm$html$Html$Attributes$style, 'right', '120px'),
					$elm$html$Html$Attributes$id('popover')
				]),
			_List_fromArray(
				[
					A2($author$project$Alerts$Views$usersList, model, alertCondition)
				]));
	});
var $author$project$Alerts$Views$subscriberCount = F2(
	function (userID, subscribers) {
		var _v0 = $elm$core$List$length(subscribers);
		switch (_v0) {
			case 0:
				return 'No one';
			case 1:
				var userID_ = A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.user;
						},
						function ($) {
							return $.id;
						}),
					$elm$core$List$head(subscribers));
				return _Utils_eq(
					userID_,
					$elm$core$Maybe$Just(userID)) ? 'Me' : '1 person';
			default:
				var i = _v0;
				return $elm$core$String$fromInt(i) + ' people';
		}
	});
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $elm_community$maybe_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 'Nothing') {
		return true;
	} else {
		return false;
	}
};
var $author$project$Alerts$Views$whenFullApp = F2(
	function (model, html) {
		var fullAppMode = $elm_community$maybe_extra$Maybe$Extra$isNothing(model.dataStreamID);
		return fullAppMode ? $elm$core$Maybe$Just(html) : $elm$core$Maybe$Nothing;
	});
var $author$project$Alerts$Views$alertConditionRow = F2(
	function (model, alertCondition) {
		var _v0 = model;
		var deletingAlertConditions = _v0.deletingAlertConditions;
		var checkedAlertConditions = _v0.checkedAlertConditions;
		var isChecked = A2($elm$core$Set$member, alertCondition.id, checkedAlertConditions);
		var isDeleting = A2($elm$core$Set$member, alertCondition.id, deletingAlertConditions);
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('full-width')
				]),
			$author$project$Help$Lists$catMaybes(
				_List_fromArray(
					[
						A2(
						$author$project$Alerts$Views$whenFullApp,
						model,
						A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('checkbox'),
											$elm$html$Html$Attributes$checked(isChecked),
											$elm$html$Html$Events$onClick(
											$author$project$Alerts$Types$ToggleAlertCondition(alertCondition))
										]),
									_List_Nil)
								]))),
						$elm$core$Maybe$Just(
						A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($elm$core$Maybe$withDefault, '', alertCondition.description))
								]))),
						$elm$core$Maybe$Just(
						A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'position', 'relative')
								]),
							_List_fromArray(
								[
									_Utils_eq(
									model.alertConditionPopover,
									$elm$core$Maybe$Just(alertCondition.id)) ? A2($author$project$Alerts$Views$popover, model, alertCondition) : $author$project$Help$Html$blank,
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											A2(
											$author$project$Help$Html$onWithoutPropagate,
											'click',
											$author$project$Alerts$Types$ToggleAlertConditionPopover(alertCondition.id)),
											A2($elm$html$Html$Attributes$style, 'color', $author$project$Help$Color$linkBlue),
											$elm$html$Html$Attributes$class('pointer')
										]),
									_List_fromArray(
										[
											$author$project$Components$Icons$icon('envelope'),
											$elm$html$Html$text(
											' ' + A2($author$project$Alerts$Views$subscriberCount, model.userID, alertCondition.subscribers))
										]))
								]))),
						$elm$core$Maybe$Just(
						A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									isDeleting ? $author$project$Components$Icons$spinner : A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ac-table__delete pointer'),
											$elm$html$Html$Events$onClick(
											$author$project$Alerts$Types$DeleteAlertCondition(alertCondition))
										]),
									_List_fromArray(
										[
											$author$project$Components$Icons$icon('trash')
										]))
								])))
					])));
	});
var $elm$html$Html$col = _VirtualDom_node('col');
var $elm$html$Html$colgroup = _VirtualDom_node('colgroup');
var $author$project$Help$Dict$groupBy = function (groupFn) {
	return A2(
		$elm$core$List$foldl,
		F2(
			function (el, dict) {
				var group = groupFn(el);
				var _v0 = A2($elm$core$Dict$get, group, dict);
				if (_v0.$ === 'Just') {
					var arr = _v0.a;
					return A3(
						$elm$core$Dict$insert,
						group,
						A2($elm$core$List$cons, el, arr),
						dict);
				} else {
					return A3(
						$elm$core$Dict$insert,
						group,
						_List_fromArray(
							[el]),
						dict);
				}
			}),
		$elm$core$Dict$empty);
};
var $elm$core$List$sortBy = _List_sortBy;
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $author$project$Help$CSS$width = $elm$html$Html$Attributes$style('width');
var $author$project$Alerts$Views$alertConditionsTables = function (model) {
	var grouped = A2(
		$elm$core$List$sortBy,
		$elm$core$Tuple$first,
		A2(
			$elm$core$List$map,
			function (_v1) {
				var alertConditions = _v1.b;
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.dataStream;
								},
								function ($) {
									return $.name;
								}),
							$elm$core$List$head(alertConditions))),
					alertConditions);
			},
			$elm$core$Dict$toList(
				A2(
					$author$project$Help$Dict$groupBy,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.dataStream;
						},
						function ($) {
							return $.id;
						}),
					model.alertConditions))));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (_v0) {
				var dataStreamName = _v0.a;
				var conditions = _v0.b;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm_community$maybe_extra$Maybe$Extra$isNothing(model.dataStreamID) ? A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(dataStreamName)
								])) : $author$project$Help$Html$blank,
							A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('throwback-table bordered-section distinct')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$colgroup,
									_List_Nil,
									$author$project$Help$Lists$catMaybes(
										_List_fromArray(
											[
												A2(
												$author$project$Alerts$Views$whenFullApp,
												model,
												A2(
													$elm$html$Html$col,
													_List_fromArray(
														[
															$author$project$Help$CSS$width('40px')
														]),
													_List_Nil)),
												$elm$core$Maybe$Just(
												A2(
													$elm$html$Html$col,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ac-table__when-col')
														]),
													_List_Nil)),
												$elm$core$Maybe$Just(
												A2(
													$elm$html$Html$col,
													_List_fromArray(
														[
															$author$project$Help$CSS$width('140px')
														]),
													_List_Nil)),
												$elm$core$Maybe$Just(
												A2(
													$elm$html$Html$col,
													_List_fromArray(
														[
															$author$project$Help$CSS$width('40px')
														]),
													_List_Nil))
											]))),
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											$author$project$Help$Lists$catMaybes(
												_List_fromArray(
													[
														A2(
														$author$project$Alerts$Views$whenFullApp,
														model,
														A2($elm$html$Html$th, _List_Nil, _List_Nil)),
														$elm$core$Maybe$Just(
														A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('When')
																]))),
														$elm$core$Maybe$Just(
														A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Email')
																]))),
														$elm$core$Maybe$Just(
														A2($elm$html$Html$th, _List_Nil, _List_Nil))
													])))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									A2(
										$elm$core$List$map,
										$author$project$Alerts$Views$alertConditionRow(model),
										conditions))
								]))
						]));
			},
			grouped));
};
var $author$project$Alerts$Views$alertConditionNew = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				model.backfillCompleteMessageVisible ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('info-alert')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Alerts will be backfilled and should show up within the next few minutes.')
					])) : $author$project$Help$Html$blank,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Help$CSS$inlineBlock,
						A2($elm$html$Html$Attributes$style, 'vertical-align', 'top'),
						$author$project$Help$CSS$width('50%'),
						A2($elm$html$Html$Attributes$style, 'padding-right', '20px')
					]),
				_List_fromArray(
					[
						$author$project$Alerts$Views$alertConditionForm(model)
					])),
				A2(
				$elm$html$Html$div,
				$elm_community$maybe_extra$Maybe$Extra$isJust(model.dataStreamID) ? _List_Nil : _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', ' inline-block'),
						A2($elm$html$Html$Attributes$style, 'vertical-align', 'top'),
						$author$project$Help$CSS$width('50%'),
						$author$project$Help$CSS$inlineBlock
					]),
				_List_fromArray(
					[
						$author$project$Alerts$Views$alertConditionsTables(model)
					]))
			]));
};
var $author$project$Api$AlertConditions$Types$conditionToHumanString = function (c) {
	if (c.$ === 'LessThan') {
		return 'less than';
	} else {
		return 'greater than';
	}
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $ryannhg$date_format$DateFormat$AmPmUppercase = {$: 'AmPmUppercase'};
var $ryannhg$date_format$DateFormat$amPmUppercase = $ryannhg$date_format$DateFormat$AmPmUppercase;
var $ryannhg$date_format$DateFormat$Language$Language = F6(
	function (toMonthName, toMonthAbbreviation, toWeekdayName, toWeekdayAbbreviation, toAmPm, toOrdinalSuffix) {
		return {toAmPm: toAmPm, toMonthAbbreviation: toMonthAbbreviation, toMonthName: toMonthName, toOrdinalSuffix: toOrdinalSuffix, toWeekdayAbbreviation: toWeekdayAbbreviation, toWeekdayName: toWeekdayName};
	});
var $ryannhg$date_format$DateFormat$Language$toEnglishAmPm = function (hour) {
	return (hour > 11) ? 'pm' : 'am';
};
var $ryannhg$date_format$DateFormat$Language$toEnglishMonthName = function (month) {
	switch (month.$) {
		case 'Jan':
			return 'January';
		case 'Feb':
			return 'February';
		case 'Mar':
			return 'March';
		case 'Apr':
			return 'April';
		case 'May':
			return 'May';
		case 'Jun':
			return 'June';
		case 'Jul':
			return 'July';
		case 'Aug':
			return 'August';
		case 'Sep':
			return 'September';
		case 'Oct':
			return 'October';
		case 'Nov':
			return 'November';
		default:
			return 'December';
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishSuffix = function (num) {
	var _v0 = A2($elm$core$Basics$modBy, 100, num);
	switch (_v0) {
		case 11:
			return 'th';
		case 12:
			return 'th';
		case 13:
			return 'th';
		default:
			var _v1 = A2($elm$core$Basics$modBy, 10, num);
			switch (_v1) {
				case 1:
					return 'st';
				case 2:
					return 'nd';
				case 3:
					return 'rd';
				default:
					return 'th';
			}
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName = function (weekday) {
	switch (weekday.$) {
		case 'Mon':
			return 'Monday';
		case 'Tue':
			return 'Tuesday';
		case 'Wed':
			return 'Wednesday';
		case 'Thu':
			return 'Thursday';
		case 'Fri':
			return 'Friday';
		case 'Sat':
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $ryannhg$date_format$DateFormat$Language$english = A6(
	$ryannhg$date_format$DateFormat$Language$Language,
	$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishAmPm,
	$ryannhg$date_format$DateFormat$Language$toEnglishSuffix);
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0.a;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.start, posixMinutes) < 0) {
					return posixMinutes + era.offset;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $ryannhg$date_format$DateFormat$amPm = F3(
	function (language, zone, posix) {
		return language.toAmPm(
			A2($elm$time$Time$toHour, zone, posix));
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		day: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		month: month,
		year: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).day;
	});
var $ryannhg$date_format$DateFormat$dayOfMonth = $elm$time$Time$toDay;
var $ryannhg$date_format$DateFormat$days = _List_fromArray(
	[$elm$time$Time$Sun, $elm$time$Time$Mon, $elm$time$Time$Tue, $elm$time$Time$Wed, $elm$time$Time$Thu, $elm$time$Time$Fri, $elm$time$Time$Sat]);
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return $elm$time$Time$Thu;
			case 1:
				return $elm$time$Time$Fri;
			case 2:
				return $elm$time$Time$Sat;
			case 3:
				return $elm$time$Time$Sun;
			case 4:
				return $elm$time$Time$Mon;
			case 5:
				return $elm$time$Time$Tue;
			default:
				return $elm$time$Time$Wed;
		}
	});
var $ryannhg$date_format$DateFormat$dayOfWeek = F2(
	function (zone, posix) {
		return function (_v1) {
			var i = _v1.a;
			return i;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, $elm$time$Time$Sun),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var day = _v0.b;
							return _Utils_eq(
								day,
								A2($elm$time$Time$toWeekday, zone, posix));
						},
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, day) {
									return _Utils_Tuple2(i, day);
								}),
							$ryannhg$date_format$DateFormat$days)))));
	});
var $ryannhg$date_format$DateFormat$isLeapYear = function (year_) {
	return (!(!A2($elm$core$Basics$modBy, 4, year_))) ? false : ((!(!A2($elm$core$Basics$modBy, 100, year_))) ? true : ((!(!A2($elm$core$Basics$modBy, 400, year_))) ? false : true));
};
var $ryannhg$date_format$DateFormat$daysInMonth = F2(
	function (year_, month) {
		switch (month.$) {
			case 'Jan':
				return 31;
			case 'Feb':
				return $ryannhg$date_format$DateFormat$isLeapYear(year_) ? 29 : 28;
			case 'Mar':
				return 31;
			case 'Apr':
				return 30;
			case 'May':
				return 31;
			case 'Jun':
				return 30;
			case 'Jul':
				return 31;
			case 'Aug':
				return 31;
			case 'Sep':
				return 30;
			case 'Oct':
				return 31;
			case 'Nov':
				return 30;
			default:
				return 31;
		}
	});
var $ryannhg$date_format$DateFormat$months = _List_fromArray(
	[$elm$time$Time$Jan, $elm$time$Time$Feb, $elm$time$Time$Mar, $elm$time$Time$Apr, $elm$time$Time$May, $elm$time$Time$Jun, $elm$time$Time$Jul, $elm$time$Time$Aug, $elm$time$Time$Sep, $elm$time$Time$Oct, $elm$time$Time$Nov, $elm$time$Time$Dec]);
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).month;
		switch (_v0) {
			case 1:
				return $elm$time$Time$Jan;
			case 2:
				return $elm$time$Time$Feb;
			case 3:
				return $elm$time$Time$Mar;
			case 4:
				return $elm$time$Time$Apr;
			case 5:
				return $elm$time$Time$May;
			case 6:
				return $elm$time$Time$Jun;
			case 7:
				return $elm$time$Time$Jul;
			case 8:
				return $elm$time$Time$Aug;
			case 9:
				return $elm$time$Time$Sep;
			case 10:
				return $elm$time$Time$Oct;
			case 11:
				return $elm$time$Time$Nov;
			default:
				return $elm$time$Time$Dec;
		}
	});
var $ryannhg$date_format$DateFormat$monthPair = F2(
	function (zone, posix) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, $elm$time$Time$Jan),
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var i = _v0.a;
						var m = _v0.b;
						return _Utils_eq(
							m,
							A2($elm$time$Time$toMonth, zone, posix));
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						$ryannhg$date_format$DateFormat$months))));
	});
var $ryannhg$date_format$DateFormat$monthNumber_ = F2(
	function (zone, posix) {
		return 1 + function (_v0) {
			var i = _v0.a;
			var m = _v0.b;
			return i;
		}(
			A2($ryannhg$date_format$DateFormat$monthPair, zone, posix));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).year;
	});
var $ryannhg$date_format$DateFormat$dayOfYear = F2(
	function (zone, posix) {
		var monthsBeforeThisOne = A2(
			$elm$core$List$take,
			A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) - 1,
			$ryannhg$date_format$DateFormat$months);
		var daysBeforeThisMonth = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$ryannhg$date_format$DateFormat$daysInMonth(
					A2($elm$time$Time$toYear, zone, posix)),
				monthsBeforeThisOne));
		return daysBeforeThisMonth + A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix);
	});
var $ryannhg$date_format$DateFormat$quarter = F2(
	function (zone, posix) {
		return (A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) / 4) | 0;
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $ryannhg$date_format$DateFormat$toFixedLength = F2(
	function (totalChars, num) {
		var numStr = $elm$core$String$fromInt(num);
		var numZerosNeeded = totalChars - $elm$core$String$length(numStr);
		var zeros = A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				function (_v0) {
					return '0';
				},
				A2($elm$core$List$range, 1, numZerosNeeded)));
		return _Utils_ap(zeros, numStr);
	});
var $elm$core$String$toLower = _String_toLower;
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $ryannhg$date_format$DateFormat$toNonMilitary = function (num) {
	return (!num) ? 12 : ((num <= 12) ? num : (num - 12));
};
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$core$String$toUpper = _String_toUpper;
var $ryannhg$date_format$DateFormat$millisecondsPerYear = $elm$core$Basics$round((((1000 * 60) * 60) * 24) * 365.25);
var $ryannhg$date_format$DateFormat$firstDayOfYear = F2(
	function (zone, time) {
		return $elm$time$Time$millisToPosix(
			$ryannhg$date_format$DateFormat$millisecondsPerYear * A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$weekOfYear = F2(
	function (zone, posix) {
		var firstDay = A2($ryannhg$date_format$DateFormat$firstDayOfYear, zone, posix);
		var firstDayOffset = A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, firstDay);
		var daysSoFar = A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix);
		return (((daysSoFar + firstDayOffset) / 7) | 0) + 1;
	});
var $ryannhg$date_format$DateFormat$year = F2(
	function (zone, time) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$piece = F4(
	function (language, zone, posix, token) {
		switch (token.$) {
			case 'MonthNumber':
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 'MonthSuffix':
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.toOrdinalSuffix(num));
				}(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 'MonthFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 'MonthNameAbbreviated':
				return language.toMonthAbbreviation(
					A2($elm$time$Time$toMonth, zone, posix));
			case 'MonthNameFull':
				return language.toMonthName(
					A2($elm$time$Time$toMonth, zone, posix));
			case 'QuarterNumber':
				return $elm$core$String$fromInt(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 'QuarterSuffix':
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.toOrdinalSuffix(num));
				}(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 'DayOfMonthNumber':
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 'DayOfMonthSuffix':
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.toOrdinalSuffix(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 'DayOfMonthFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 'DayOfYearNumber':
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 'DayOfYearSuffix':
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.toOrdinalSuffix(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 'DayOfYearFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 'DayOfWeekNumber':
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 'DayOfWeekSuffix':
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.toOrdinalSuffix(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 'DayOfWeekNameAbbreviated':
				return language.toWeekdayAbbreviation(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 'DayOfWeekNameFull':
				return language.toWeekdayName(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 'WeekOfYearNumber':
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 'WeekOfYearSuffix':
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.toOrdinalSuffix(num));
				}(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 'WeekOfYearFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 'YearNumberLastTwo':
				return A2(
					$elm$core$String$right,
					2,
					A2($ryannhg$date_format$DateFormat$year, zone, posix));
			case 'YearNumber':
				return A2($ryannhg$date_format$DateFormat$year, zone, posix);
			case 'AmPmUppercase':
				return $elm$core$String$toUpper(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 'AmPmLowercase':
				return $elm$core$String$toLower(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 'HourMilitaryNumber':
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toHour, zone, posix));
			case 'HourMilitaryFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toHour, zone, posix));
			case 'HourNumber':
				return $elm$core$String$fromInt(
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 'HourFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 'HourMilitaryFromOneNumber':
				return $elm$core$String$fromInt(
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 'HourMilitaryFromOneFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 'MinuteNumber':
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMinute, zone, posix));
			case 'MinuteFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toMinute, zone, posix));
			case 'SecondNumber':
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toSecond, zone, posix));
			case 'SecondFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toSecond, zone, posix));
			case 'MillisecondNumber':
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMillis, zone, posix));
			case 'MillisecondFixed':
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($elm$time$Time$toMillis, zone, posix));
			default:
				var string = token.a;
				return string;
		}
	});
var $ryannhg$date_format$DateFormat$formatWithLanguage = F4(
	function (language, tokens, zone, time) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A3($ryannhg$date_format$DateFormat$piece, language, zone, time),
				tokens));
	});
var $ryannhg$date_format$DateFormat$format = $ryannhg$date_format$DateFormat$formatWithLanguage($ryannhg$date_format$DateFormat$Language$english);
var $ryannhg$date_format$DateFormat$HourNumber = {$: 'HourNumber'};
var $ryannhg$date_format$DateFormat$hourNumber = $ryannhg$date_format$DateFormat$HourNumber;
var $ryannhg$date_format$DateFormat$MinuteFixed = {$: 'MinuteFixed'};
var $ryannhg$date_format$DateFormat$minuteFixed = $ryannhg$date_format$DateFormat$MinuteFixed;
var $ryannhg$date_format$DateFormat$Text = function (a) {
	return {$: 'Text', a: a};
};
var $ryannhg$date_format$DateFormat$text = $ryannhg$date_format$DateFormat$Text;
var $author$project$Alerts$Views$posixToAlertTime = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[
			$ryannhg$date_format$DateFormat$hourNumber,
			$ryannhg$date_format$DateFormat$text(':'),
			$ryannhg$date_format$DateFormat$minuteFixed,
			$ryannhg$date_format$DateFormat$text(' '),
			$ryannhg$date_format$DateFormat$amPmUppercase
		]));
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 'Zone', a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $author$project$Help$Time$zoneFromOffsetsAndPosix = F2(
	function (offsets, t) {
		zoneFromOffsetsAndPosix:
		while (true) {
			var millis = $elm$time$Time$posixToMillis(t);
			if (offsets.b) {
				if (offsets.b.b) {
					var start = offsets.a;
					var _v1 = offsets.b;
					var end = _v1.a;
					var xs = _v1.b;
					if ((_Utils_cmp(millis, start.millis) > -1) && (_Utils_cmp(millis, end.millis) < 1)) {
						return A2($elm$time$Time$customZone, start.offset, _List_Nil);
					} else {
						var $temp$offsets = A2($elm$core$List$cons, end, xs),
							$temp$t = t;
						offsets = $temp$offsets;
						t = $temp$t;
						continue zoneFromOffsetsAndPosix;
					}
				} else {
					var last = offsets.a;
					return A2($elm$time$Time$customZone, last.offset, _List_Nil);
				}
			} else {
				return A2($elm$time$Time$customZone, 0, _List_Nil);
			}
		}
	});
var $author$project$Help$Time$usingOffsets = F3(
	function (f, offsets, time) {
		var zone = A2($author$project$Help$Time$zoneFromOffsetsAndPosix, offsets, time);
		return A2(f, zone, time);
	});
var $author$project$Alerts$Views$alertRow = F2(
	function (model, alert) {
		var unitType = alert.dataStream.unitType;
		var identifierString = $author$project$Api$AlertConditions$Types$conditionToHumanString(alert.identifier);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(alert.dataStream.name)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromFloat(alert.metadata.value) + (' ' + unitType))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							identifierString + (' ' + ($elm$core$String$fromFloat(alert.metadata.condition.value) + (' ' + unitType))))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							A3($author$project$Help$Time$usingOffsets, $author$project$Alerts$Views$posixToAlertTime, model.offsets, alert.occurredAt))
						]))
				]));
	});
var $justinmimbs$date$Date$RD = function (a) {
	return {$: 'RD', a: a};
};
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m.$) {
			case 'Jan':
				return 0;
			case 'Feb':
				return 31;
			case 'Mar':
				return 59 + leapDays;
			case 'Apr':
				return 90 + leapDays;
			case 'May':
				return 120 + leapDays;
			case 'Jun':
				return 151 + leapDays;
			case 'Jul':
				return 181 + leapDays;
			case 'Aug':
				return 212 + leapDays;
			case 'Sep':
				return 243 + leapDays;
			case 'Oct':
				return 273 + leapDays;
			case 'Nov':
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m.$) {
			case 'Jan':
				return 31;
			case 'Feb':
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 'Mar':
				return 31;
			case 'Apr':
				return 30;
			case 'May':
				return 31;
			case 'Jun':
				return 30;
			case 'Jul':
				return 31;
			case 'Aug':
				return 31;
			case 'Sep':
				return 30;
			case 'Oct':
				return 31;
			case 'Nov':
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return $justinmimbs$date$Date$RD(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
				$elm$core$Basics$clamp,
				1,
				A2($justinmimbs$date$Date$daysInMonth, y, m),
				d));
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $ryannhg$date_format$DateFormat$DayOfMonthNumber = {$: 'DayOfMonthNumber'};
var $ryannhg$date_format$DateFormat$dayOfMonthNumber = $ryannhg$date_format$DateFormat$DayOfMonthNumber;
var $ryannhg$date_format$DateFormat$DayOfWeekNameFull = {$: 'DayOfWeekNameFull'};
var $ryannhg$date_format$DateFormat$dayOfWeekNameFull = $ryannhg$date_format$DateFormat$DayOfWeekNameFull;
var $ryannhg$date_format$DateFormat$MonthNameAbbreviated = {$: 'MonthNameAbbreviated'};
var $ryannhg$date_format$DateFormat$monthNameAbbreviated = $ryannhg$date_format$DateFormat$MonthNameAbbreviated;
var $ryannhg$date_format$DateFormat$YearNumber = {$: 'YearNumber'};
var $ryannhg$date_format$DateFormat$yearNumber = $ryannhg$date_format$DateFormat$YearNumber;
var $author$project$Alerts$Views$posixToAlertDate = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[
			$ryannhg$date_format$DateFormat$dayOfWeekNameFull,
			$ryannhg$date_format$DateFormat$text(', '),
			$ryannhg$date_format$DateFormat$monthNameAbbreviated,
			$ryannhg$date_format$DateFormat$text(' '),
			$ryannhg$date_format$DateFormat$dayOfMonthNumber,
			$ryannhg$date_format$DateFormat$text(', '),
			$ryannhg$date_format$DateFormat$yearNumber
		]));
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0.a;
	return rd;
};
var $author$project$Alerts$Views$alertsIndexView = function (model) {
	var grouped = A2(
		$elm$core$List$map,
		function (_v1) {
			var k = _v1.a;
			var v = _v1.b;
			return _Utils_Tuple2(
				k,
				A2(
					$elm$core$List$sortBy,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.dataStream;
						},
						function ($) {
							return $.name;
						}),
					v));
		},
		A2(
			$elm$core$List$sortBy,
			$elm$core$Tuple$first,
			$elm$core$Dict$toList(
				A2(
					$author$project$Help$Dict$groupBy,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.occurredAt;
						},
						A2(
							$elm$core$Basics$composeR,
							A2($author$project$Help$Time$usingOffsets, $justinmimbs$date$Date$fromPosix, model.offsets),
							$justinmimbs$date$Date$toRataDie)),
					model.alerts))));
	var rows = A2(
		$elm$core$List$concatMap,
		function (_v0) {
			var alerts = _v0.b;
			var date = A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.occurredAt;
						},
						A2($author$project$Help$Time$usingOffsets, $author$project$Alerts$Views$posixToAlertDate, model.offsets)),
					$elm$core$List$head(alerts)));
			return A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$attribute, 'colspan', '5')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(date)
								]))
						])),
				A2(
					$elm$core$List$map,
					$author$project$Alerts$Views$alertRow(model),
					alerts));
		},
		grouped);
	return $elm$core$List$isEmpty(model.alerts) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('info-alert')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('No alerts have been triggered. Set up alert conditions to be notified when your data goes outside expected bounds.')
			])) : A2(
		$elm$html$Html$table,
		$author$project$Help$Lists$catMaybes(
			_List_fromArray(
				[
					$elm$core$Maybe$Just(
					$elm$html$Html$Attributes$class('bordered-section throwback-table')),
					A2(
					$elm$core$Maybe$map,
					$elm$core$Basics$always(
						A2($elm$html$Html$Attributes$style, 'background-color', 'white')),
					model.dataStreamID)
				])),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$colgroup,
				_List_Nil,
				_List_fromArray(
					[
						A2($elm$html$Html$col, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$col,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '150px')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$col,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '185px')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$col,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$thead,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Data Source')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Value')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Alert When')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Occurred')
									]))
							]))
					])),
				A2($elm$html$Html$tbody, _List_Nil, rows)
			]));
};
var $author$project$Alerts$Types$BackfillAll = {$: 'BackfillAll'};
var $author$project$Alerts$Types$BackfillSelected = {$: 'BackfillSelected'};
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === 'RBEmpty_elm_builtin') {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Set$isEmpty = function (_v0) {
	var dict = _v0.a;
	return $elm$core$Dict$isEmpty(dict);
};
var $author$project$Alerts$Views$backfillControl = function (model) {
	var _v0 = model;
	var backfilling = _v0.backfilling;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'float', 'right')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$disabled(
						backfilling || $elm$core$Set$isEmpty(model.checkedAlertConditions)),
						$elm$html$Html$Attributes$class('form__button'),
						$author$project$Help$CSS$inlineBlock,
						A2($elm$html$Html$Attributes$style, 'margin-right', '10px'),
						$elm$html$Html$Events$onClick($author$project$Alerts$Types$BackfillSelected)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Backfill Selected')
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form__button'),
						$elm$html$Html$Attributes$disabled(backfilling),
						$elm$html$Html$Events$onClick($author$project$Alerts$Types$BackfillAll)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Backfill All')
					]))
			]));
};
var $elm$html$Html$header = _VirtualDom_node('header');
var $author$project$Alerts$Types$SetRoute = function (a) {
	return {$: 'SetRoute', a: a};
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Alerts$Views$routeLink = F3(
	function (current, route, name) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('pointer', true),
							_Utils_Tuple2(
							'active',
							_Utils_eq(current, route))
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Alerts$Types$SetRoute(route))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(name)
						]))
				]));
	});
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Alerts$Views$view = function (model) {
	var _v0 = model.dataStreamID;
	if (_v0.$ === 'Just') {
		return $elm$core$List$isEmpty(model.alertConditions) ? $author$project$Help$Html$blank : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('equip-sys__data-mining'),
					A2($elm$html$Html$Attributes$style, 'padding-right', '20px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Alerts')
								]))
						])),
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Alerts$Views$alertConditionsTables(model)
						]))
				]));
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('narrow-layout')
				]),
			_List_fromArray(
				[
					_Utils_eq(model.route, $author$project$Alerts$Types$AlertConditionNew) ? $author$project$Alerts$Views$backfillControl(model) : $author$project$Help$Html$blank,
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav nav-pills distinct')
						]),
					_List_fromArray(
						[
							A3($author$project$Alerts$Views$routeLink, model.route, $author$project$Alerts$Types$AlertConditionNew, 'Alert Conditions'),
							A3($author$project$Alerts$Views$routeLink, model.route, $author$project$Alerts$Types$AlertsIndex, 'Alert History')
						])),
					function () {
					var _v1 = model.route;
					if (_v1.$ === 'AlertConditionNew') {
						return $author$project$Alerts$Views$alertConditionNew(model);
					} else {
						return $author$project$Alerts$Views$alertsIndexView(model);
					}
				}()
				]));
	}
};
var $author$project$Alerts$main = $alex_tan$loadable$Loadable$element(
	{
		errorView: $elm$core$Maybe$Just($author$project$Components$Loadable$errorView),
		failCmd: $elm$core$Maybe$Nothing,
		load: $author$project$Alerts$Load$load,
		loadingView: $elm$core$Maybe$Just(
			function (flags) {
				return $elm_community$maybe_extra$Maybe$Extra$isJust(flags.dataStreamID) ? $author$project$Help$Html$blank : $author$project$Components$Loadable$loadingView(flags);
			}),
		subscriptions: $author$project$Alerts$subscriptions,
		update: $author$project$Alerts$State$update,
		view: $author$project$Alerts$Views$view
	});
_Platform_export({'Alerts':{'init':$author$project$Alerts$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (userID) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (siteID) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (offsets) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (dataStreamID) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (apiConfig) {
											return $elm$json$Json$Decode$succeed(
												{apiConfig: apiConfig, dataStreamID: dataStreamID, offsets: offsets, siteID: siteID, userID: userID});
										},
										A2(
											$elm$json$Json$Decode$field,
											'apiConfig',
											A2(
												$elm$json$Json$Decode$andThen,
												function (jwt) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (authToken) {
															return $elm$json$Json$Decode$succeed(
																{authToken: authToken, jwt: jwt});
														},
														A2($elm$json$Json$Decode$field, 'authToken', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'jwt', $elm$json$Json$Decode$string))));
								},
								A2(
									$elm$json$Json$Decode$field,
									'dataStreamID',
									$elm$json$Json$Decode$oneOf(
										_List_fromArray(
											[
												$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
												A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int)
											]))));
						},
						A2(
							$elm$json$Json$Decode$field,
							'offsets',
							$elm$json$Json$Decode$list(
								A2(
									$elm$json$Json$Decode$andThen,
									function (offset) {
										return A2(
											$elm$json$Json$Decode$andThen,
											function (millis) {
												return $elm$json$Json$Decode$succeed(
													{millis: millis, offset: offset});
											},
											A2($elm$json$Json$Decode$field, 'millis', $elm$json$Json$Decode$int));
									},
									A2($elm$json$Json$Decode$field, 'offset', $elm$json$Json$Decode$int)))));
				},
				A2($elm$json$Json$Decode$field, 'siteID', $elm$json$Json$Decode$int));
		},
		A2($elm$json$Json$Decode$field, 'userID', $elm$json$Json$Decode$int)))(0)}});}(this));