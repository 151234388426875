import React from "react";
import PropTypes from "prop-types";
import Icon from "../../../components/Icon";

export default class RelatedDataStreamsRow extends React.PureComponent {
  static propTypes = {
    navigateToDataStream: PropTypes.func.isRequired,
    stream: PropTypes.object.isRequired
  };

  handleClick = event => {
    const { stream, site } = this.props;
    event.preventDefault();
    const url = `/sites/${site.id}/equipment_and_systems/${stream.id}`;
    this.props.navigateToDataStream(url);
  };

  render() {
    return (
      <li>
        <a href="#" onClick={this.handleClick}>
          {this.props.stream.name}
          <Icon name="angle-right" />
        </a>
      </li>
    );
  }
}
