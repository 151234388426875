import Promise from "bluebird";
import {
  propEq,
  merge,
  find,
  filter,
  map,
  identity,
  pipe,
  reject,
  pathEq,
  prop,
  uniqBy,
  contains
} from "ramda";
import request from "superagent";

import StreamClient from "../../clients/StreamClient";

import { LOAD_STREAM_DATA } from "../../constants/actionTypes";

import { signatureAuthor } from "../../modules/caching";

import {
  getStartMoment,
  getEndMoment
} from "../../../../skeletons/dataVisualization/modules/ComputedAttributes";

export default (dispatch, getState) => {
  var alertPromises, streamPayload;

  const state = getState();

  const {
    activeDataStreamID,
    authToken,
    alertsURL,
    interval,
    streamAlerts,
    dataStreams,
    jwt
  } = state;

  const startMoment = getStartMoment(state),
    endMoment = getEndMoment(state);

  if (!activeDataStreamID) {
    return;
  }

  const dataStream = find(propEq("id", activeDataStreamID), dataStreams);

  const alertParams = {
    start_time: startMoment.unix(),
    end_time: endMoment.unix(),
    limit: 1
  };

  const equipmentSignature = signatureAuthor(
    merge(alertParams, { data_stream_id: dataStream.id })
  );

  const alertsLoaded = !!streamAlerts[equipmentSignature];

  if (alertsLoaded) {
    return new Promise(resolve => resolve());
  }

  const dataStreamRelationships = request
    .get("/rest/data_stream_relationships_view")
    .query({
      owner_data_stream_id: `eq.${dataStream.id}`
    })
    .setJWT(jwt)
    .then(resp => {
      const dataStreamRelationships = map(
        prop("related_data_stream_id", resp.body)
      );

      const associatedStreams = filter(d => {
        contains(d.id, dataStreamRelationships);
      }, dataStreams);

      const streamToAlertPromise = dataStream =>
        request
          .get(alertsURL)
          .query(merge(alertParams, { data_stream_id: dataStream.id }))
          .setAuthToken(authToken)
          .then(({ body }) => body.alerts && body.alerts[0]);
      alertPromises = map(streamToAlertPromise, associatedStreams);
      alertPromises.push(streamToAlertPromise(dataStream));
      return Promise.all(alertPromises).then(alerts => {
        const presentAlerts = filter(identity, alerts);

        streamPayload.alerts = presentAlerts;

        const relatedStreamWithAlerts = pipe(
          reject(pathEq(["data_stream", "id"], activeDataStreamID)),
          map(prop("data_stream")),
          uniqBy(prop("id"))
        )(presentAlerts);

        return Promise.all(map(streamToGraphPromise, relatedStreamWithAlerts));
      });
    });

  streamPayload = {
    type: LOAD_STREAM_DATA,
    alerts: [],
    data: {},
    signature: equipmentSignature
  };

  const query = {
    interval: interval.asSeconds(),
    start_time: startMoment.unix(),
    end_time: endMoment.unix()
  };

  const streamToGraphPromise = stream => {
    const client = new StreamClient(stream, { authToken });

    const signature = signatureAuthor(
      merge(alertParams, { data_stream_id: stream.id })
    );

    return client.fetch(query).then(row => {
      streamPayload.data[signature] = row;
    });
  };

  return Promise.all([
    streamToGraphPromise(dataStream),
    dataStreamRelationships
  ]).then(() => {
    dispatch(streamPayload);
  });
};
