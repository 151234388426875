import moment from "moment-timezone";
import { LOAD_SITE_SUCCESS } from "../constants/actionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case LOAD_SITE_SUCCESS:
      const { site } = action;
      const endDataLimit = site.end_data_limit,
        timeZone = site.time_zone;

      return endDataLimit ? moment.tz(endDataLimit, timeZone) : state;
    default:
      return state;
  }
};
