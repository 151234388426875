import { SET_START_TIME } from "../constants/actionTypes";

import ensureStreamsLoaded from "./subactions/ensureStreamsLoaded";
import { ensureRequiredAttributesExist } from "../../../modules/StateHelpers";

export default startTime => (dispatch, getState) => {
  const state = getState();
  const { site } = state;

  ensureRequiredAttributesExist(["site"], state);

  dispatch({
    timeZone: site.time_zone,
    type: SET_START_TIME,
    startTime
  });

  ensureStreamsLoaded(dispatch, getState);
};
