import { merge } from "ramda";
import { EQUIPMENT_DAY_DATA_LOADED } from "../constants/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case EQUIPMENT_DAY_DATA_LOADED:
      return merge(state, {
        [action.id]: action.data
      });
    default:
      return state;
  }
};
