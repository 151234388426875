import { always } from "ramda";
import { combineReducers } from "redux";

import endTimeBound from "../../../skeletons/dataVisualization/reducers/endTimeBound";
import site from "../../../skeletons/dataVisualization/reducers/site";
import startTime from "../../../skeletons/dataVisualization/reducers/startTime";
import startTimeBound from "../../../skeletons/dataVisualization/reducers/startTimeBound";

import dataStreams from "./dataStreams";
import dataStreamsLoaded from "./dataStreamsLoaded";
import activeDataStreamID from "./activeDataStreamID";
import activeAlertID from "./activeAlertID";
import streamData from "./streamData";
import streamAlerts from "./streamAlerts";

import staticReducer from "../../../reducers/staticReducer";

import {
  defaultSetting,
  oneHourInterval
} from "../../../constants/zoomSettings";

export default combineReducers({
  dataStreams,
  dataStreamsLoaded,
  activeDataStreamID,
  activeAlertID,
  streamData,
  streamAlerts,
  endTimeBound,
  site,
  startTime,
  startTimeBound,

  authToken: staticReducer,
  offsets: staticReducer,
  jwt: staticReducer,
  alertsURL: staticReducer,
  siteID: staticReducer,
  siteURL: staticReducer,
  isAdmin: staticReducer,
  dataStreamsURL: staticReducer,
  dataVisualizationURL: staticReducer,
  zoomSetting: always(defaultSetting),
  interval: always(oneHourInterval)
});
