import PropTypes from "prop-types";
import { values } from "ramda";
import React from "react";

import ThumbChart from "../../../components/ThumbChart";

const styles = {
  checkbox: {
    marginRight: "20px"
  },

  placeholder: {
    display: "inline-block",
    width: "28px"
  }
};

export default class extends React.Component {
  static propTypes = {
    graph: PropTypes.object,
    isSelected: PropTypes.bool.isRequired,
    stream: PropTypes.object,
    toggleDataStream: PropTypes.func.isRequired
  };

  handleToggle = () => {
    const { stream, toggleDataStream } = this.props;

    toggleDataStream(stream);
  };

  render() {
    const { graph, isSelected, stream } = this.props;

    if (!stream) {
      return <span />;
    }

    const htmlID = `stream-option-${stream.id}`;

    return (
      <li className={isSelected ? "selected" : ""}>
        <div style={{ width: "38px" }}>
          {stream && (
            <input
              id={htmlID}
              type="checkbox"
              checked={isSelected}
              onChange={this.handleToggle}
              style={styles.checkbox}
              className="square-checkbox"
            />
          )}

          {!stream && "-"}
        </div>
        <div onClick={this.handleToggle}>
          {graph && (
            <ThumbChart
              values={values(graph.data)}
              className={`thumb-chart-${graph.value}`}
              strokeColor={graph.color}
            />
          )}
        </div>
        <div>
          <label htmlFor={htmlID}>{stream.name}</label>
        </div>
      </li>
    );
  }
}
