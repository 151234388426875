import d3 from "d3";
import {
  pipe,
  invert,
  mapObjIndexed,
  head,
  filter,
  prop,
  sort,
  map,
  propOr,
  sum
} from "ramda";
import React from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";

import Base from "./Base";

import HUMANIZED_BILL_TYPES from "../../constants/humanizedBillTypes";
import SCALE_FACTORS from "../../constants/scaleFactors";
import UNIT_TYPES from "../../constants/unitTypes";

const DEHUMANIZED_BILL_TYPES = pipe(
  invert,
  mapObjIndexed(head)
)(HUMANIZED_BILL_TYPES);

const format = d3.format(",.2r");

const yTickFormat = v => `${format(v)} kBTU`;

const billToValue = bill => bill.consumption * SCALE_FACTORS[bill.bill_type];

import { isNumeric } from "../../../../modules/NumberHelpers";

const filterAndSortNodes = pipe(
  filter(
    pipe(
      prop("value"),
      isNumeric
    )
  ),
  sort((a, b) => b.value - a.value)
);

const tooltipContents = (data, titleFormat, valueFormat, color) =>
  ReactDOMServer.renderToString(
    <table className="c3-tooltip">
      <tbody>
        <tr key="title">
          <th colSpan="2">{titleFormat(data[0].x)}</th>
        </tr>
        {filterAndSortNodes(data).map(node => {
          const dehumanizedBillType = DEHUMANIZED_BILL_TYPES[node.id];
          const scaleFactor = SCALE_FACTORS[dehumanizedBillType];
          const unitType = UNIT_TYPES[dehumanizedBillType];

          return (
            <tr key={node.id}>
              <td className="name">
                <span style={{ backgroundColor: color(node) }} /> {node.name}
              </td>
              <td className="value">
                {format(node.value)}
                {" kBTU "}({format(node.value / scaleFactor)} {unitType})
              </td>
            </tr>
          );
        })}
        <tr key="totals">
          <td className="name">Total</td>
          <td className="value">
            {pipe(
              map(propOr(0, "value")),
              sum,
              format
            )(data)}{" "}
            kBTU
          </td>
        </tr>
      </tbody>
    </table>
  );

export default class extends React.Component {
  static propTypes = {
    monthlyBills: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  render() {
    const { monthlyBills } = this.props;

    return (
      <Base
        monthlyBills={monthlyBills}
        billToValue={billToValue}
        tooltipContents={tooltipContents}
        yTickFormat={yTickFormat}
      />
    );
  }
}
