import { invoker } from "ramda";

// Returns the date given the number of seconds from the unix epoch.
// Number -> Date
const dateFromUnixSeconds = seconds => new Date(seconds * 1000);

const greaterMoment = (a, b) => (a.isAfter(b) ? a : b);
const lesserMoment = (a, b) => (a.isBefore(b) ? a : b);

const momentToUnix = invoker(0, "unix");

// Given a start, end, and interval, all in seconds, calculate the unix seconds
// at the interval between.
// Date -> Date -> Integer -> Array
const timesBetweenDates = (unixStart, unixEnd, unixInterval) => {
  var current, unixIntervals;

  unixIntervals = [];
  current = unixStart;

  while (current <= unixEnd) {
    unixIntervals.push(current);
    current = current + unixInterval;
  }

  return unixIntervals;
};

export {
  dateFromUnixSeconds,
  greaterMoment,
  lesserMoment,
  momentToUnix,
  timesBetweenDates
};
