import moment from "moment-timezone";

const calculateStartTimeFromSite = (
  days,
  { start_data_limit, end_data_limit, time_zone }
) => {
  const startOfThisHour = moment()
      .tz(time_zone)
      .startOf("hour"),
    sixtyDaysAgo = startOfThisHour.subtract(days, "days");

  const shouldReturnStartDataLimit =
    (start_data_limit && sixtyDaysAgo.isBefore(start_data_limit)) ||
    (end_data_limit && sixtyDaysAgo.isAfter(end_data_limit));

  if (shouldReturnStartDataLimit) {
    return moment
      .tz(start_data_limit, time_zone)
      .add(1, "hour")
      .startOf("hour");
  }

  return sixtyDaysAgo;
};

export { calculateStartTimeFromSite };
