import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import DatePicker from "react-datepicker";

import { DATEPICKER_FORMAT } from "../../../modules/TimeFormats";

export default class extends React.Component {
  static propTypes = {
    endTimeBound: PropTypes.object,
    setStartTime: PropTypes.func.isRequired,
    startTime: PropTypes.string.isRequired,
    startTimeBound: PropTypes.object,
    timeZone: PropTypes.string.isRequired
  };

  setStartTime = startTime => {
    const { timeZone } = this.props;
    const startMoment = moment.tz(startTime, DATEPICKER_FORMAT, timeZone);
    this.props.setStartTime(startMoment.format(DATEPICKER_FORMAT));
  };

  render() {
    const { startTime, endTimeBound, startTimeBound, timeZone } = this.props;

    return (
      <div className="data-viz__date-picker">
        <DatePicker
          selected={moment.tz(startTime, DATEPICKER_FORMAT, timeZone)}
          onChange={this.setStartTime}
          dateFormat={DATEPICKER_FORMAT}
          minDate={startTimeBound}
          maxDate={endTimeBound}
          weekStart="0"
        />
      </div>
    );
  }
}
