import { LOAD_SITE_SUCCESS } from "../../../skeletons/dataVisualization/constants/actionTypes";

const EQUIPMENT_DAY_DATA_LOADED = "EQUIPMENT_DAY_DATA_LOADED";
const EQUIPMENT_METRICS_LOADED = "EQUIPMENT_METRICS_LOADED";
const LOAD_EQUIPMENT_SUCCESS = "LOAD_EQUIPMENT_SUCCESS";
const LOAD_STORIES_SUCCESS = "LOAD_STORIES_SUCCESS";
const LOAD_EQUIPMENT_SYSTEMS_SUCCESS = "LOAD_EQUIPMENT_SYSTEMS_SUCCESS";
const SELECT_EQUIPMENT = "SELECT_EQUIPMENT";
const SELECT_TAB = "SELECT_TAB";
const LOAD_METADATAS = "LOAD_METADATAS";

export {
  LOAD_SITE_SUCCESS,
  EQUIPMENT_DAY_DATA_LOADED,
  EQUIPMENT_METRICS_LOADED,
  LOAD_EQUIPMENT_SUCCESS,
  LOAD_STORIES_SUCCESS,
  LOAD_EQUIPMENT_SYSTEMS_SUCCESS,
  LOAD_METADATAS,
  SELECT_EQUIPMENT,
  SELECT_TAB
};
