import initialLoad from "./initialLoad";
import loadEquipmentData from "./loadEquipmentData";
import selectBillTab from "./selectBillTab";
import selectEquipment from "./selectEquipment";

export default {
  initialLoad,
  loadEquipmentData,
  selectBillTab,
  selectEquipment
};
