import { merge } from "ramda";
import Promise from "bluebird";
import request from "superagent";

import {
  LOAD_EQUIPMENT_SUCCESS,
  LOAD_EQUIPMENT_SYSTEMS_SUCCESS,
  LOAD_SITE_SUCCESS,
  LOAD_METADATAS
  // SELECT_TAB
} from "../constants/actionTypes";

import { sortByName } from "../../../modules/Sorters";

import handleUnexpectedError from "../../../lib/handleUnexpectedError";

// const isNumNotNull = n => n && !isNaN(+n);

// const hasDemandData = ({ actual_rate, billed_rate }) =>
//   isNumNotNull(actual_rate) || isNumNotNull(billed_rate);

export default () => (dispatch, getState) => {
  const {
    authToken,
    jwt,
    dataStreamsURL,
    equipmentSystemsURL,
    siteID,
    siteURL
  } = getState();
  // monthlyBills

  // const demandData = pipe(
  //   filter(propEq("bill_type", "energy")),
  //   filter(hasDemandData)
  // )(monthlyBills);

  // If we have demand Data, set defaults to "Demand" Tab
  // if (demandData.length > 0) {
  //   dispatch({
  //     type: SELECT_TAB,
  //     tab: "Demand"
  //   });
  // }

  Promise.props({
    // Load the system types
    systemTypes: request
      .get(equipmentSystemsURL)
      .query({ site_id: siteID })
      .setAuthToken(authToken)
      .then(
        resp => ({
          type: LOAD_EQUIPMENT_SYSTEMS_SUCCESS,

          equipmentSystems: resp.body.equipment_systems
        }),
        handleUnexpectedError
      ),

    site: request
      .get(siteURL)
      .setAuthToken(authToken)
      .then(
        resp => ({
          type: LOAD_SITE_SUCCESS,
          site: resp.body.site
        }),
        handleUnexpectedError
      ),

    equipment: request
      .get(dataStreamsURL)
      .query({
        site_id: `eq.${siteID}`,
        stream_type: "eq.equipment",
        select: `*,breakers(*),equipment_system(*)`
      })
      .setJWT(jwt)
      .then(
        resp => ({
          type: LOAD_EQUIPMENT_SUCCESS,
          equipment: sortByName(resp.body)
        }),
        handleUnexpectedError
      ),

    metadatas: request
      .get("/api/data_stream_metadatas")
      .setAuthToken(authToken)
      .then(resp => ({
        type: LOAD_METADATAS,
        metadatas: resp.body.data_stream_metadatas
      }))
  }).then(({ systemTypes, site, equipment, metadatas }) => {
    dispatch(systemTypes);
    dispatch(site);
    dispatch(
      merge(equipment, {
        metadatas: metadatas.metadatas,
        equipmentSystems: systemTypes.equipmentSystems
      })
    );
    dispatch(metadatas);
  });
};
