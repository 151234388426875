import request from "superagent";
import * as Cookies from "js-cookie";
// import Promise from "bluebird";

window.requestJWT = () =>
  request
    .post("/api/jwts")
    .setAuthToken(window.authToken)
    .then(
      resp => {
        const { token } = resp.body;
        const { href } = location;

        var domain;
        if (href.match(/http:\/\/localhost/)) {
          domain = "localhost";
        } else if (href.match(/http:\/\/0\.0\.0\.0/)) {
          domain = "0.0.0.0";
        } else {
          domain = ".bractlet.com";
        }
        Cookies.set("jwt", token, { domain });
        return token;
      },
      err => {
        if (window.bugsnagClient) {
          window.bugsnagClient.notify(err);
        }
      }
    );
