import { path, prop, curry, forEach, ifElse } from "ramda";

const valueFetcher = ifElse(
  Array.isArray, // test if it's an array
  path,
  prop
);

const ensureRequiredAttributesExist = curry((attributes, state) => {
  forEach(attribute => {
    const value = valueFetcher(attribute)(state);

    if (!value) {
      console.warn("Expected", attribute, "to be set in state. Got", value);
      console.trace();
    }
  }, attributes);

  return state;
});

export { ensureRequiredAttributesExist };
