import PropTypes from "prop-types";
import { path, equals, map, head, fromPairs, zip, pipe, drop } from "ramda";
import c3 from "c3";
import React from "react";

const extractPattern = path(["settings", "color", "pattern"]);

export default class extends React.Component {
  static propTypes = {
    id: PropTypes.any.isRequired,
    settings: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { settings } = this.props;
    settings.bindto = `#chart-${this.props.id}`;
    this.chart = c3.generate(settings);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.chart = this.chart.destroy();
      this.componentDidMount();
      return;
    }

    const { settings } = this.props;
    if (settings.data) {
      this.chart.load(settings.data);
    }
    if (settings.grid && settings.grid.y) {
      this.chart.ygrids(settings.grid.y.lines);
    }
    if (settings.legend && settings.legend.hide) {
      this.chart.legend.hide(settings.legend.hide);
    }
    if (settings.size) {
      this.chart.resize(settings.size);
    }

    if (!equals(extractPattern(prevProps), extractPattern(this.props))) {
      const keys = pipe(
        drop(1),
        map(head)
      )(this.props.settings.data.columns);
      const values = extractPattern(this.props);
      const object = fromPairs(zip(keys, values));

      this.chart.data.colors(object);
    }
  }

  componentWillUnmount() {
    this.chart = this.chart.destroy();
  }

  render() {
    return <div id={`chart-${this.props.id}`} />;
  }
}
