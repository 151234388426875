import {
  reduce,
  min,
  max,
  always,
  isEmpty,
  curry,
  add,
  ifElse,
  contains,
  reject,
  equals,
  append,
  isNil
} from "ramda";

const toggleValue = curry((value, array) =>
  ifElse(contains(value), reject(equals(value)), append(value))(array)
);

const compact = reject(isNil);
const arraySum = reduce(add, 0);

const arrayMin = ifElse(isEmpty, always(null), reduce(min, Infinity));

const arrayMax = ifElse(isEmpty, always(null), reduce(max, -Infinity));

export { arrayMin, arrayMax, arraySum, compact, toggleValue };
