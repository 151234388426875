import { map, prop, splitEvery } from "ramda";
import request from "superagent";

import {
  EQUIPMENT_DAY_DATA_LOADED,
  EQUIPMENT_METRICS_LOADED
} from "../constants/actionTypes";

const mapValues = map(prop("value"));

const oneHourInSeconds = 60 * 60;
const oneDayInSeconds = oneHourInSeconds * 24;

const findMaxDataPoint = list => {
  var winner = list[0];

  list.forEach(item => {
    if (item.value > winner.value) {
      winner = item;
    }
  });

  return winner;
};

export default equipment => (dispatch, getState) => {
  const {
    authToken,
    narrowStartTime,
    narrowEndTime,
    wideStartTime,
    wideEndTime
  } = getState();

  // TODO: Create a module with all urls
  const data_path = `https://api.bractlet.com/timescale/${equipment.id}`;

  // Make the request for the squiggly line data.
  request
    .get(data_path)
    .setAuthToken(authToken)
    .query({
      start_time: narrowStartTime.unix(),
      end_time: narrowEndTime.unix(),
      interval: oneHourInSeconds
    })
    .then(resp => {
      const { data_stream } = resp.body;
      const data = mapValues(data_stream.data);
      dispatch({
        type: EQUIPMENT_DAY_DATA_LOADED,
        id: data_stream.id,
        data
      });
    });

  // Make the request for the 60 day, daily interval data.
  request
    .get(data_path)
    .setAuthToken(authToken)
    .query({
      start_time: wideStartTime.unix(),
      end_time: wideEndTime.unix(),
      interval: oneDayInSeconds
    })
    .then(resp => {
      const { data } = resp.body.data_stream;
      const lastThirtyDaysDataPoints = data.length
        ? splitEvery(data.length / 2, data)[1]
        : [];

      const maximumOverFullTimeSpan = findMaxDataPoint(data),
        maximumInLastHalf = findMaxDataPoint(lastThirtyDaysDataPoints);

      dispatch({
        type: EQUIPMENT_METRICS_LOADED,
        id: equipment.id,
        metrics: {
          maximumInLastHalf,
          maximumOverFullTimeSpan
        }
      });
    });
};
