import { signatureFromParams } from "../../../modules/EquivalenceHelpers";

const signatureAuthor = signatureFromParams([
  "interval",
  "startTime",
  "timeSpan"
]);

// The function signature is such that state can be passed directly to it.
export default state => {
  const { startTime, interval, zoomSetting } = state;

  return signatureAuthor({
    startTime,
    interval: interval.id,
    timeSpan: zoomSetting.timeSpan.id
  });
};
