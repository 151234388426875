import { pipe, map, merge } from "ramda";

import { compact, arrayMin, arrayMax } from "./ArrayHelpers";

const compactAndMin = pipe(
    compact,
    arrayMin
  ),
  compactAndMax = pipe(
    compact,
    arrayMax
  );

export default class DerivesUnitTypePropertiesFromStreams {
  constructor(rows) {
    this.unitTypes = {};

    rows.forEach(row => {
      const { unitType } = row;

      this.unitTypes[unitType] = this.unitTypes[unitType] || {};

      this.unitTypes[unitType].minimumValue = compactAndMin([
        this.minimumForType(unitType),
        row.min
      ]);

      this.unitTypes[unitType].maximumValue = compactAndMax([
        this.maximumForType(unitType),
        row.max
      ]);
    });
  }

  /**
   * Returns the minimum value for a given unit type.
   * @return [Number]
   */
  minimumForType(type) {
    return this.unitTypes[type].minimumValue;
  }

  /**
   * Returns the maximum value for a given unit type.
   * @return [Number]
   */
  maximumForType(type) {
    return this.unitTypes[type].maximumValue;
  }

  scaleData(unitType, value) {
    const max = this.maximumForType(unitType),
      min = this.minimumForType(unitType);

    const difference = max - min;
    return difference > 0 ? (value - min) / difference : 0;
  }

  unscaleData(unitType, value) {
    const max = this.maximumForType(unitType),
      min = this.minimumForType(unitType);

    const difference = max - min;
    return value * difference + min;
  }

  scaleRow(row) {
    const unitType = row.unitType;

    const scaledData = map(v => this.scaleData(unitType, v), row.data);

    return merge(row, { data: scaledData });
  }
}
