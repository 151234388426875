import PropTypes from "prop-types";
import React from "react";
import Spinner from "../../../components/Spinner";
import PlotRun from "./PlotRun";

const KEY_ESC = 27;
const KEY_ARROW_LEFT = 37;
const KEY_ARROW_RIGHT = 39;

export default class extends React.Component {
  static propTypes = {
    plotRuns: PropTypes.arrayOf(PropTypes.object),
    selectPlotRun: PropTypes.func.isRequired,
    selectedPlotRun: PropTypes.number
  };

  renderPlot = (plotRun, index) => (
    <PlotRun
      key={index}
      index={index}
      plotRun={plotRun}
      selected={index === this.props.selectedPlotRun}
      selectPlotRun={this.props.selectPlotRun}
    />
  );

  handleKeyDown = e => {
    const { selectPlotRun, selectedPlotRun } = this.props;

    if (selectedPlotRun !== null) {
      switch (e.which) {
        case KEY_ARROW_LEFT:
          e.preventDefault();
          selectPlotRun((selectedPlotRun || 0) - 1);
          break;
        case KEY_ARROW_RIGHT:
          e.preventDefault();
          selectPlotRun((selectedPlotRun || 0) + 1);
          break;
        case KEY_ESC:
          e.preventDefault();
          selectPlotRun(selectedPlotRun);
          break;
      }
    }
  };

  renderBody = () => {
    const { plotRuns } = this.props;

    if (plotRuns.length) {
      return (
        <div
          style={{ width: plotRuns.length * 235 + 15 }}
          className="equip-sys__data-mining__wrapper"
          tabIndex={-1}
          onKeyDown={this.handleKeyDown}
        >
          {plotRuns.map(this.renderPlot)}
        </div>
      );
    }

    return <p>No data to display.</p>;
  };

  render() {
    const { plotRuns } = this.props;
    return (
      <div className="equip-sys__data-mining">
        <header>
          <h3>Data-mining Information</h3>
        </header>
        <section>{plotRuns ? this.renderBody() : <Spinner />}</section>
      </div>
    );
  }
}
