import $ from "jquery";
import c3 from "c3";
import {
  addIndex,
  map,
  pipe,
  prop,
  flatten,
  uniq,
  indexBy,
  assoc,
  merge,
  reduce,
  filter,
  fromPairs
} from "ramda";
import { formatNumberWithUnitType } from "../../skeletons/dataVisualization/modules/Chart";
import moment from "moment-timezone";
import ElmInit from "../../modules/ElmInit";

const colorToHex = ({ red, green, blue }) =>
  `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;

const mapIdx = addIndex(map);

function intervalsAndValues(streams) {
  const intervals = pipe(
    map(prop("dataPoints")),
    flatten,
    map(prop("time")),
    uniq
  )(streams);

  const values = map(s => {
    const vals = indexBy(prop("time"), s.dataPoints);
    return map(t => (vals[t] || null) && vals[t].value * s.scale, intervals);
  }, streams);

  return { intervals, values };
}

const makeYMap = fn =>
  addIndex(reduce)((obj, s, i) => assoc(`y${i}`, fn(s, i), obj), {});

const names = makeYMap(s => s.name);
const colors = makeYMap(({ color }) => colorToHex(color));
const classes = makeYMap(s => s.dashType && `dashed-${s.dashType}`);
const allProps = makeYMap(({ unitType, scale }) => ({ unitType, scale }));
const extractParams = pipe(
  s => s.split("&"),
  map(p => p.split("=")),
  filter(p => p.length === 2),
  fromPairs
);

const defaultProps = {
  intervalConfig: {
    startTime: null,
    interval: null,
    zoom: null
  },
  selectedIds: {
    streamIds: [],
    simStreamIds: []
  }
};

const extractInt = s => (isNaN(+s) ? null : +s);

const extractIntList = pipe(
  s => s.split(","),
  map(extractInt),
  filter(s => s)
);

const getPropsFromUri = () => {
  const searchString = window.location.href.split("?")[1];
  if (!searchString) return {};

  const params = extractParams(searchString);
  return {
    intervalConfig: {
      startTime: extractInt(params.start_time),
      interval: extractInt(params.interval),
      zoom: extractInt(params.zoom_setting)
    },
    selectedIds: {
      streamIds: extractIntList(params.stream_ids || ""),
      simStreamIds: extractIntList(params.sim_stream_ids || "")
    }
  };
};

window.addURLFunctionality = app => {
  // Inform app of browser navigation (the BACK and FORWARD buttons)
  document.addEventListener("popstate", () => {
    app.ports.onUrlChange.send(location.href);
  });

  window.addEventListener("hashchange", () => {
    app.ports.onUrlChange.send(location.href);
  });

  app.ports.pushUrl.subscribe(url => {
    if (window.navigationConfirmation) {
      if (!confirm(window.navigationConfirmation)) {
        return;
      }
    }
    history.pushState({}, "", url);
    app.ports.onUrlChange.send(location.href);
  });
};

export default (node, flags) => {
  const ElmDataVis = ElmInit(window.Elm.DataVisualizer);
  const selector = "#data-visualizer-graph";
  const finalProps = reduce(merge, {}, [
    defaultProps,
    flags,
    { url: location.href },
    getPropsFromUri()
  ]);
  var timeZone = "America/Chicago";
  return ElmDataVis(node, finalProps).then(elmApp => {
    window.addURLFunctionality(elmApp);
    const baseFormat = "M/D h:mm A";
    const tooltipFormat = `dddd, ${baseFormat}`;
    const dateFormatter = t => moment.tz(t, timeZone).format(baseFormat);
    const tooltipTitleFormatter = t =>
      moment.tz(t, timeZone).format(tooltipFormat);
    var streams = [];
    var chart;
    var resizeTimeout;
    var connectGaps = false;

    const dataFor = streams => {
      const { intervals, values } = intervalsAndValues(streams);
      const valColumns = mapIdx((s, i) => [`y${i}`].concat(values[i]), values);
      const timeColumn = ["x"].concat(intervals);

      return {
        xFormat: dateFormatter,
        x: "x",
        columns: [timeColumn].concat(valColumns),
        names: names(streams),
        colors: colors(streams),
        classes: classes(streams)
      };
    };

    const initializeGraph = () => {
      const sProps = allProps(streams);
      const data = dataFor(streams);

      return c3.generate({
        bindto: selector,
        data,
        axis: {
          x: {
            tick: {
              format: dateFormatter,
              count: 5
            },
            type: "timeseries"
          },
          y: {
            min: -0.01,
            show: false
          }
        },
        line: { connectNull: connectGaps },
        padding: { left: 40, right: 40 },
        point: {
          focus: {
            expand: {
              enabled: true,
              r: 3
            }
          },

          show: true,
          r: 0
        },
        tooltip: {
          format: {
            title: tooltipTitleFormatter,
            value: (v, r, id) =>
              formatNumberWithUnitType(
                v / sProps[id].scale,
                sProps[id].unitType
              )
          }
        },
        subchart: {
          show: true,
          onbrush: onBrush
        },
        transition: {
          duration: null
        }
      });
    };

    const reinitializeGraph = () => {
      if (chart) {
        chart.destroy();
        chart = null;
        $(selector).html("");
      }

      if (streams.length > 0) {
        chart = initializeGraph();
      }
    };

    const onBrush = domain => {
      elmApp.ports.subChart.send({
        start: domain[0].toISOString(),
        end: domain[1].toISOString()
      });
    };

    elmApp.ports.selectedStreams.subscribe(newStreams => {
      streams = newStreams;
      // Do it async, so flex layout can settle down
      window.setTimeout(reinitializeGraph, 0);
    });

    elmApp.ports.connectGaps.subscribe(newConnectGaps => {
      connectGaps = newConnectGaps;
      reinitializeGraph();
    });

    elmApp.ports.timeZone.subscribe(tz => {
      timeZone = tz;
    });

    elmApp.ports.dateChange.subscribe(dateStr => {
      const t = moment.tz(dateStr, timeZone);
      elmApp.ports.timeChange.send(t.unix() * 1000);
    });

    // Reinitialzie on resize
    $(window).resize(() => {
      if (resizeTimeout) {
        window.clearTimeout(resizeTimeout);
        resizeTimeout = null;
      }
      resizeTimeout = setTimeout(() => {
        reinitializeGraph();
        resizeTimeout = null;
      }, 20);
    });
  });
};
