import { prop, map, merge, find } from "ramda";
import moment from "moment-timezone";

const UNIT_SHORTENED = {
  minutes: "M",
  days: "D",
  hours: "H",
  weeks: "W",
  years: "Y"
};

const duration = (n, unit) => {
  var result = moment.duration(n, unit);
  result.id = result.asSeconds();
  result.label = `${n}${UNIT_SHORTENED[unit]}`;
  return result;
};

const oneDayInterval = duration(1, "days");
const oneHourInterval = duration(1, "hours");

const zoomSettings = map(
  setting =>
    merge(setting, {
      id: setting.timeSpan.id,
      label: setting.timeSpan.label
    }),
  [
    {
      timeSpan: duration(1, "days"),
      intervals: [
        duration(10, "minutes"),
        duration(15, "minutes"),
        oneHourInterval
      ]
    },
    {
      timeSpan: duration(1, "weeks"),
      intervals: [oneHourInterval, oneDayInterval]
    },
    {
      default: true,
      timeSpan: duration(30, "days"),
      intervals: [oneHourInterval, oneDayInterval]
    },
    {
      timeSpan: duration(1, "years"),
      intervals: [oneDayInterval, duration(30, "days")]
    }
  ]
);

const defaultSetting = find(prop("default"), zoomSettings);

export { zoomSettings, defaultSetting, oneDayInterval, oneHourInterval };
