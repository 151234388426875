import PropTypes from "prop-types";
import React from "react";

import AlertRow from "./AlertRow";
import Icon from "../../../components/Icon";

const columns = {
  date: { width: "15%" },
  description: { width: "70%" },
  graph: { width: "15%" }
};

export default class extends React.Component {
  static propTypes = {
    activeAlertID: PropTypes.number,
    alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectAlert: PropTypes.func.isRequired,
    timeZone: PropTypes.string.isRequired
  };

  render() {
    const { activeAlertID, alerts, selectAlert, timeZone } = this.props;

    return (
      <table>
        <colgroup>
          <col style={columns.date} />
          <col style={columns.description} />
          <col style={columns.graph} />
        </colgroup>
        <thead>
          <tr>
            <th>
              Date <Icon name="angle-down" />
            </th>
            {false && (
              <th>
                Type <Icon name="angle-down" />
              </th>
            )}
            <th>Description</th>
            <th>Graph</th>
          </tr>
        </thead>
        <tbody>
          {alerts.map(alert => (
            <AlertRow
              key={alert.id}
              alert={alert}
              active={alert.id === activeAlertID}
              selectAlert={selectAlert}
              timeZone={timeZone}
            />
          ))}
        </tbody>
      </table>
    );
  }
}
