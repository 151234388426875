import { find, pipe, map, path, propEq, groupBy, contains } from "ramda";
import { LOAD_EQUIPMENT_SUCCESS } from "../constants/actionTypes";

import systemTypes from "../constants/systemTypes";

const getSystemType = path(["equipment_system", "system_type", "identifier"]);
const getSystemTypeDisplayName = path([
  "equipment_system",
  "system_type",
  "display_name"
]);

const groupEquipmentBySystemType = groupBy(equipment => {
  const systemType = getSystemType(equipment);
  if (contains(systemType, systemTypes)) {
    return getSystemTypeDisplayName(equipment);
  }

  return "Uncategorized Equipment";
});

export default (state = {}, action) => {
  const { metadatas, equipmentSystems, equipment } = action;
  switch (action.type) {
    case LOAD_EQUIPMENT_SUCCESS:
      return pipe(
        map(dataStream => {
          const metadata = find(
            propEq("name", dataStream.brick_class_name),
            metadatas
          );
          const equipmentSystem = dataStream.equipment_system
            ? find(
                propEq("id", dataStream.equipment_system.id),
                equipmentSystems
              )
            : null;
          dataStream.metadata_definition = metadata;
          dataStream.equipment_system = equipmentSystem;
          return dataStream;
        }),
        groupEquipmentBySystemType
      )(equipment);
    default:
      return state;
  }
};
