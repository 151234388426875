import { reject, pipe, equals, append } from "ramda";
import { CLEAR_SIGNATURE, LOAD_STREAM_DATA } from "../constants/actionTypes";

export default (state = [], action) => {
  const { signature } = action;

  switch (action.type) {
    case CLEAR_SIGNATURE:
      return reject(equals(signature), state);
    case LOAD_STREAM_DATA:
      return pipe(
        reject(equals(signature)),
        append(signature)
      )(state);
    default:
      return state;
  }
};
