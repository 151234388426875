import PropTypes from "prop-types";
import React from "react";

export default class extends React.Component {
  static propTypes = {
    correctiveActionsCount: PropTypes.number.isRequired,
    loadCorrectiveActionsCount: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { loadCorrectiveActionsCount } = this.props;

    loadCorrectiveActionsCount();
  }

  render() {
    const { correctiveActionsCount } = this.props;
    if (correctiveActionsCount) {
      return <span className="badge badge--red">{correctiveActionsCount}</span>;
    }
    return <span />;
  }
}
