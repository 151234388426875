import {
  TOGGLE_DATA_STREAM,
  UNSELECT_ALL_DATA_STREAMS
} from "../constants/actionTypes";

import { toggleValue } from "../../../modules/ArrayHelpers";

export default (state = [], action) => {
  const { id } = action;

  switch (action.type) {
    case TOGGLE_DATA_STREAM:
      return toggleValue(id, state);
    case UNSELECT_ALL_DATA_STREAMS:
      return [];
    default:
      return state;
  }
};
