import React from "react";
import PropTypes from "prop-types";
import { map, prop, find, range, any, merge, propEq } from "ramda";
import $ from "jquery";

import { timesBetweenDates } from "../../../modules/TimeHelpers";

import Accordion from "../../../components/Accordion";
import HeaderShare from "../../../components/HeaderShare";
import LoadingMessage from "../../../components/LoadingMessage";
import Summary from "../../../components/Summary";
import Tower from "../../../components/Tower";
import DataStream from "./DataStream";
import Ticker from "../../../../../elm/Ticker.elm";
import Elm from "react-elm-components";

import {
  getStartMoment,
  getEndMoment
} from "../../../skeletons/dataVisualization/modules/ComputedAttributes";

const siteHasAnyPanels = site => {
  const numbers = range(1, 4);

  return any(
    number => site[`panel_${number}_title`] && site[`panel_${number}_value`],
    numbers
  );
};

import { signatureAuthor } from "../modules/caching";

export default class Interface extends React.Component {
  static propTypes = {
    activeAlertID: PropTypes.number,
    activeDataStreamID: PropTypes.number,
    authToken: PropTypes.string.isRequired,
    dataStreams: PropTypes.array.isRequired,
    dataStreamsLoaded: PropTypes.bool.isRequired,
    dataVisualizationURL: PropTypes.string.isRequired,
    initialLoad: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    selectAlert: PropTypes.func.isRequired,
    selectStream: PropTypes.func.isRequired,
    site: PropTypes.shape({
      time_zone: PropTypes.string.isRequired
    }),
    startTime: PropTypes.string,
    streamAlerts: PropTypes.object.isRequired,
    streamData: PropTypes.object.isRequired
  };

  state = {
    autoScroll: false
  };

  componentDidMount() {
    const { initialLoad } = this.props;

    initialLoad().then(() => {
      this.scrollFollow();
    });
  }

  componentDidUpdate() {
    if (!this.state.autoScroll && this.props.dataStreamsLoaded) {
      this.setState({
        autoScroll: true
      });
    }
  }

  getAccordionItems = () => {
    const { props } = this;

    const {
      activeAlertID,
      activeDataStreamID,
      streamData,
      interval,
      selectAlert,
      site,
      streamAlerts,
      dataStreams
    } = props;

    const timeZone = site.time_zone;

    const startMoment = getStartMoment(props);
    const endMoment = getEndMoment(props);

    const intervals = timesBetweenDates(
      startMoment.unix(),
      endMoment.unix(),
      interval.asSeconds()
    );

    return dataStreams.map(dataStream => {
      var streamIDForData = dataStream.id;

      const base = {
        id: dataStream.id,
        news: 0,
        title: dataStream.name,
        unitType: dataStream.unit_type,
        autoScroll: this.state.autoScroll,
        intervals,
        selectAlert,
        activeAlertID,
        timeZone
      };

      if (activeDataStreamID !== dataStream.id) {
        return base;
      }

      const baseSignature = {
        start_time: startMoment.unix(),
        end_time: endMoment.unix()
      };

      const equipmentSignature = signatureAuthor(
        merge(baseSignature, { data_stream_id: dataStream.id })
      );

      const alerts = streamAlerts[equipmentSignature];

      if (activeAlertID && alerts) {
        const alert = find(propEq("id", activeAlertID), alerts);
        streamIDForData = alert.data_stream.id;
      }

      const dataSignature = signatureAuthor(
        merge(baseSignature, { data_stream_id: streamIDForData })
      );

      return merge(base, {
        alerts,
        data: streamData[dataSignature]
      });
    });
  };

  getTowerItems = () => {
    const { dataStreams, activeDataStreamID, timeZone } = this.props;

    return dataStreams.map(dataStream =>
      merge(dataStream, {
        title: dataStream.name,
        active: dataStream.id === activeDataStreamID,
        news: false,
        timeZone
      })
    );
  };

  scrollFollow = () => {
    const win = $(window);
    const elm = $(this.refs.status);
    const top = elm.offset().top;
    const body = $("html, body");
    win.scroll(() => {
      const scrollTop = win.scrollTop();
      if (scrollTop > top) {
        const rect = elm[0].getBoundingClientRect();
        const distanceToBottom =
          body.prop("scrollHeight") - scrollTop - 90 - rect.height;
        elm.css({
          position: distanceToBottom > 0 ? "fixed" : "absolute",
          right: distanceToBottom > 0 ? win.width() - rect.right : 0,
          top: distanceToBottom > 0 ? 0 : "",
          bottom: distanceToBottom > 0 ? "" : 15
        });
      } else {
        elm.css({ position: "" });
      }
    });
  };

  render() {
    const {
      site,
      activeDataStreamID,
      selectStream,
      dataStreamsLoaded,
      dataStreams,
      jwt,
      authToken,
      isAdmin,
      offsets
    } = this.props;

    if (!site) {
      return <div />;
    }

    return (
      <div className="narrow-layout">
        {false && <HeaderShare title="Measurement & Verification" />}

        <div className="mv__ticker">
          {dataStreamsLoaded && (
            <Elm
              src={Ticker.Elm.Ticker}
              flags={{
                apiConfig: {
                  jwt,
                  authToken
                },
                siteID: site.id,
                dataStreamIDs: map(prop("id"), dataStreams),
                isAdmin,
                offsets
              }}
            />
          )}
        </div>

        {siteHasAnyPanels(site) && (
          <div className="mv__summary">
            <Summary site={site} />
          </div>
        )}

        <div className="mv__streams">
          {!dataStreamsLoaded && <LoadingMessage loading="data" />}
          {dataStreamsLoaded && (
            <Accordion
              items={this.getAccordionItems()}
              activeItem={activeDataStreamID}
              component={DataStream}
              onSelectItem={selectStream}
            />
          )}
        </div>

        <div ref="status" className="mv__statuses">
          {dataStreamsLoaded && (
            <Tower items={this.getTowerItems()} selectStream={selectStream} />
          )}
        </div>
      </div>
    );
  }
}
