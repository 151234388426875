import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import Icon from "./Icon";

export default class extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    selectStream: PropTypes.func
  };

  handleClick = () => {
    this.props.selectStream(this.props.item);
  };

  render() {
    const { item } = this.props;
    const itemClasses = cx({
      tower__item: true,
      "tower__item--news": !!item.news,
      "tower__item--active": !!item.active,
      "tower__item--clickable": !!this.props.onClickItem
    });
    return (
      <div key={item.id} className={itemClasses} onClick={this.handleClick}>
        {item.title}
        {item.news && <Icon name="circle" />}
      </div>
    );
  }
}
