import PropTypes from "prop-types";
import React from "react";

import TowerItem from "./TowerItem";

export default class extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectStream: PropTypes.func
  };

  render() {
    return (
      <div className="tower">
        {this.props.items.map(item => (
          <TowerItem
            key={item.id}
            item={item}
            selectStream={this.props.selectStream}
          />
        ))}
      </div>
    );
  }
}
