import PropTypes from "prop-types";
import React from "react";

import SelectorInput from "./SelectorInput";

export default class extends React.Component {
  static propTypes = {
    interval: PropTypes.object.isRequired,
    setInterval: PropTypes.func.isRequired,
    zoomSetting: PropTypes.object.isRequired
  };

  render() {
    const { zoomSetting } = this.props;

    return (
      <div className="text-selector">
        <strong>Interval:</strong>
        {zoomSetting.intervals.map((interval, index) => (
          <SelectorInput
            key={index}
            namespace="interval"
            object={interval}
            onChange={this.props.setInterval}
            selectedObject={this.props.interval}
          />
        ))}
      </div>
    );
  }
}
