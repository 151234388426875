import { combineReducers } from "redux";
import staticReducer from "../../../reducers/staticReducer";
import { LOAD_METADATAS } from "../constants/actionTypes";

import equipment from "./equipment";
import equipmentData from "./equipmentData";
import equipmentLoaded from "./equipmentLoaded";
import equipmentMetrics from "./equipmentMetrics";
import equipmentSystems from "./equipmentSystems";
import narrowStartTime from "./wideStartTime";
import narrowEndTime from "./wideEndTime.ts";
import selectedTab from "./selectedTab";
import site from "./site";
import wideStartTime from "./wideStartTime";
import wideEndTime from "./wideEndTime.ts";

export default combineReducers({
  authToken: staticReducer,
  dataStreamsURL: staticReducer,
  equipmentSystemsURL: staticReducer,
  isAdmin: staticReducer,
  monthlyBills: staticReducer,
  siteID: staticReducer,
  siteURL: staticReducer,
  jwt: staticReducer,
  offsets: staticReducer,

  equipment,
  equipmentData,
  equipmentLoaded,
  equipmentMetrics,
  equipmentSystems,
  narrowStartTime,
  narrowEndTime,
  selectedTab,
  site,
  wideEndTime,
  wideStartTime,
  metadatas: (state = [], action) => {
    switch (action.type) {
      case LOAD_METADATAS:
        return action.metadatas;
      default:
        return state;
    }
  }
});
