import { LOAD_SITE_SUCCESS } from "../constants/actionTypes";

import { calculateStartTimeFromSite } from "../modules/Time";

export default (state = null, action) => {
  switch (action.type) {
    case LOAD_SITE_SUCCESS:
      const { site } = action;

      return calculateStartTimeFromSite(60, site);
    default:
      return state;
  }
};
