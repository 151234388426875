import { pipe, join, filter, identity } from "ramda";
export const numberWithCommas = x =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatDataStreamMetadata = metadata =>
  pipe(
    filter(identity),
    join(" ")
  )([value(metadata), metadata.unit_type]);

const value = ({ value_type, value }) => {
  switch (value_type) {
    case "decimal":
    case "integer":
      return numberWithCommas(value);
    default:
      return value;
  }
};
