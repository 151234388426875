import { always } from "ramda";
import ensureStreamLoaded from "./subactions/ensureStreamLoaded";
import signatureAuthor from "../lib/signatureAuthor";

import { LOAD_STREAM_DATA, TOGGLE_DATA_STREAM } from "../constants/actionTypes";

export default stream => (dispatch, getState) => {
  const state = getState();

  // Use pure function to return the same state we're working with just to make
  // sure the state isn't altered between when we generate the signature and
  // when we load the stream.
  ensureStreamLoaded(dispatch, always(state), stream).then(payload => {
    if (payload) {
      const signature = signatureAuthor(state);

      dispatch({
        type: LOAD_STREAM_DATA,
        signature,
        payloads: [payload],
        source: payload.source
      });
    }
  });

  dispatch({
    type: TOGGLE_DATA_STREAM,
    id: stream.id
  });
};
