import { map, evolve, replace } from "ramda";
import { LOAD_PLOT_RUNS_SUCCESS } from "../constants/actionTypes";

export default (state = [], action) => {
  switch (action.type) {
    case LOAD_PLOT_RUNS_SUCCESS:
      return map(
        evolve({ output_png_file_url: replace("http://", "https://") }),
        action.plotRuns
      );
    default:
      return state;
  }
};
