// Shims for older browsers;

require("es5-shim");
require("array.prototype.find");
window.Promise = window.Promise || require("bluebird");
import { merge, reduce } from "ramda";
import ElmInit from "../modules/ElmInit";

// jQuery
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

// Monkeypatch superagent to include setAuthToken method.
require("../lib/superagent");

// Configure moment to use Rails time zone names.
require("../lib/moment");

import CorrectiveActionsCount from "../apps/CorrectiveActionsCount";
import DataVisualizer from "../apps/DataVisualizer";
import EquipmentAndSystemsIndex from "../apps/EquipmentAndSystemsIndex";
import EquipmentAndSystemsShow from "../apps/EquipmentAndSystems";
import HandleIEMessage from "../modules/IEWarningMessage";
import MeasurementAndVerification from "../apps/MeasurementAndVerification";
import SiteNav from "../apps/SiteNav";
import SitesList from "../apps/SitesList";
require("../lib/data");

var wrapJS = app => flags =>
  window.requestJWT().then(jwt => {
    app(merge({ jwt }, flags));
  });

// Unifiy elm modules.
window.Initializers = {
  AlertsElm: ElmInit(window.Elm.Alerts),
  CorrectiveActionsCount,
  Dashboard: ElmInit(window.Elm.Dashboard),
  DataVisualizer,
  EquipmentAndSystemsIndex: wrapJS(EquipmentAndSystemsIndex),
  EquipmentAndSystemsShow: wrapJS(EquipmentAndSystemsShow),
  MeasurementAndVerification: wrapJS(MeasurementAndVerification),
  SiteNav,
  SitesList
};

window.combineFlags = function() {
  const args = Array.prototype.slice.call(arguments);
  args.push({ authToken: window.authToken });
  const initial = reduce(merge, {}, args);
  const { jwt, authToken } = initial;
  if (!jwt) console.warn("JWT not provided");
  if (!authToken) console.warn("Auth token not provided");
  return merge(initial, {
    apiConfig: {
      authToken,
      jwt
    }
  });
};

HandleIEMessage();
