import PropTypes from "prop-types";
import { T, any, propEq } from "ramda";
import React from "react";

import { StyleSheet, css } from "aphrodite";

const TABS = [
  {
    name: "Cost",
    visibilityTest: T
  },
  {
    name: "Demand",
    visibilityTest: any(propEq("bill_type", "energy"))
  },
  {
    name: "Consumption",
    visibilityTest: T
  }
];

const styles = StyleSheet.create({
  tabs: {
    margin: "auto",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: "20px"
  },

  tab: {
    padding: "5px",
    backgroundColor: "white",
    border: "1px solid #E2E2E2",
    display: "inline-block",
    fontSize: "12px",
    marginRight: "10px",
    color: "gray",
    ":hover": {
      cursor: "pointer"
    }
  },

  selectedTab: {
    border: "2px solid black",
    color: "black"
  }
});

export default class extends React.Component {
  static propTypes = {
    monthlyBills: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectBillTab: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired
  };

  render() {
    const { monthlyBills, selectBillTab, selectedTab } = this.props;

    return (
      <ul className={css(styles.tabs)}>
        {TABS.map(
          tab =>
            tab.visibilityTest(monthlyBills) && (
              <Category
                key={tab.name}
                tab={tab}
                selectedTab={selectedTab}
                selectBillTab={selectBillTab}
              />
            )
        )}
      </ul>
    );
  }
}

class Category extends React.Component {
  static propTypes = {
    selectBillTab: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired,
    tab: PropTypes.object.isRequired
  };

  handleSelect = () => {
    const { selectBillTab, tab } = this.props;

    selectBillTab(tab.name);
  };

  render() {
    const { tab, selectedTab } = this.props;

    return (
      <li
        key={tab.name}
        className={css(
          styles.tab,
          tab.name === selectedTab && styles.selectedTab
        )}
        onClick={this.handleSelect}
      >
        {tab.name}
      </li>
    );
  }
}
