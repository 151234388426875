import { SET_ZOOM_SETTING } from "../constants/actionTypes";

import ensureStreamsLoaded from "./subactions/ensureStreamsLoaded";

export default zoomSetting => (dispatch, getState) => {
  dispatch({
    type: SET_ZOOM_SETTING,
    zoomSetting
  });

  ensureStreamsLoaded(dispatch, getState);
};
