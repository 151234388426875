import setInterval from "../../../skeletons/dataVisualization/actions/setInterval";
import setStartTime from "../../../skeletons/dataVisualization/actions/setStartTime";
import setZoom from "../../../skeletons/dataVisualization/actions/setZoom";
import toggleDataStream from "../../../skeletons/dataVisualization/actions/toggleDataStream";

import initialLoad from "./initialLoad";
import sortDataStreams from "./sortDataStreams";
import selectPlotRun from "./selectPlotRun";
import navigateToDataStream from "./navigateToDataStream";
import toggleEquipmentInfo from "./toggleEquipmentInfo";

export default {
  initialLoad,
  setInterval,
  setZoom,
  navigateToDataStream,
  sortDataStreams,
  selectPlotRun,
  setStartTime,
  toggleEquipmentInfo,
  toggleDataStream
};
