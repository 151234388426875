import PropTypes from "prop-types";
import React from "react";

import Icon from "./Icon";

export default class extends React.Component {
  static propTypes = {
    onShare: PropTypes.func,
    title: PropTypes.node.isRequired
  };

  render() {
    return (
      <div className="header-share">
        {false && (
          <a className="header-share__share" onClick={this.props.onShare}>
            <Icon name="share" />
            Share
          </a>
        )}
        <h3 className="header-share__title">{this.props.title}</h3>
      </div>
    );
  }
}
