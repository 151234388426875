import PropTypes from "prop-types";
import React from "react";
import Icon from "../../../components/Icon";

export default class extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sortDataStreams: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.props.sortDataStreams(this.props.name);
  };

  render() {
    const className = this.props.sorted ? "sorted" : "";
    return (
      <th onClick={this.handleClick} className={className}>
        {this.props.label}
        <Icon name="caret-down" />
      </th>
    );
  }
}
