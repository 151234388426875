import equipmentGroup from "./equipmentGroup";
import equipmentGroupHeader from "./equipmentGroupHeader";
import equipmentGroupTable from "./equipmentGroupTable";
import equipmentTable from "./equipmentTable";
import equipmentTableTh from "./equipmentTableTh";
import equipmentTableRow from "./equipmentTableRow";
import equipmentTableCell from "./equipmentTableCell";
import equipmentTableContainer from "./equipmentTableContainer";
import groupName from "./groupName";

export default {
  equipmentGroup,
  equipmentGroupHeader,
  equipmentGroupTable,
  equipmentTable,
  equipmentTableTh,
  equipmentTableRow,
  equipmentTableContainer,
  equipmentTableCell,
  groupName
};
