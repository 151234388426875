import {
  append,
  pipe,
  map,
  prop,
  filter,
  path,
  identity,
  flatten
} from "ramda";
import request from "superagent";

import { LOAD_PLOT_RUNS_SUCCESS } from "../../constants/actionTypes";
import { getMainDataStream } from "../../../../skeletons/dataVisualization/lib/ComputedAttributes";

export default (dispatch, getState) => {
  const state = getState();

  const { authToken, urls } = state;

  const equipmentStream = getMainDataStream(state);

  const relatedStreamIDs = pipe(
    map(prop("id")),
    append(equipmentStream.id)
  )(equipmentStream.related_data_streams);

  request
    .get(urls.plotRuns)
    .query({ data_stream_ids: relatedStreamIDs.join(",") })
    .setAuthToken(authToken)
    .then(response => {
      const plotRuns = pipe(
        path(["body", "equipment_plot_runs"]),
        filter(identity),
        flatten
      )(response);

      dispatch({
        type: LOAD_PLOT_RUNS_SUCCESS,
        plotRuns
      });
    });
};
