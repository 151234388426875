import PropTypes from "prop-types";
import React from "react";

import { zoomSettings } from "../constants/zoomSettings";

import SelectorInput from "./SelectorInput";

export default class ZoomSelector extends React.PureComponent {
  static propTypes = {
    setZoom: PropTypes.func.isRequired,
    zoomSetting: PropTypes.object.isRequired
  };

  render() {
    return (
      <div className="text-selector">
        <strong>Zoom:</strong>
        {zoomSettings.map((zoomSetting, index) => (
          <SelectorInput
            key={index}
            namespace="zoom"
            object={zoomSetting}
            onChange={this.props.setZoom}
            selectedObject={this.props.zoomSetting}
          />
        ))}
      </div>
    );
  }
}
