import * as PropTypes from "prop-types";
import * as React from "react";

import HeaderShare from "../../../components/HeaderShare";
import Summary from "../../../components/Summary";

import EquipmentList from "./EquipmentList.tsx";
import MonthlyBills from "./MonthlyBills";

import Ticker from "../../../../../elm/Ticker.elm";
import Elm from "react-elm-components";

const col1 = {
  display: "inline-block",
  width: "70%",
  verticalAlign: "top"
};

const col2 = {
  display: "inline-block",
  width: "30%",
  verticalAlign: "top",
  paddingLeft: "20px"
};

export default class Interface extends React.Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    equipment: PropTypes.object.isRequired,
    equipmentData: PropTypes.object.isRequired,
    equipmentLoaded: PropTypes.bool.isRequired,
    equipmentMetrics: PropTypes.object.isRequired,
    initialLoad: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    jwt: PropTypes.string.isRequired,
    loadEquipmentData: PropTypes.func.isRequired,
    metadatas: PropTypes.array.isRequired,
    selectBillTab: PropTypes.func.isRequired,
    selectEquipment: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired,
    site: PropTypes.object,
    wideEndTime: PropTypes.object,
    wideStartTime: PropTypes.object
  };

  componentDidMount() {
    this.props.initialLoad();
  }

  render() {
    const {
      isAdmin,
      loadEquipmentData,
      metadatas,
      monthlyBills,
      selectBillTab,
      selectedTab,
      site,
      wideStartTime,
      jwt,
      authToken,
      wideEndTime,
      offsets
    } = this.props;

    if (!site) {
      return <div />;
    }

    const flags = {
      apiConfig: {
        jwt,
        authToken
      },
      siteID: site.id,
      dataStreamIDs: null,
      isAdmin,
      offsets
    };

    return (
      <div className="narrow-layout">
        <div style={col1}>
          {false && <HeaderShare title="Equipment" />}
          <Summary site={site} />
          {monthlyBills.length > 0 && (
            <MonthlyBills
              monthlyBills={monthlyBills}
              selectBillTab={selectBillTab}
              selectedTab={selectedTab}
            />
          )}

          <EquipmentList
            equipment={this.props.equipment}
            equipmentData={this.props.equipmentData}
            equipmentMetrics={this.props.equipmentMetrics}
            loadEquipmentData={loadEquipmentData}
            metadatas={metadatas}
            selectEquipment={this.props.selectEquipment}
            wideStartTime={wideStartTime}
            wideEndTime={wideEndTime}
          />
        </div>
        <div style={col2}>
          <Elm src={Ticker.Elm.Ticker} flags={flags} />
        </div>
      </div>
    );
  }
}
