import request from "superagent";

import { CORRECTIVE_ACTIONS_COUNT_LOAD_SUCCESS } from "../constants/actionTypes";

export default () => (dispatch, getState) => {
  const { correctiveActionsCountURL, authToken } = getState();

  return request(correctiveActionsCountURL)
    .setAuthToken(authToken)
    .then(resp => resp.body.count)
    .then(count => {
      dispatch({
        type: CORRECTIVE_ACTIONS_COUNT_LOAD_SUCCESS,
        count
      });
    });
};
