import { combineReducers } from "redux";
import staticReducer from "../../../reducers/staticReducer";

import correctiveActionsCount from "./correctiveActionsCount";

export default combineReducers({
  correctiveActionsCountURL: staticReducer,
  authToken: staticReducer,
  correctiveActionsCount
});
