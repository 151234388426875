import React from "react";

import PropTypes from "prop-types";
import { drop, contains, propEq, find } from "ramda";

import Stream from "./Stream";

export default class GraphSelector extends React.PureComponent {
  static propTypes = {
    activatedDataStreamIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    data: PropTypes.object,
    dataStreams: PropTypes.arrayOf(PropTypes.object).isRequired,
    equipment: PropTypes.object.isRequired,
    graphs: PropTypes.array.isRequired,
    toggleDataStream: PropTypes.func.isRequired
  };

  render() {
    var { graphs } = this.props;

    const {
      activatedDataStreamIDs,
      equipment,
      dataStreams,
      toggleDataStream
    } = this.props;

    if (!graphs) {
      return <span />;
    }

    if (equipment.breakers.length === 0) {
      graphs = drop(1, graphs);
    }

    return (
      <ul className="equip-sys__visualization__graph-selector">
        {dataStreams.map((dataStream, index) => {
          const graph = find(propEq("id", dataStream.id), graphs);

          return (
            <Stream
              key={index}
              stream={dataStream}
              graph={graph}
              toggleDataStream={toggleDataStream}
              isSelected={contains(dataStream.id, activatedDataStreamIDs)}
            />
          );
        })}
        {dataStreams.map(dataStream => {
          const graph = find(propEq("id", dataStream.id), graphs);
          return (
            <Stream
              key={dataStream.id}
              stream={null}
              graph={graph}
              toggleDataStream={toggleDataStream}
              isSelected={false}
            />
          );
        })}
      </ul>
    );
  }
}
