import React from "react";
import RelatedEquipmentsRow from "./RelatedDataStreamsRow";

import PropTypes from "prop-types";

export default class RelatedDataStreams extends React.PureComponent {
  static propTypes = {
    navigateToDataStream: PropTypes.func.isRequired,
    relatedEquipmentDataStreams: PropTypes.arrayOf(PropTypes.object).isRequired,
    site: PropTypes.object.isRequired
  };

  renderItem = (stream, index) => {
    const { navigateToDataStream, site } = this.props;

    return (
      <RelatedEquipmentsRow
        key={index}
        stream={stream}
        site={site}
        navigateToDataStream={navigateToDataStream}
      />
    );
  };

  render() {
    const { relatedEquipmentDataStreams } = this.props;

    const anyStreams = relatedEquipmentDataStreams.length > 0;

    return (
      <div className="equip-sys__related-equipments">
        <header>
          <h3>Related Equipment</h3>
        </header>
        {anyStreams && (
          <ul>{relatedEquipmentDataStreams.map(this.renderItem)}</ul>
        )}
        {!anyStreams && <p>No related equipment.</p>}
      </div>
    );
  }
}
