import * as moment from "moment-timezone";
import { LOAD_SITE_SUCCESS } from "../constants/actionTypes";

import { calculateStartTimeFromSite } from "../modules/Time";
import * as T from "../types";

const WIDE_LENGTH_IN_DAYS = 60;

export default (
  state: T.Maybe<moment.Moment> = null,
  action
): T.Maybe<moment.Moment> => {
  switch (action.type) {
    case LOAD_SITE_SUCCESS:
      const { site } = action;
      const { end_data_limit } = site;

      const startTime = calculateStartTimeFromSite(WIDE_LENGTH_IN_DAYS, site);

      const later = startTime.clone().add(WIDE_LENGTH_IN_DAYS, "days");

      return end_data_limit && later.isAfter(end_data_limit)
        ? moment(end_data_limit)
        : later;
    default:
      return state;
  }
};
