import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { propEq, find } from "ramda";
import $ from "jquery";

import calculateScrollOffset from "../../../lib/scrollOffset";

import Spinner from "../../../components/Spinner";

import Alerts from "./Alerts";
import Chart from "./Chart";

export default class extends React.Component {
  static propTypes = {
    activeAlertID: PropTypes.number,
    alerts: PropTypes.array,
    autoScroll: PropTypes.bool.isRequired,
    data: PropTypes.object,
    id: PropTypes.number.isRequired,
    intervals: PropTypes.array,
    news: PropTypes.number.isRequired,
    selectAlert: PropTypes.func.isRequired,
    timeZone: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    unitType: PropTypes.string.isRequired
  };

  componentDidMount() {
    if (this.props.autoScroll) {
      this.scrollTo();
    }
  }

  scrollTo = () => {
    const node = ReactDOM.findDOMNode(this);
    const scrollTop = calculateScrollOffset(node, 20, "middle");
    $("html, body").animate({ scrollTop }, 500);
  };

  renderAlerts = () => {
    const alerts = this.props.alerts;

    if (!alerts) {
      return <Spinner />;
    } else if (alerts.length) {
      return this.renderAlertsTable(alerts);
    }

    return <p>No alerts were triggered in this timeframe.</p>;
  };

  getChartAlert = () => {
    const { activeAlertID, alerts } = this.props;

    if (activeAlertID && alerts) {
      return find(propEq("id", activeAlertID), alerts);
    }
  };

  renderChart = () => {
    var stream;

    const { data, id, intervals, timeZone, unitType } = this.props;

    const alert = this.getChartAlert();

    stream = alert
      ? { id: alert.data_stream.id, unitType: alert.data_stream.unit_type }
      : { id, unitType };

    if (data) {
      return (
        <Chart
          id={stream.id}
          data={data}
          unitType={unitType}
          intervals={intervals}
          alert={alert}
          timeZone={timeZone}
        />
      );
    }

    return <Spinner />;
  };

  render() {
    const { alerts, activeAlertID, selectAlert, timeZone } = this.props;

    return (
      <div className="mv__streams__stream">
        {alerts && alerts.length > 0 && (
          <div className="mv__streams__stream__stories">
            <Alerts
              alerts={alerts}
              activeAlertID={activeAlertID}
              selectAlert={selectAlert}
              timeZone={timeZone}
            />
          </div>
        )}
        <div className="mv__streams__stream__chart">{this.renderChart()}</div>
      </div>
    );
  }
}
