import {
  map,
  join,
  pipe,
  reject,
  propEq,
  prepend,
  prop,
  find,
  path
} from "ramda";
import Promise from "bluebird";
import request from "superagent";

import ensureStreamsLoaded from "../../../skeletons/dataVisualization/actions/subactions/ensureStreamsLoaded";

import loadPlotRuns from "./subactions/loadPlotRuns";

import {
  LOAD_SITE_SUCCESS,
  LOAD_DATA_STREAMS_SUCCESS,
  TOGGLE_DATA_STREAM
} from "../constants/actionTypes";

import handleUnexpectedError from "../../../lib/handleUnexpectedError";

import { sortByName } from "../../../modules/Sorters";

export default () => (dispatch, getState) => {
  const { authToken, jwt, urls, equipmentID } = getState();

  const dataStream = request
    .get(urls.dataStream)
    .query({
      select: "*,breakers(*)"
    })
    .setJWT(jwt)
    .then(resp => resp.body[0]);

  const dataStreamRelationships = request
    .get("/rest/data_stream_relationships_view")
    .query({
      owner_data_stream_id: `eq.${equipmentID}`
    })
    .setJWT(jwt)
    .then(prop("body"));

  const dataStreamMetadatas = request
    .get("/api/data_stream_metadatas")
    .setAuthToken(authToken)
    .then(path(["body", "data_stream_metadatas"]));

  const dataStreamRequests = Promise.props({
    dataStream,
    dataStreamRelationships,
    dataStreamMetadatas
  })
    .then(({ dataStream, dataStreamRelationships, dataStreamMetadatas }) => {
      var mainDataStream = dataStream;
      mainDataStream.isMainDataStream = true;

      const idsCommaized = pipe(
        map(prop("related_data_stream_id")),
        join(",")
      )(dataStreamRelationships);

      return request
        .get("/rest/data_streams_view")
        .query({
          select: "*,breakers(*)",
          id: `in.(${idsCommaized})`
          // We only want the non-equipment related stream showing up with
          // graphs.
        })
        .setJWT(jwt)
        .then(resp => {
          const related = resp.body;

          dataStream.related_data_streams = related;
          const metadata = find(
            propEq("name", dataStream.brick_class_name),
            dataStreamMetadatas
          );
          dataStream.metadata_label = metadata ? metadata.label : null;
          dataStream.metadata_fields = metadata ? metadata.attributes : [];

          dispatch({
            type: LOAD_DATA_STREAMS_SUCCESS,
            keepBreakerlessEquipment: true,
            dataStreams: pipe(
              reject(propEq("stream_type", "equipment")),
              // Sort them by name.
              sortByName,
              // But the main equipment should still come first.
              prepend(dataStream)
            )(related)
          });

          // Load the equipment data if it's metered.
          if (mainDataStream.breakers.length > 0) {
            dispatch({
              type: TOGGLE_DATA_STREAM,
              id: mainDataStream.id
            });
          }
          return mainDataStream;
        });
    })
    .then(() => {
      Promise.all([
        request
          .get(urls.site)
          .setAuthToken(authToken)
          .then(
            resp =>
              dispatch({
                type: LOAD_SITE_SUCCESS,
                site: resp.body.site
              }),
            handleUnexpectedError
          ),

        dataStreamRequests
      ]).then(() => {
        ensureStreamsLoaded(dispatch, getState);
        loadPlotRuns(dispatch, getState);
      });
    });
};
