import { omit } from "ramda";
import React from "react";
import { render } from "react-dom";

import { connect, Provider } from "react-redux";
import { createAppStore } from "../../modules/ReduxHelpers";

const mapStateToProps = omit(["siteURL"]);

import mapDispatchToProps from "./actions";
import Interface from "./components/Interface";
import rootReducer from "./reducers";

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(Interface);

export default props => {
  const store = createAppStore(rootReducer, props);

  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("app")
  );
};
