import {
  SELECT_DATA_STREAM,
  LOAD_DATA_STREAMS_SUCCESS
} from "../constants/actionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case SELECT_DATA_STREAM:
      return state === action.id ? null : action.id;
    case LOAD_DATA_STREAMS_SUCCESS:
      return action.data_streams && action.data_streams.length
        ? action.data_streams[0].id
        : null;
    default:
      return state;
  }
};
