import moment from "moment-timezone";
const DATE_FORMAT = "%m-%d %I:%M %p";

const CHART_SETTINGS = {
  axis: {
    x: {
      type: "timeseries",
      tick: {
        format: "%m/%d",
        count: 5
      }
    },

    y: {
      padding: 0,
      show: true,
      label: {
        text: "KW",
        position: "outer-center"
      },
      tick: {
        format(d) {
          return d.toFixed(2);
        }
      }
    }
  },

  // x identifies the row that will be used to represent the x-values.
  data: { x: "x", xFormat: DATE_FORMAT },

  legend: { hide: true },

  // Necessary so that the x-axis dates don't get cut off.
  padding: { left: 80, right: 30, bottom: 0 },

  // Don't show circles for each data point.
  point: { show: false },

  size: { height: 250, width: 850 },
  tooltip: {
    format: {
      title(x) {
        return moment(x).format("MMM D, YYYY h:mm a");
      }
    }
  }
};

export default CHART_SETTINGS;
