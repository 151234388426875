import PropTypes from "prop-types";
import { pipe, values, any, identity } from "ramda";
import React from "react";
import Panel from "./Panel";

const extractPanelProps = (site, number) => {
  const title = site[`panel_${number}_title`],
    value = site[`panel_${number}_value`],
    note = site[`panel_${number}_note`],
    color = site[`panel_${number}_color`];

  if (!title || !value) {
    return null;
  }

  return {
    title,
    value,
    note,
    color
  };
};

const hasProps = pipe(
  values,
  any(identity)
);

export default class extends React.Component {
  static propTypes = {
    site: PropTypes.shape({
      panel_1_title: PropTypes.string,
      panel_1_value: PropTypes.string,
      panel_1_note: PropTypes.string,
      panel_1_color: PropTypes.string,
      panel_2_title: PropTypes.string,
      panel_2_value: PropTypes.string,
      panel_2_note: PropTypes.string,
      panel_2_color: PropTypes.string,
      panel_3_title: PropTypes.string,
      panel_3_value: PropTypes.string,
      panel_3_note: PropTypes.string,
      panel_3_color: PropTypes.string
    })
  };

  render() {
    const { site } = this.props;

    const panel1Props = extractPanelProps(site, 1);
    const panel2Props = extractPanelProps(site, 2);
    const panel3Props = extractPanelProps(site, 3);

    const anyPanels = any(hasProps, [panel1Props, panel2Props, panel3Props]);

    if (!anyPanels) {
      return <span />;
    }

    return (
      <div className="summary">
        {panel1Props && <Panel {...panel1Props} />}
        {panel2Props && <Panel {...panel2Props} />}
        {panel3Props && <Panel {...panel3Props} />}
      </div>
    );
  }
}
