import reduxUnhandledAction from "redux-unhandled-action";
import thunkMiddleware from "redux-thunk";
import { applyMiddleware, compose, createStore } from "redux";

const stateDidNotChangeCallback = action => {
  console.warn(action, "didn't lead to creation of a new state object");
};

export const createAppStore = compose(
  applyMiddleware(thunkMiddleware),
  global.devToolsExtension ? global.devToolsExtension() : f => f,
  applyMiddleware(reduxUnhandledAction(stateDidNotChangeCallback))
)(createStore);

export const minimalCreateAppStore = compose(applyMiddleware(thunkMiddleware))(
  createStore
);
