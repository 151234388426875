import React from "react";
import PropTypes from "prop-types";
import { apply } from "ramda";
import cx from "classnames";

export default class ThumbChart extends React.PureComponent {
  static propTypes = {
    height: PropTypes.number.isRequired,
    strokeColor: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
    width: PropTypes.number.isRequired
  };

  static defaultProps = {
    width: 50,
    height: 22
  };

  draw = () => {
    const { width, height, values } = this.props;
    const max = apply(Math.max, values);
    const ratioTop = max > 0 ? height / max : height;
    const ratioLeft = width / values.length;
    const path = values.map((value, index) => {
      const left = Math.round(index * ratioLeft);
      const top = height - Math.round(value * ratioTop);
      const command = index === 0 ? "M" : "L";
      return `${command}${left} ${top}`;
    });
    return path.join(" ");
  };

  render() {
    const { className, strokeColor } = this.props;

    const style = {
      stroke: strokeColor || "#777",
      fill: "none"
    };
    const classNames = cx({
      ["thumb-chart"]: true,
      [className]: !!className
    });
    return (
      <svg
        className={classNames}
        width={this.props.width}
        height={this.props.height}
      >
        <path d={this.draw()} style={style} />
      </svg>
    );
  }
}
