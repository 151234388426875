export default class NameRegister {
  registeredNames = {};

  add(requestedName) {
    var nameToUse = requestedName,
      n = 1;

    // Go through names until we get one that's not taken.
    while (this.registeredNames[nameToUse]) {
      nameToUse = `${requestedName} ${n}`;
      n = n + 1;
    }

    this.registeredNames[nameToUse] = true;

    return nameToUse;
  }
}
