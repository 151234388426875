import { notify } from "./Bugsnag";

export default module => (node, json) =>
  window.requestJWT().then(jwt => {
    const flags = window.combineFlags({ jwt, url: location.href }, json);
    const elm = module.init({ node, flags });
    const { ports } = elm;
    if (ports) {
      if (ports.bugsnag) {
        ports.bugsnag.subscribe(errs => {
          notify(new Error("Elm error"), {
            metaData: {
              errors: errs
            }
          });
        });
      }
    }
    return elm;
  });
