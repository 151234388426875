import { combineReducers } from "redux";
import staticReducer from "../../../reducers/staticReducer";

import activatedDataStreamIDs from "../../../skeletons/dataVisualization/reducers/activatedDataStreamIDs";
import data from "../../../skeletons/dataVisualization/reducers/data";
import endTimeBound from "../../../skeletons/dataVisualization/reducers/endTimeBound";
import interval from "../../../skeletons/dataVisualization/reducers/interval";
import signatureHistory from "../../../skeletons/dataVisualization/reducers/signatureHistory";
import site from "../../../skeletons/dataVisualization/reducers/site";
import startTime from "../../../skeletons/dataVisualization/reducers/startTime";
import startTimeBound from "../../../skeletons/dataVisualization/reducers/startTimeBound";
import dataStreams from "../../../skeletons/dataVisualization/reducers/dataStreams";
import zoomSetting from "../../../skeletons/dataVisualization/reducers/zoomSetting";
import selectedPlotRun from "./selectedPlotRun";
import sortDataStreamsBy from "./sortDataStreamsBy";
import moreEquipmentInfo from "./moreEquipmentInfo";
import plotRuns from "./plotRuns";
import plotRunsLoaded from "./plotRunsLoaded";

export default combineReducers({
  authToken: staticReducer,
  jwt: staticReducer,
  currentUserID: staticReducer,
  equipmentID: staticReducer,
  isAdmin: staticReducer,
  startDataLimit: staticReducer,
  endDataLimit: staticReducer,
  urls: staticReducer,
  offsets: staticReducer,

  connectNull: () => true,
  loadDataStreamsRelatedToEquipment: () => true,

  activatedDataStreamIDs,
  endTimeBound,

  // Stream/equipment and data.
  data,
  sortDataStreamsBy,
  dataStreams,
  signatureHistory,
  preloadAll: () => true,

  // Time related reducers.
  startTimeBound,
  startTime,
  interval,
  zoomSetting,

  // Plot runs.
  plotRuns,
  plotRunsLoaded,
  selectedPlotRun,

  moreEquipmentInfo,
  site
});
