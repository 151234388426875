export const CLEAR_SIGNATURE = "CLEAR_SIGNATURE";
export const LOAD_SITE_SUCCESS = "LOAD_SITE_SUCCESS";
export const LOAD_DATA_STREAMS_SUCCESS = "LOAD_DATA_STREAMS_SUCCESS";
export const LOAD_STREAM_DATA = "LOAD_STREAM_DATA";
export const SET_INTERVAL = "SET_INTERVAL";
export const SET_SCALE_TO_FIT = "SET_SCALE_TO_FIT";
export const SET_START_TIME = "SET_START_TIME";
export const SET_ZOOM_SETTING = "SET_ZOOM_SETTING";
export const SET_CONNECT_NULL = "SET_CONNECT_NULL";
export const TOGGLE_DATA_STREAM = "TOGGLE_DATA_STREAM";
export const UNSELECT_ALL_DATA_STREAMS = "UNSELECT_ALL_DATA_STREAMS";
