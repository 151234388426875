import PropTypes from "prop-types";
import React from "react";

import BillCategorySelector from "./BillCategorySelector";
import MonthlyBillGraph from "./MonthlyBillGraph";

const styles = {
  headerDiv: {
    backgroundColor: "white",
    padding: "15px",
    borderBottom: "1px solid rgb(226, 226, 226)"
  },
  header: {
    fontSize: "16px",
    marginBottom: "0px"
  },
  inner: {
    padding: "15px"
  },
  wrapper: {
    backgroundColor: "rgb(251, 251, 251)",
    border: "1px solid rgb(226, 226, 226)",
    marginBottom: "20px"
  }
};

export default class extends React.Component {
  static propTypes = {
    monthlyBills: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectBillTab: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired
  };

  render() {
    const { monthlyBills, selectedTab, selectBillTab } = this.props;

    return (
      <div style={styles.wrapper}>
        <div style={styles.headerDiv}>
          <h2 style={styles.header}>Utility Data</h2>
        </div>
        <div style={styles.inner}>
          <BillCategorySelector
            monthlyBills={monthlyBills}
            selectBillTab={selectBillTab}
            selectedTab={selectedTab}
          />
          <MonthlyBillGraph
            monthlyBills={monthlyBills}
            selectedTab={selectedTab}
          />
        </div>
      </div>
    );
  }
}
