import React from "react";
import { render } from "react-dom";

import { connect, Provider } from "react-redux";
import { createAppStore } from "../../modules/ReduxHelpers";

const mapStateToProps = f => f;
import mapDispatchToProps from "./actions";
import Interface from "./components/Interface";
import rootReducer from "./reducers";

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(Interface);

export default props => {
  render(
    <Provider store={createAppStore(rootReducer, props)}>
      <App />
    </Provider>,
    document.getElementById("equipment-and-systems-index-interface")
  );
};
