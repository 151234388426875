import {
  pipe,
  filter,
  reject,
  prop,
  contains,
  identity,
  __,
  uniq,
  sort,
  subtract,
  groupBy,
  mapObjIndexed,
  takeLast,
  equals
} from "ramda";
import c3 from "c3";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";

import HUMANIZED_BILL_TYPES from "../../constants/humanizedBillTypes";

const isNumNotNull = n => n && !isNaN(+n);

export default class extends React.Component {
  static propTypes = {
    billToValue: PropTypes.func.isRequired,
    exclude: PropTypes.arrayOf(PropTypes.string),
    formatTooltipValue: PropTypes.func,
    monthlyBills: PropTypes.arrayOf(PropTypes.object).isRequired,
    tooltipContents: PropTypes.func,
    yTickFormat: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      billToValue,
      exclude,
      formatTooltipValue,
      monthlyBills,
      tooltipContents,
      yTickFormat
    } = this.props;

    var dateKeys = [],
      billTypes = [],
      groups = [];

    const billsGroupedByMonth = pipe(
      // Filter null values
      filter(
        pipe(
          billToValue,
          isNumNotNull
        )
      ),
      // Exclude
      exclude
        ? reject(
            pipe(
              prop("bill_type"),
              contains(__, exclude)
            )
          )
        : identity,
      // Group the bills by their bill type.
      groupBy(
        pipe(
          prop("bill_type"),
          prop(__, HUMANIZED_BILL_TYPES)
        )
      ),
      // For each bill type, group the bills by the start month of the service
      // period start.
      mapObjIndexed(bills =>
        groupBy(bill => {
          const dateKey = moment(bill.service_period_start)
            .startOf("month")
            .unix();

          billTypes.push(HUMANIZED_BILL_TYPES[bill.bill_type]);
          dateKeys.push(dateKey);
          return dateKey;
        })(bills)
      )
    )(monthlyBills);

    const uniqDateKeys = pipe(
      uniq,
      sort(subtract),
      takeLast(12)
    )(dateKeys);

    const uniqBillTypes = pipe(uniq)(billTypes);

    var columns = uniqBillTypes.map(billType =>
      [billType].concat(
        (() => {
          const bills = billsGroupedByMonth[billType];

          return uniqDateKeys.map(date => {
            const billSubset = bills[date];
            return billSubset && billSubset[0]
              ? billToValue(billSubset[0])
              : null;
          });
        })()
      )
    );

    const dateValues = uniqDateKeys.map(key =>
      moment.unix(key).format("YYYY-MM-DD")
    );
    const dateRow = ["date"].concat(dateValues);
    columns = [dateRow].concat(columns);

    groups = [reject(equals("Simulation"), uniqBillTypes)];

    if ((contains("Simulation"), uniqBillTypes)) {
      groups.push(["Simulation"]);
    }

    this.chart = c3.generate({
      bindto: "#chart",
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format(x) {
              return moment(x).format("MMM 'YY");
            },
            values: dateValues
          }
        },
        y: {
          tick: {
            format: yTickFormat
          }
        }
      },
      data: {
        x: "date",
        columns,
        type: "bar",

        // Pass all the keys together as a group so that it stacks them.
        groups
      },
      tooltip: {
        contents: tooltipContents,
        format: {
          value: formatTooltipValue
        }
      }
    });
  }

  componentWillUnmount() {
    this.chart = this.chart.destroy();
  }

  render() {
    return <div id="chart" />;
  }
}
