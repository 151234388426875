import PropTypes from "prop-types";
import { any, map, merge, prop, reject } from "ramda";
import React from "react";

import Icon from "../../../components/Icon";

import { formatDataStreamMetadata } from "../../../modules/Metadata";

const anyAdditional = any(prop("additional"));

export default class EquipmentInfo extends React.PureComponent {
  static propTypes = {
    dataStream: PropTypes.object.isRequired,
    image: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    moreEquipmentInfo: PropTypes.bool.isRequired,
    site: PropTypes.object.isRequired,
    toggleEquipmentInfo: PropTypes.func.isRequired
  };

  getMetadata = () => {
    const { dataStream, moreEquipmentInfo } = this.props;

    var { metadata_fields } = dataStream;

    metadata_fields = map(
      m =>
        merge(
          {
            value: dataStream.metadata[m.name]
          },
          m
        ),
      metadata_fields
    );

    if (moreEquipmentInfo) {
      return metadata_fields;
    }

    return reject(prop("additional"), metadata_fields);
  };

  renderMetadata = () => {
    const { dataStream } = this.props;

    return (
      <dl>
        <dt>Equipment Type</dt>
        <dd>{dataStream.metadata_label || "N/A"}</dd>
        {this.getMetadata().map((item, index) => [
          <dt key={`${index}l`}>{item.label}</dt>,
          <dd key={`${index}v`}>
            {item.value ? formatDataStreamMetadata(item) : "N/A"}
            {item.checked && <Icon name="check-square" />}
          </dd>
        ])}
      </dl>
    );
  };

  render() {
    const { dataStream, site } = this.props;

    const { project_id } = site;
    const edit_path =
      `/admin/data_streams?data_stream_id=${dataStream.id}` +
      `&project_id=${project_id}`;
    return (
      <div className="equip-sys__equipment-info">
        <header>
          <h3>{dataStream.name}</h3>
          {this.props.isAdmin && <a href={edit_path}>Edit</a>}
        </header>
        {this.renderMetadata()}
        {anyAdditional(dataStream.metadata_fields) && (
          <footer>
            <a
              className="equip-sys__equipment-info__toggle"
              onClick={this.props.toggleEquipmentInfo}
            >
              View Additional Information
            </a>
          </footer>
        )}
      </div>
    );
  }
}
