import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";

import request from "superagent";

import Site from "./Site";

export default class extends React.Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    interval: PropTypes.number.isRequired,
    sitesURL: PropTypes.string.isRequired
  };

  static childContextTypes = {
    authToken: PropTypes.string.isRequired
  };

  state = {
    loaded: false,
    sites: []
  };

  getChildContext() {
    const { authToken } = this.props;

    return { authToken };
  }

  componentDidMount() {
    const { sitesURL, authToken } = this.props;

    request(sitesURL)
      .setAuthToken(authToken)
      .then(req => {
        this.setState({
          loaded: true,
          sites: req.body.sites
        });
      });
  }

  render() {
    const { loaded, sites } = this.state;
    const { authToken, interval } = this.props;

    if (!loaded) {
      return <div />;
    }

    const siteElements = sites.map(site => {
      var startTime, endTime;
      var props = { site, authToken, interval };

      const { start_data_limit, end_data_limit } = site;

      endTime = (end_data_limit ? moment(end_data_limit) : moment()).startOf(
        "hour"
      );

      startTime = endTime.clone().subtract(7, "days");

      if (start_data_limit && startTime.isBefore(start_data_limit)) {
        startTime = moment(start_data_limit);
      }

      props.startTime = startTime.unix();
      props.endTime = endTime.unix();

      return <Site key={site.id} {...props} />;
    });

    return <div className="row">{siteElements}</div>;
  }
}
