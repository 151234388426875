import PropTypes from "prop-types";
import {
  isEmpty,
  pipe,
  prop,
  values,
  all,
  pick,
  ifElse,
  always,
  filter,
  contains,
  identity,
  flatten,
  map,
  join
} from "ramda";
import React from "react";

import moment from "moment-timezone";

import ChartDatepicker from "../../../skeletons/dataVisualization/components/ChartDatepicker";
import Icon from "../../../components/Icon";
import IntervalSelector from "../../../components/IntervalSelector";
import Spinner from "../../../components/Spinner";
import ZoomSelector from "../../../components/ZoomSelector";

import GraphSelector from "./GraphSelector";
import GraphTable from "./GraphTable";
import GraphChart from "./GraphChart";

import { stringifyQueryOptions } from "../../../modules/URLHelpers";

const graphBlank = pipe(
  prop("values"),
  isEmpty
);

const allGraphsBlank = pipe(
  values,
  all(graphBlank)
);

import { DATEPICKER_FORMAT } from "../../../modules/TimeFormats";
import { getStartMoment } from "../../../skeletons/dataVisualization/modules/ComputedAttributes";

const styles = {
  dataHeader: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "10px"
  },
  inlineBlock: {
    display: "inline-block",
    verticalAlign: "top"
  },
  simulationLabel: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "5px",
    fontSize: "10px",
    fontWeight: "500",
    color: "#444"
  },
  visualizationLink: {
    display: "inline-block",
    verticalAlign: "top",
    paddingLeft: "15px"
  }
};

export default class Visualization extends React.PureComponent {
  static propTypes = {
    activatedDataStreamIDs: PropTypes.array.isRequired,
    connectNull: PropTypes.bool.isRequired,
    endTimeBound: PropTypes.object,
    equipment: PropTypes.object.isRequired,
    graphs: PropTypes.object,
    interval: PropTypes.object.isRequired,
    setInterval: PropTypes.func.isRequired,
    setStartTime: PropTypes.func.isRequired,
    setZoom: PropTypes.func.isRequired,
    sortDataStreams: PropTypes.func.isRequired,
    sortDataStreamsBy: PropTypes.array.isRequired,
    startTime: PropTypes.string.isRequired,
    startTimeBound: PropTypes.object,
    timeZone: PropTypes.string.isRequired,
    toggleDataStream: PropTypes.func.isRequired,
    urls: PropTypes.object.isRequired,
    zoomSetting: PropTypes.object.isRequired
  };

  renderChart = () => {
    const { props } = this;

    const {
      graphs,
      interval,
      activatedDataStreamIDs,
      startTime,
      timeZone,
      connectNull,
      zoomSetting
    } = props;

    if (!graphs) {
      return <span />;
    }

    const activatedGraphs = pick(activatedDataStreamIDs, graphs);

    if (isEmpty(activatedGraphs) || allGraphsBlank(activatedGraphs)) {
      return (
        <div className="info-alert">No data available in this time range.</div>
      );
    }

    const startMoment = getStartMoment({
      startTime,
      site: { time_zone: timeZone }
    });

    const selectedGraphs = pipe(
      pick(activatedDataStreamIDs),
      values
    )(graphs);

    return (
      <GraphChart
        graphs={selectedGraphs}
        interval={interval}
        startMoment={startMoment}
        timeZone={timeZone}
        zoomSetting={zoomSetting}
        connectNull={connectNull}
      />
    );
  };

  dataVisualizationURL = () => {
    const {
      urls,
      interval,
      graphs,
      activatedDataStreamIDs,
      startTime,
      timeZone,
      zoomSetting
    } = this.props;

    if (!graphs) {
      return "#";
    }

    const streamIDs = ifElse(
      isEmpty,
      always([]),
      pipe(
        values,
        filter(s => contains(s.id, activatedDataStreamIDs)),
        map(prop("id"))
      )
    )(graphs);

    const startTimeUnix = moment
      .tz(startTime, DATEPICKER_FORMAT, timeZone)
      .unix();

    const queryOptions = {
      dataStreamIDs: join(",", streamIDs),
      startTime: startTimeUnix,
      zoom: zoomSetting.id,
      interval: interval.id
    };

    return `${urls.dataVisualization}?${stringifyQueryOptions(queryOptions)}`;
  };

  render() {
    const {
      interval,
      setInterval,
      setStartTime,
      setZoom,
      startTime,
      zoomSetting,
      startTimeBound,
      endTimeBound,
      timeZone,
      equipment,
      graphs,
      toggleDataStream,
      activatedDataStreamIDs,
      sortDataStreams,
      sortDataStreamsBy,
      dataStreams
    } = this.props;

    const visibleIDs = pipe(
      filter(identity),
      flatten,
      map(prop("id"))
    )([dataStreams]);

    return (
      <div className="equip-sys__visualization">
        <header>
          <div className="equip-sys__visualization__right-tools">
            <a
              href={this.dataVisualizationURL()}
              style={styles.visualizationLink}
              target="_blank"
              title="Open visualization in new window."
            >
              <Icon name="external-link" />
            </a>
          </div>
          <div className="equip-sys__visualization__left-tools">
            <ChartDatepicker
              startTime={startTime}
              setStartTime={setStartTime}
              startTimeBound={startTimeBound}
              endTimeBound={endTimeBound}
              timeZone={timeZone}
            />
            <div style={styles.inlineBlock}>
              <ZoomSelector setZoom={setZoom} zoomSetting={zoomSetting} />
            </div>
            <div style={styles.inlineBlock}>
              <IntervalSelector
                interval={interval}
                setInterval={setInterval}
                zoomSetting={zoomSetting}
              />
            </div>
          </div>
        </header>
        <section>
          <div className="equip-sys__visualization__main">
            {this.renderChart()}
          </div>
          <div className="equip-sys__visualization__right">
            <h3 style={styles.dataHeader}>Data</h3>
            <GraphSelector
              equipment={equipment}
              graphs={values(graphs)}
              dataStreams={dataStreams}
              toggleDataStream={toggleDataStream}
              activatedDataStreamIDs={activatedDataStreamIDs}
            />
          </div>
          <div className="equip-sys__visualization__bottom">
            <GraphTable
              graphs={values(graphs)}
              toggleDataStream={toggleDataStream}
              activatedDataStreamIDs={activatedDataStreamIDs}
              sortDataStreams={sortDataStreams}
              sortDataStreamsBy={sortDataStreamsBy}
              visibleIDs={visibleIDs}
            />
          </div>
          {!graphs && (
            <div className="equip-sys__visualization__loading">
              <Spinner />
            </div>
          )}
        </section>
      </div>
    );
  }
}
