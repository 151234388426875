export const notify = err => {
  if (window.Bugsnag) {
    window.Bugsnag.notify({
      name: "Elm Error",
      message: err
    });
  } else {
    console.log("Elm Error", err);
  }
};
