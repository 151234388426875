import { SELECT_ALERT, SELECT_DATA_STREAM } from "../constants/actionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case SELECT_DATA_STREAM:
      return null;
    case SELECT_ALERT:
      return action.id;
    default:
      return state;
  }
};
