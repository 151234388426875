import PropTypes from "prop-types";
import React from "react";

import { isNumeric } from "../../../modules/NumberHelpers";

const unitTypes = {
  "watts per square meter": " W/m2",
  "millimeters per hour": " mm/h",
  "relative humidity": " RH",
  millibars: " mb",
  percent: "%",
  fahrenheit: " °F"
};

export default class extends React.Component {
  static propTypes = {
    isSelected: PropTypes.bool.isRequired,
    stream: PropTypes.object.isRequired,
    toggleDataStream: PropTypes.func.isRequired
  };

  handleClick = () => {
    const { toggleDataStream, stream } = this.props;

    toggleDataStream(stream);
  };

  render() {
    const { stream, isSelected } = this.props;

    const { name, max, median, average, min, unitType } = stream;

    const formatNumber = number => {
      if (isNumeric(number)) {
        const unit = unitTypes[unitType] || ` ${unitType}`;
        return `${number.toFixed(1)}${unit}`;
      }

      return "";
    };

    return (
      <tr onClick={this.handleClick} className={isSelected ? "selected" : ""}>
        <td>{name}</td>
        <td>{formatNumber(median)}</td>
        <td>{formatNumber(average)}</td>
        <td>{formatNumber(max)}</td>
        <td>{formatNumber(min)}</td>
      </tr>
    );
  }
}
