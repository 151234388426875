import { equals, values } from "ramda";
import request from "superagent";
import Promise from "bluebird";

import { arrayMin, arrayMax } from "../../../modules/ArrayHelpers";
import { streamDataToMap } from "../../../modules/StreamHelpers";

export default class StreamClient {
  constructor(stream, options) {
    this._stream = stream;
    this._unitType = stream.unit_type;
    this._name = stream.name;

    this._authToken = options.authToken;
  }

  fetch(requestParams) {
    // If this request has already been made, return the result as a promise.
    if (equals(requestParams, this.lastRequestParams)) {
      return new Promise(resolve => resolve(this.row));
    }

    // Otherwise, store the params so that we can reuse this data if the chart
    // is adding/deleting rows later, and return the request promise.
    this.lastRequestParams = requestParams;

    const paramsSignature = `${requestParams.interval}-${requestParams.start_time}`;

    const url = `https://api.bractlet.com/timescale/${this._stream.id}`;

    return request
      .get(url)
      .setAuthToken(this._authToken)
      .query(requestParams)
      .then(resp => {
        const { data_stream } = resp.body;

        const mappedValues = streamDataToMap(data_stream.data),
          v = values(mappedValues);

        this.row = {
          paramsSignature,
          values: v,
          id: this._stream.id,
          name: this._name,
          unitType: this._unitType,
          data: mappedValues,
          max: arrayMax(v),
          min: arrayMin(v)
        };

        return this.row;
      });
  }
}
