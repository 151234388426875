// This can be passed as the second argument to `then` calls on superagent
// promises for requests we never expect to fail. So, for example, this should
// not be used when we expect that the user could submit a form in an invalid
// way because we don't want that getting sent to Bugsnag.
export default err => {
  const { Bugsnag } = global;

  // If for some reason Bugsnag wasn't loaded, don't try to notify it.
  if (Bugsnag) {
    Bugsnag.notify({
      name: err,
      message: "handledUnexpectedError"
    });
  } else {
    console.log(err);
  }
};
