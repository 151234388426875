import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";

import LoadingMessage from "../../../components/LoadingMessage";

import DataMining from "../components/DataMining";
import Visualization from "../components/Visualization";

import signatureAuthor from "../../../skeletons/dataVisualization/lib/signatureAuthor";
import { getEquipment } from "../../../skeletons/dataVisualization/lib/ComputedAttributes";

import Alerts from "../../../../../elm/Alerts.elm";
import Elm from "react-elm-components";

class Interface extends React.Component {
  static propTypes = {
    activatedDataStreamIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    authToken: PropTypes.string.isRequired,
    connectNull: PropTypes.bool.isRequired,
    currentUserID: PropTypes.number.isRequired,
    dataStreams: PropTypes.arrayOf(PropTypes.object),
    endTimeBound: PropTypes.object,
    initialLoad: PropTypes.func.isRequired,
    interval: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    moreEquipmentInfo: PropTypes.bool.isRequired,
    navigateToDataStream: PropTypes.func.isRequired,
    plotRuns: PropTypes.arrayOf(PropTypes.object).isRequired,
    plotRunsLoaded: PropTypes.bool.isRequired,
    selectPlotRun: PropTypes.func.isRequired,
    selectedPlotRun: PropTypes.number,
    setInterval: PropTypes.func.isRequired,
    setStartTime: PropTypes.func.isRequired,
    setZoom: PropTypes.func.isRequired,
    site: PropTypes.object,
    sortDataStreams: PropTypes.func.isRequired,
    sortDataStreamsBy: PropTypes.array.isRequired,
    startTime: PropTypes.string,
    startTimeBound: PropTypes.object,
    toggleDataStream: PropTypes.func.isRequired,
    toggleEquipmentInfo: PropTypes.func.isRequired,
    urls: PropTypes.object.isRequired,
    zoomSetting: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.initialLoad();
  }

  render() {
    const { props } = this;

    const {
      activatedDataStreamIDs,
      connectNull,
      currentUserID,
      data,
      jwt,
      authToken,
      endTimeBound,
      interval,
      plotRuns,
      plotRunsLoaded,
      selectPlotRun,
      selectedPlotRun,
      setInterval,
      setStartTime,
      setZoom,
      site,
      sortDataStreams,
      sortDataStreamsBy,
      startTime,
      startTimeBound,
      dataStreams,
      toggleDataStream,
      urls,
      offsets,
      zoomSetting
    } = props;

    if (!site || !dataStreams) {
      return <LoadingMessage loading="equipment" />;
    }

    const equipment = getEquipment(props);

    const signature = signatureAuthor(props);
    const graphs = data[signature];

    if (!site) {
      return <div />;
    }

    return (
      <div className="equip-sys__main">
        <div className="equip-sys__main__block">
          <Visualization
            activatedDataStreamIDs={activatedDataStreamIDs}
            connectNull={connectNull}
            endTimeBound={endTimeBound}
            equipment={equipment}
            graphs={graphs}
            interval={interval}
            setZoom={setZoom}
            zoomSetting={zoomSetting}
            toggleDataStream={toggleDataStream}
            setInterval={setInterval}
            sortDataStreams={sortDataStreams}
            sortDataStreamsBy={sortDataStreamsBy}
            dataStreams={dataStreams}
            startTime={startTime}
            startTimeBound={startTimeBound}
            setStartTime={setStartTime}
            timeZone={site.time_zone}
            urls={urls}
          />
        </div>

        <div className="equip-sys__main__block">
          <DataMining
            plotRuns={plotRuns}
            plotRunsLoaded={plotRunsLoaded}
            selectPlotRun={selectPlotRun}
            selectedPlotRun={selectedPlotRun}
          />
        </div>

        <div className="equip-sys__main__block">
          <Elm
            src={Alerts.Elm.Alerts}
            flags={{
              apiConfig: {
                jwt,
                authToken
              },
              siteID: site.id,
              userID: currentUserID,
              offsets,
              dataStreamID: equipment.id
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = f => f;
import mapDispatchToProps from "../actions";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Interface);
