import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import Icon from "../../../components/Icon";

export default class extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    plotRun: PropTypes.object.isRequired,
    selectPlotRun: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired
  };

  handleClick = () => {
    this.props.selectPlotRun(this.props.index);
  };

  handlePrevious = e => {
    e.stopPropagation();
    this.props.selectPlotRun(this.props.index - 1);
  };

  handleNext = e => {
    e.stopPropagation();
    this.props.selectPlotRun(this.props.index + 1);
  };

  renderPrevious = () => (
    <div
      className="equip-sys__data-mining__wrapper__plot__previous"
      onClick={this.handlePrevious}
    >
      <Icon name="arrow-left" />
    </div>
  );

  renderNext = () => (
    <div
      className="equip-sys__data-mining__wrapper__plot__next"
      onClick={this.handleNext}
    >
      <Icon name="arrow-right" />
    </div>
  );

  render() {
    const { plotRun, selected } = this.props;
    const classes = cx({
      "equip-sys__data-mining__wrapper__plot": true,
      "equip-sys__data-mining__wrapper__plot--selected": selected
    });
    return (
      <div className={classes} onClick={this.handleClick}>
        <div>{plotRun.name}</div>
        <img src={plotRun.output_png_file_url} />
        {selected && this.renderPrevious()}
        {selected && this.renderNext()}
      </div>
    );
  }
}
