import {
  curry,
  merge,
  omit,
  median,
  sum,
  set,
  lensPath,
  mean,
  values
} from "ramda";
import hsl from "hsl-to-hex";

import { CLEAR_SIGNATURE, LOAD_STREAM_DATA } from "../constants/actionTypes";

import { arrayMin, arrayMax } from "../../../modules/ArrayHelpers";
import { streamDataToMap } from "../../../modules/StreamHelpers";

const setKeyIfBlank = curry((key, state) => {
  if (state[key]) {
    return state;
  }

  return merge(state, { [key]: {} });
});

const getColorDistribution = (i, n) => hsl((i / n) * 360.0, 70, 60);

/*
The state tree looks like this: {
  '{start-time}-{time-span-in-seconds}-{interval-in-seconds}': {
    '{id}': [[timestamp, value], [timestamp, value]]
    '{id}': [[timestamp, value], [timestamp, value]]
    '{id}': [[timestamp, value], [timestamp, value]]
  }
}
*/
export default (state = {}, action) => {
  const { signature, payloads } = action;

  switch (action.type) {
    case CLEAR_SIGNATURE:
      return omit([signature], state);
    case LOAD_STREAM_DATA:
      let newState = setKeyIfBlank(signature, state);

      payloads.forEach((payload, i) => {
        const { data, stream } = payload;

        const mappedValues = streamDataToMap(data),
          v = values(mappedValues);

        const row = {
          values: v,
          id: stream.id,
          name: stream.displayName,
          unitType: stream.unitType,
          data: mappedValues,
          max: arrayMax(v),
          min: arrayMin(v),
          median: median(v),
          average: mean(v),
          total: sum(v),
          color: getColorDistribution(i, payloads.length)
        };

        newState = set(lensPath([signature, stream.id]), row, newState);
      });

      return newState;
    default:
      return state;
  }
};
