import { merge, pipe, filter, pathEq } from "ramda";
import React from "react";
import { connect } from "react-redux";

import EquipmentInfo from "../components/EquipmentInfo";
import RelatedDataStreams from "../components/RelatedDataStreams";

import actions from "../actions";

import { sortByName } from "../../../modules/Sorters";

import { getMainDataStream } from "../../../skeletons/dataVisualization/lib/ComputedAttributes";
import Ticker from "../../../../../elm/Ticker.elm";
import Elm from "react-elm-components";

const Sidebar = ({
  equipment,
  isAdmin,
  moreEquipmentInfo,
  toggleEquipmentInfo,
  navigateToDataStream,
  relatedEquipmentDataStreams,
  site,
  jwt,
  authToken,
  offsets
}) => (
  <div className="equip-sys__left">
    <div className="equip-sys__left__block">
      {equipment && site && (
        <EquipmentInfo
          dataStream={equipment}
          isAdmin={isAdmin}
          moreEquipmentInfo={moreEquipmentInfo}
          toggleEquipmentInfo={toggleEquipmentInfo}
          site={site}
        />
      )}
    </div>
    <div className="equip-sys__left__block">
      {relatedEquipmentDataStreams && site && (
        <RelatedDataStreams
          navigateToDataStream={navigateToDataStream}
          relatedEquipmentDataStreams={relatedEquipmentDataStreams}
          site={site}
        />
      )}
    </div>
    <div className="equip-sys__left__block">
      {site && (
        <Elm
          src={Ticker.Elm.Ticker}
          flags={{
            apiConfig: {
              jwt,
              authToken
            },
            siteID: site.id,
            dataStreamIDs: [equipment.id],
            isAdmin,
            offsets
          }}
        />
      )}
    </div>
  </div>
);

export default connect(
  state => {
    const {
      isAdmin,
      moreEquipmentInfo,
      site,
      dataStreams,
      authToken,
      jwt,
      offsets
    } = state;

    const initial = {
      moreEquipmentInfo,
      site,
      dataStreams,
      authToken,
      jwt,
      isAdmin,
      offsets
    };

    if (!dataStreams) {
      return initial;
    }

    const equipmentStream = getMainDataStream(state);

    const relatedEquipmentDataStreams = pipe(
      filter(pathEq(["stream_type"], "equipment")),
      sortByName
    )(equipmentStream.related_data_streams);

    return merge(initial, {
      equipment: equipmentStream,
      relatedEquipmentDataStreams
    });
  },
  actions
)(Sidebar);
