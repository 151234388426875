import PropTypes from "prop-types";
import React from "react";

import { intervalsAndRowsToC3Columns } from "../../../modules/C3Helpers";
import {
  formatDateForTooltip,
  formatNumberWithUnitType
} from "../../../skeletons/dataVisualization/modules/Chart";

import RawChart from "../../../components/RawChart";

export default class extends React.Component {
  static propTypes = {
    alert: PropTypes.object,
    data: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    intervals: PropTypes.array.isRequired,
    timeZone: PropTypes.string.isRequired,
    unitType: PropTypes.string.isRequired
  };

  getYLines = () => {
    const { alert } = this.props;

    return alert
      ? [{ value: alert.metadata.condition.value, class: "alert" }]
      : [];
  };

  getSettings = () => {
    const { data, intervals, timeZone, unitType } = this.props;

    return {
      axis: {
        x: {
          type: "timeseries",
          show: false
        },
        y: {
          padding: 0,
          show: false,
          min: 0,
          max: Math.ceil(data.max)
        }
      },
      data: {
        x: "x",
        type: "area",
        columns: intervalsAndRowsToC3Columns(intervals, [data])
      },
      legend: {
        hide: true
      },
      padding: {
        left: -7,
        right: 3,
        bottom: -27
      },
      point: {
        show: true,
        r: 0,
        focus: {
          expand: {
            enabled: true,
            r: 3
          }
        }
      },
      size: {
        width: 560,
        height: 200
      },
      grid: {
        focus: {
          show: false
        },
        y: {
          lines: this.getYLines()
        }
      },
      tooltip: {
        format: {
          title: formatDateForTooltip(timeZone),
          value: rawValue => formatNumberWithUnitType(rawValue, unitType)
        }
      }
    };
  };

  render() {
    return <RawChart id={this.props.id} settings={this.getSettings()} />;
  }
}
