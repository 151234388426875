import { pipe, identity, reject, merge, map } from "ramda";
import { LOAD_DATA_STREAMS_SUCCESS } from "../constants/actionTypes";

import NameRegister from "../../../skeletons/dataVisualization/models/NameRegister";

export default (state = null, action) => {
  switch (action.type) {
    case LOAD_DATA_STREAMS_SUCCESS:
      const nameRegister = new NameRegister();

      return pipe(
        action.keepBreakerlessEquipment
          ? identity
          : reject(
              dataStream =>
                dataStream.breakers && dataStream.breakers.length === 0
            ),
        map(dataStream => {
          const name = nameRegister.add(dataStream.name);

          return merge(dataStream, {
            displayName: name,
            unitType: dataStream.unit_type,
            name
          });
        })
      )(action.dataStreams);
    default:
      return state;
  }
};
