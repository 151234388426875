import { curry, ifElse, identity } from "ramda";
import moment from "moment-timezone";

import { TIME_WITH_TIME_ZONE_FORMAT } from "../../../modules/TimeFormats";

import { commaDelimitNumber, isNumeric } from "../../../modules/NumberHelpers";

const formatDateForTooltip = curry((timeZone, date) =>
  moment.tz(date, timeZone).format(TIME_WITH_TIME_ZONE_FORMAT)
);

const formatNumberWithUnitType = (number, unitType) =>
  isNumeric(number)
    ? `${commaDelimitNumber(number.toFixed(2))} ${unitType}`
    : "N/A";

const formatNumberWithoutUnitType = ifElse(
  identity,
  v => commaDelimitNumber(v.toFixed(2)),
  identity
);

export {
  formatDateForTooltip,
  formatNumberWithUnitType,
  formatNumberWithoutUnitType
};
