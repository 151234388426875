import moment from "moment-timezone";

import { defaultSetting } from "../../../constants/zoomSettings";

import { DATEPICKER_FORMAT } from "../../../modules/TimeFormats";

import { LOAD_SITE_SUCCESS, SET_START_TIME } from "../constants/actionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case SET_START_TIME:
      return moment
        .tz(action.startTime, DATEPICKER_FORMAT, action.timeZone)
        .format(DATEPICKER_FORMAT);
    case LOAD_SITE_SUCCESS:
      const { site } = action;
      const { start_data_limit, end_data_limit } = site;

      const start = start_data_limit && moment(start_data_limit);
      const end = end_data_limit && moment(end_data_limit);
      const current = state
        ? moment.tz(state, DATEPICKER_FORMAT, site.time_zone)
        : moment()
            .subtract(defaultSetting.timeSpan)
            .startOf("day");

      if (
        (start && start.isAfter(current)) ||
        (current && current.isAfter(end))
      ) {
        return start.format(DATEPICKER_FORMAT);
      }

      return current.format(DATEPICKER_FORMAT);
    default:
      return state;
  }
};
