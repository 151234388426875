import { connect } from "react-redux";
import React from "react";

import HeaderShare from "../../../components/HeaderShare";
import Icon from "../../../components/Icon";

import { getEquipment } from "../../../skeletons/dataVisualization/lib/ComputedAttributes";

const Header = ({ equipment, dashboardURL }) => {
  const title = (
    <span>
      <a href={dashboardURL}>Dashboard</a>
      <Icon name="angle-right" />
      {equipment && equipment.name}
    </span>
  );

  return <HeaderShare title={title} />;
};

export default connect(
  state => ({
    equipment: getEquipment(state),
    dashboardURL: state.urls.dashboard
  }),
  () => ({})
)(Header);
