import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

class Icon extends React.PureComponent {
  render() {
    const iconClasses = cx({
      fa: true,
      [`fa-${this.props.name}`]: true,
      [this.props.className]: !!this.props.className
    });
    return <i className={iconClasses} />;
  }
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default Icon;
