import { pipe, filter, propEq, sortBy } from "ramda";
import d3 from "d3";
import c3 from "c3";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";

const format = d3.format(",");

const startOfMonthInUnix = str =>
  moment(str)
    .startOf("month")
    .unix();

const isNumNotNull = n => n && !isNaN(+n);

const hasValue = ({ actual_rate, billed_rate }) =>
  isNumNotNull(actual_rate) || isNumNotNull(billed_rate);

export default class extends React.Component {
  static propTypes = {
    monthlyBills: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  componentDidMount() {
    const { monthlyBills } = this.props;

    const energyBills = pipe(
      filter(propEq("bill_type", "energy")),
      filter(hasValue),
      sortBy(bill => startOfMonthInUnix(bill.service_period_start))
    )(monthlyBills);

    var actual = ["Actual"],
      billed = ["Billed"],
      dates = [];

    energyBills.forEach(monthlyBill => {
      actual.push(monthlyBill.actual_rate);
      billed.push(monthlyBill.billed_rate);
      dates.push(monthlyBill.service_period_start);
    });

    const dateValues = dates.map(key => moment(key).format("YYYY-MM-DD"));

    const datesRow = ["date"].concat(dateValues);

    const columns = [datesRow, actual, billed];

    this.chart = c3.generate({
      bindto: "#chart",
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format(x) {
              return moment(x).format("MMM 'YY");
            }
          }
        },
        y: {
          tick: {
            format: v => `${format(v)} kW`
          }
        }
      },
      data: {
        x: "date",
        columns,
        type: "bar"
      },
      tooltip: {
        format: {
          value: v => `${format(v)} kW`
        }
      }
    });
  }

  componentWillUnmount() {
    this.chart = this.chart.destroy();
  }

  render() {
    return <div id="chart" />;
  }
}
