import moment from "moment-timezone";
import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";

import Spinner from "../../../components/Spinner";
import ThumbChart from "../../../components/ThumbChart";

import styles from "../styles";

import colGroup from "./colGroup";
import { propEq, find } from "ramda";
import * as T from "../types";

const stringifyDataPoint = dataPoint => {
  if (!dataPoint) {
    return "ND";
  }

  const { time, value } = dataPoint;

  const timeFormatted = moment.unix(time).format("M/D");

  return `${value.toFixed(2)} kw on ${timeFormatted}`;
};

type Props = {
  equipment: T.Equipment;
  selectEquipment: (id: number) => any;
  equipmentData: Array<T.Data>;
  loadEquipmentData: (Equipment) => any;
  equipmentMetrics: T.EquipmentMetrics;
};

type State = {
  loadTriggered: boolean;
};

export default class extends React.Component<Props, State> {
  state = {
    loadTriggered: false
  };

  handleClick = () => {
    this.props.selectEquipment(this.props.equipment.id);
  };

  handleVisibility = () => {
    const { loadTriggered } = this.state;

    const { equipment, loadEquipmentData } = this.props;

    if (loadTriggered) {
      return;
    }

    this.setState({ loadTriggered: true });

    if (equipment.breakers.length > 0) {
      loadEquipmentData(equipment);
    }
  };

  renderPowerCellContents = () => {
    const { equipmentData, equipment } = this.props;

    if (equipment.breakers.length === 0) {
      return "NM";
    }

    if (equipmentData && equipmentData.length > 0) {
      return <ThumbChart values={equipmentData} />;
    }

    if (equipmentData && equipmentData.length === 0) {
      return "ND";
    }

    return <Spinner />;
  };

  renderMetricCellContents = metric => {
    const { equipment, equipmentMetrics } = this.props;

    if (equipment.breakers.length === 0) {
      return "NM";
    }

    if (!equipmentMetrics) {
      return <Spinner />;
    }

    const point = equipmentMetrics[metric];

    return stringifyDataPoint(point);
  };

  render() {
    const { equipment } = this.props;
    const { metadata_definition } = equipment;

    return (
      <VisibilitySensor onChange={this.handleVisibility}>
        <table style={styles.equipmentTable}>
          {colGroup}
          <tbody>
            <tr style={styles.equipmentTableRow} onClick={this.handleClick}>
              <td style={styles.equipmentTableCell}>{equipment.name}</td>
              <td style={styles.equipmentTableCell}>
                {this.renderPowerCellContents()}
              </td>
              <td style={styles.equipmentTableCell}>
                {metadata_definition ? metadata_definition.label : "N/A"}
              </td>
              <td style={styles.equipmentTableCell}>
                {this.renderMetricCellContents("maximumInLastHalf")}
              </td>
              <td style={styles.equipmentTableCell}>
                {this.renderMetricCellContents("maximumOverFullTimeSpan")}
              </td>
            </tr>
          </tbody>
        </table>
      </VisibilitySensor>
    );
  }
}
