import { SET_PLOT_RUN } from "../constants/actionTypes";

export default index => (dispatch, getState) => {
  const { plotRuns } = getState();
  const selectedPlotRun = Math.max(0, Math.min(plotRuns.length, index));
  dispatch({
    type: SET_PLOT_RUN,
    selectedPlotRun
  });
};
