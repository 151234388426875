import React from "react";

export default (
  <colgroup>
    <col style={{ width: "20%" }} />
    <col style={{ width: "10%" }} />
    <col style={{ width: "16%" }} />
    <col style={{ width: "22%" }} />
    <col style={{ width: "22%" }} />
  </colgroup>
);
