import { SELECT_TAB } from "../constants/actionTypes";

export default (state = "Cost", action) => {
  switch (action.type) {
    case SELECT_TAB:
      return action.tab;
    default:
      return state;
  }
};
