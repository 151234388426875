import { pipe } from "ramda";
import moment from "moment-timezone";

import { DATEPICKER_FORMAT } from "../../../modules/TimeFormats";

import { ensureRequiredAttributesExist } from "../../../modules/StateHelpers";

export const getStartMoment = pipe(
  ensureRequiredAttributesExist(["startTime", ["site", "time_zone"]]),
  ({ startTime, site }) =>
    moment.tz(startTime, DATEPICKER_FORMAT, site.time_zone)
);

export const getEndMoment = pipe(
  ensureRequiredAttributesExist(["zoomSetting"]),
  state => {
    const { zoomSetting } = state;

    return getStartMoment(state)
      .clone()
      .add(zoomSetting.timeSpan);
  }
);
