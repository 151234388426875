import d3 from "d3";
import { prop } from "ramda";
import React from "react";
import PropTypes from "prop-types";

import Base from "./Base";

const billToValue = prop("total_cost_with_taxes");
const formatValue = d3.format("$,");

export default class extends React.Component {
  static propTypes = {
    monthlyBills: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  render() {
    const { monthlyBills } = this.props;

    return (
      <Base
        formatTooltipValue={formatValue}
        yTickFormat={formatValue}
        monthlyBills={monthlyBills}
        billToValue={billToValue}
        exclude={["simulation"]}
      />
    );
  }
}
