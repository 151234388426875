import * as PropTypes from "prop-types";
import {
  values,
  map,
  pipe,
  omit,
  toPairs,
  sortBy,
  head,
  any,
  pathSatisfies,
  equals
} from "ramda";

import * as React from "react";

import LoadingMessage from "../../../components/LoadingMessage";
import EquipmentListLegend from "./EquipmentListLegend";
import EquipmentRow from "./EquipmentRow";
import * as T from "../types";
import * as moment from "moment";

const getGroupedEquipment = pipe(
  omit(["Uncategorized Equipment"]),
  toPairs,
  sortBy(head)
);

import styles from "../styles";

import colGroup from "./colGroup";

const anyWithoutBreakers = any(
  pathSatisfies(equals(0), ["breakers", "length"])
);

type Props = {
  equipment: T.Equipment;
  equipmentData: Array<T.Data>;
  equipmentMetrics: T.EquipmentMetrics;
  loadEquipmentData: (Equipment) => any;
  wideEndTime: moment.Moment;
  wideStartTime: moment.Moment;
  selectEquipment: (number) => any;
};

export default class EquipmentList extends React.Component<Props, {}> {
  static propTypes = {
    equipment: PropTypes.object.isRequired,
    equipmentData: PropTypes.object.isRequired,
    equipmentMetrics: PropTypes.object.isRequired,
    loadEquipmentData: PropTypes.func.isRequired,
    selectEquipment: PropTypes.func.isRequired,
    wideEndTime: PropTypes.object.isRequired,
    wideStartTime: PropTypes.object.isRequired
  };

  render() {
    const {
      equipment,
      equipmentData,
      equipmentMetrics,
      loadEquipmentData,
      wideEndTime,
      wideStartTime
    } = this.props;

    if (!equipment) {
      return <LoadingMessage loading="dashboard" />;
    }

    const otherEquipment: Array<T.Equipment> =
      equipment["Uncategorized Equipment"] || [];

    const numberOfDays: number = wideEndTime.diff(wideStartTime, "days");

    const equipmentPairToHTML = pair => {
      const [groupName, equipmentList] = pair;

      const legendProps: T.LegendProps = {
        displayND: any((eq: T.Equipment) => {
          const data = equipmentData[eq.id];
          return eq.breakers.length > 0 && data && data.length === 0;
        }, equipmentList),
        displayNM: anyWithoutBreakers(equipmentList)
      };

      // @ts-ignore: TS2345: Argument of type '{ <T>(fn: (a: T) => boolean, list: ReadonlyArray<T>): boolean; <T>(fn: (a: T) => boolean): (list: ReadonlyArray<T>) => boolean; }' is not assignable to parameter of type '(x: never[]) => (list: ReadonlyArray<{}>) => boolean'.
      const displayLegend: boolean = pipe(
        // @ts-ignore
        values,
        // @ts-ignore
        any
      )(legendProps);

      return (
        <div key={groupName} style={styles.equipmentGroup}>
          <div style={styles.equipmentGroupHeader}>
            {displayLegend && <EquipmentListLegend {...legendProps} />}
            <h2 style={styles.groupName}>{groupName}</h2>
          </div>
          <div
            style={{
              paddingLeft: "15px",
              paddingTop: "15px",
              paddingRight: "15px"
            }}
          >
            <table style={styles.equipmentGroupTable}>
              {colGroup}
              <thead>
                <tr>
                  <th style={styles.equipmentTableTh}>Equipment Name</th>
                  <th style={styles.equipmentTableTh}>Power</th>
                  <th style={styles.equipmentTableTh}>Equipment Type</th>
                  <th style={styles.equipmentTableTh}>
                    {numberOfDays / 2} Day Maximum Daily Avg.
                  </th>
                  <th style={styles.equipmentTableTh}>
                    {numberOfDays} Day Maximum Daily Avg.
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={styles.equipmentTableContainer}>
            {map(
              eq => (
                <EquipmentRow
                  key={eq.id}
                  equipment={eq}
                  equipmentData={equipmentData[eq.id]}
                  equipmentMetrics={equipmentMetrics[eq.id]}
                  loadEquipmentData={loadEquipmentData}
                  selectEquipment={this.props.selectEquipment}
                />
              ),
              equipmentList
            )}
          </div>
        </div>
      );
    };

    return (
      <div>
        {map(equipmentPairToHTML, getGroupedEquipment(equipment))}
        {otherEquipment.length > 0 &&
          equipmentPairToHTML(["Other Equipment", otherEquipment])}
      </div>
    );
  }
}
