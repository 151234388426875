import PropTypes from "prop-types";
import React from "react";

export default class SelectorInput extends React.PureComponent {
  static propTypes = {
    namespace: PropTypes.string.isRequired,
    object: PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired,

    onChange: PropTypes.func.isRequired,
    selectedObject: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  };

  handleChange = () => {
    const { object } = this.props;
    this.props.onChange(object);
  };

  render() {
    const { namespace, object, selectedObject } = this.props;

    const selected = object.id === selectedObject.id;

    const htmlID = `${namespace}-${object.id}`;

    return (
      <label htmlFor={htmlID} className={selected ? "selected" : ""}>
        {object.label}
        <input
          id={htmlID}
          type="radio"
          value={object.id}
          checked={selected}
          onChange={this.handleChange}
        />
      </label>
    );
  }
}
