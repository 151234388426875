import { ifElse, always, identity, filter, contains, find } from "ramda";
import Promise from "bluebird";

import signatureAuthor from "../../lib/signatureAuthor";

import ensureStreamLoaded from "./ensureStreamLoaded";

import { ensureRequiredAttributesExist } from "../../../../modules/StateHelpers";

import { CLEAR_SIGNATURE, LOAD_STREAM_DATA } from "../../constants/actionTypes";

const MAX_CACHED_SIGNATURES = 10;

export default (dispatch, getState) => {
  const state = getState();

  ensureRequiredAttributesExist(
    ["activatedDataStreamIDs", "dataStreams"],
    state
  );

  const { activatedDataStreamIDs, preloadAll, dataStreams } = state;

  const activeDataStreams = ifElse(
    always(preloadAll),
    identity,
    filter(stream => contains(stream.id, activatedDataStreamIDs))
  )(dataStreams || []);

  Promise.map(activeDataStreams, ensureStreamLoaded(dispatch, getState)).then(
    responses => {
      // Some responses may be undefined as they have been loaded already.
      const payloads = filter(identity, responses);

      if (payloads.length > 0) {
        const signature = signatureAuthor(state);

        dispatch({
          type: LOAD_STREAM_DATA,
          signature,
          payloads
        });

        const { signatureHistory } = getState();

        // Make sure we don't leak memory.
        if (signatureHistory.length > MAX_CACHED_SIGNATURES) {
          dispatch({
            type: CLEAR_SIGNATURE,
            signature: find(s => s !== signature, signatureHistory)
          });
        }
      }
    }
  );
};
