import PropTypes from "prop-types";
import React from "react";

import Cost from "./BillGraphs/Cost";
import Consumption from "./BillGraphs/Consumption";
import Demand from "./BillGraphs/Demand";

export default class extends React.Component {
  static propTypes = {
    monthlyBills: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedTab: PropTypes.string.isRequired
  };

  render() {
    const { monthlyBills, selectedTab } = this.props;

    switch (selectedTab) {
      case "Cost":
        return <Cost monthlyBills={monthlyBills} />;
      case "Consumption":
        return <Consumption monthlyBills={monthlyBills} />;
      case "Demand":
        return <Demand monthlyBills={monthlyBills} />;
    }
  }
}
