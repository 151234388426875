import { SELECT_ALERT, SELECT_DATA_STREAM } from "../constants/actionTypes";

import loadDataStreamData from "./subactions/loadDataStreamData";
import { signatureAuthor } from "../modules/caching";

import {
  getStartMoment,
  getEndMoment
} from "../../../skeletons/dataVisualization/modules/ComputedAttributes";

export default stream => (dispatch, getState) => {
  const { activeDataStreamID } = getState();

  dispatch({
    type: SELECT_DATA_STREAM,
    id: stream ? stream.id : null
  });

  if (stream.id === activeDataStreamID) {
    return;
  }

  return loadDataStreamData(dispatch, getState).then(() => {
    const state = getState();

    const { streamAlerts } = state;

    const startMoment = getStartMoment(state);
    const endMoment = getEndMoment(state);

    const key = signatureAuthor({
      start_time: startMoment.unix(),
      end_time: endMoment.unix(),
      data_stream_id: stream.id
    });

    const alerts = streamAlerts[key];

    if (alerts && alerts.length) {
      dispatch({
        type: SELECT_ALERT,
        id: alerts && alerts[0] && alerts[0].id
      });
    }
  });
};
