import Promise from "bluebird";
import { sortBy, pipe, prop, toLower } from "ramda";
import request from "superagent";

import {
  LOAD_SITE_SUCCESS,
  LOAD_DATA_STREAMS_SUCCESS
} from "../constants/actionTypes";

import loadDataStreamData from "./subactions/loadDataStreamData";

import handleUnexpectedError from "../../../lib/handleUnexpectedError";

const sortByName = sortBy(
  pipe(
    prop("name"),
    toLower
  )
);

export default () => (dispatch, getState) => {
  const { authToken, jwt, siteID, siteURL, dataStreamsURL } = getState();

  return (
    Promise.all([
      // Load the site.
      request
        .get(siteURL)
        .setAuthToken(authToken)
        .then(
          resp =>
            dispatch({
              type: LOAD_SITE_SUCCESS,
              site: resp.body.site
            }),
          handleUnexpectedError
        ),

      // Load the site's streams.
      request
        .get(dataStreamsURL)
        .setJWT(jwt)
        .query({
          site_id: `eq.${siteID}`,
          stream_type: "eq.equipment",
          select: "id,name,stream_type,unit_type"
        })
    ])
      // This needs to happen after the site and the streams are loaded.
      .then(responses => {
        const resp = responses[1];
        const data_streams = sortByName(resp.body);

        dispatch({
          type: LOAD_DATA_STREAMS_SUCCESS,
          data_streams
        });

        return loadDataStreamData(dispatch, getState);
      })
  );
};
