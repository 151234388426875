import $ from "jquery";

export default () => {
  const dismissClick = () => {
    $("#ie-message").remove();
    document.cookie = "dismissIEMessage=true;path=/";
    return false;
  };

  const detectIE = () => {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    const edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // other browser
    return false;
  };

  $(document).ready(() => {
    const ieVersion = detectIE();

    const cookies = document.cookie;
    const dismiss = cookies.indexOf("dismissIEMessage") > -1;

    if (!ieVersion || dismiss) {
      $("#ie-message").remove();
    } else if (ieVersion <= 11) {
      $("#ie-message").removeClass("hidden");
      $("#ie-message-button").click(dismissClick);
    }
  });
};
