import { TOGGLE_EQUIPMENT_INFO } from "../constants/actionTypes";

export default (state = false, action) => {
  switch (action.type) {
    case TOGGLE_EQUIPMENT_INFO:
      return !state;
    default:
      return state;
  }
};
