import { SET_INTERVAL } from "../constants/actionTypes";

import ensureStreamsLoaded from "./subactions/ensureStreamsLoaded";

export default interval => (dispatch, getState) => {
  dispatch({
    type: SET_INTERVAL,
    interval
  });

  ensureStreamsLoaded(dispatch, getState);
};
