import PropTypes from "prop-types";
import React from "react";
import moment from "moment-timezone";
import cx from "classnames";

import Icon from "../../../components/Icon";

export default class extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    alert: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      occurred_at: PropTypes.string.isRequired
    }).isRequired,
    selectAlert: PropTypes.func.isRequired,
    timeZone: PropTypes.string.isRequired
  };

  handleClick = () => {
    const { alert, selectAlert } = this.props;

    selectAlert(alert.id);
  };

  render() {
    const { active, alert, timeZone } = this.props;

    const classes = cx({
      "tr--active": active
    });

    return (
      <tr className={classes}>
        <td>{moment.tz(alert.occurred_at, timeZone).format("MM/DD")}</td>
        {false && <td>{alert.type}</td>}
        <td>
          {alert.data_stream.name} {alert.description}
        </td>
        <td>
          <a onClick={this.handleClick}>
            Show <Icon name="angle-right" />
          </a>
        </td>
      </tr>
    );
  }
}
