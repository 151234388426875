import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

export default class extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    note: PropTypes.string,
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  };

  render() {
    const { color, note, title, value } = this.props;

    const noteClasses = cx({
      summary__note: true,
      [`summary__note--${color}`]: !!color
    });

    return (
      <div className="summary__panel">
        <h1 className="summary__header">{title}</h1>
        <div className="summary__value">{value}</div>
        {note && color && <div className={noteClasses}>{note}</div>}
      </div>
    );
  }
}
