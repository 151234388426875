import { SET_PLOT_RUN } from "../constants/actionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case SET_PLOT_RUN:
      return action.selectedPlotRun === state ? null : action.selectedPlotRun;
    default:
      return state;
  }
};
