import { curry, path } from "ramda";
import Promise from "bluebird";

import request from "superagent";

import {
  getStartMoment,
  getEndMoment
} from "../../../../skeletons/dataVisualization/modules/ComputedAttributes";

import handleUnexpectedError from "../../../../lib/handleUnexpectedError";
import signatureAuthor from "../../lib/signatureAuthor";

import { ensureRequiredAttributesExist } from "../../../../modules/StateHelpers";

export default curry((dispatch, getState, stream) => {
  const state = getState();

  ensureRequiredAttributesExist(["authToken", "data", "interval"], state);

  const { authToken, data, interval } = state;

  const signature = signatureAuthor(state);

  if (path([signature, stream.id], data)) {
    return new Promise(resolve => resolve());
  }

  const startMoment = getStartMoment(state),
    endMoment = getEndMoment(state);

  // TODO: this should always be provided by the API
  const streamURL =
    stream.url || `https://api.bractlet.com/timescale/${stream.id}`;

  return request(streamURL)
    .query({
      start_time: startMoment.unix(),
      end_time: endMoment.unix(),
      interval: interval.asSeconds()
    })
    .setAuthToken(authToken)
    .then(resp => {
      const { body } = resp;

      if (body.stream) {
        return {
          data: body.stream.data,
          stream,
          source: "stream"
        };
      } else if (body.data_stream) {
        return {
          data: body.data_stream.data,
          stream,
          source: "data-stream"
        };
      }
    }, handleUnexpectedError);
});
