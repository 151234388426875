import request from "superagent";

request.Request.prototype.setAuthToken = function(token) {
  return this.set("Authorization", `Token token="${token}"`);
};

request.Request.prototype.setJWT = function(token) {
  return this.set("Authorization", `Bearer ${token}`);
};

export default request;
