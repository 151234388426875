import PropTypes from "prop-types";
import { contains, pipe, filter, sortBy, identity, reverse, prop } from "ramda";
import React from "react";

import GraphTableRow from "./GraphTableRow";
import GraphTableHeader from "./GraphTableHeader";

const styles = {
  smallCol: {
    width: "100px"
  }
};

export default class GraphTable extends React.PureComponent {
  static propTypes = {
    activatedDataStreamIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    graphs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        median: PropTypes.number,
        average: PropTypes.number,
        max: PropTypes.number,
        min: PropTypes.number
      })
    ),
    sortDataStreams: PropTypes.func.isRequired,
    sortDataStreamsBy: PropTypes.array.isRequired,
    toggleDataStream: PropTypes.func.isRequired,
    visibleIDs: PropTypes.arrayOf(PropTypes.number).isRequired
  };

  renderRow = (item, index) => {
    const { activatedDataStreamIDs, toggleDataStream } = this.props;

    return (
      <GraphTableRow
        key={index}
        stream={item}
        isSelected={contains(item.id, activatedDataStreamIDs)}
        toggleDataStream={toggleDataStream}
      />
    );
  };

  renderHeader = (name, label) => {
    const { sortDataStreams, sortDataStreamsBy } = this.props;
    return (
      <GraphTableHeader
        name={name}
        label={label}
        sorted={sortDataStreamsBy === name}
        sortDataStreams={sortDataStreams}
      />
    );
  };

  render() {
    const { visibleIDs, sortDataStreamsBy, graphs } = this.props;

    if (!graphs) {
      return <span />;
    }

    const graphAdjuster = pipe(
      filter(g => contains(g.id, visibleIDs)),
      sortBy(prop(sortDataStreamsBy[0])),
      sortDataStreamsBy[1] ? identity : reverse
    );

    return (
      <div className="equip-sys__visualization__graph-table">
        <table>
          <colgroup>
            <col />
            <col style={styles.smallCol} />
            <col style={styles.smallCol} />
            <col style={styles.smallCol} />
            <col style={styles.smallCol} />
            <col style={styles.smallCol} />
          </colgroup>
          <thead>
            <tr>
              {this.renderHeader("label", "Data Name")}
              {this.renderHeader("median", "Median")}
              {this.renderHeader("average", "Average")}
              {this.renderHeader("max", "Max")}
              {this.renderHeader("min", "Min")}
            </tr>
          </thead>
          <tbody>{graphAdjuster(graphs).map(this.renderRow)}</tbody>
        </table>
      </div>
    );
  }
}
