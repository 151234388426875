import { SORT_DATA_STREAMS } from "../constants/actionTypes";

// State is a tuple of [property, ascending]
export default (state = ["label", true], action) => {
  switch (action.type) {
    case SORT_DATA_STREAMS:
      const { sortStreamsBy } = action;
      const [currentProperty, currentAscending] = state;

      return [
        sortStreamsBy,
        currentProperty === sortStreamsBy ? !currentAscending : true
      ];
    default:
      return state;
  }
};
