import { merge } from "ramda";
import { LOAD_STREAM_DATA } from "../constants/actionTypes";

export default (state = {}, action) => {
  const { data } = action;

  switch (action.type) {
    case LOAD_STREAM_DATA:
      return merge(state, data);
    default:
      return state;
  }
};
