import PropTypes from "prop-types";
import { find, propEq } from "ramda";
import React from "react";

export default class extends React.Component {
  static propTypes = {
    activeSiteID: PropTypes.number.isRequired
  };

  render() {
    const { activeSiteID, sites } = this.props;

    if (sites.length === 1) {
      return <span className="breadcrumbs__site-name">{sites[0].name}</span>;
    }

    const matchesCurrentSite = propEq("id", activeSiteID);

    const currentSite = find(matchesCurrentSite, sites);

    return (
      <span className="dropdown">
        <a
          className="dropdown-toggle breadcrumbs__site-name"
          data-toggle="dropdown"
          href="#"
          role="button"
        >
          {currentSite.name} <span className="caret" />
        </a>
        <span className="dropdown-menu breadcrumbs__menu">
          {sites.map(site => {
            const url = location.href.replace(/sites\/\d+/, `sites/${site.id}`);
            return (
              <ul key={site.id}>
                <a className="dropdown-header" href={url}>
                  {site.name}
                </a>
              </ul>
            );
          })}
        </span>
      </span>
    );
  }
}
