import React from "react";
import { render } from "react-dom";

import { Provider } from "react-redux";
import { createAppStore } from "../../modules/ReduxHelpers";

import rootReducer from "./reducers";

import Header from "./containers/Header";
import Interface from "./containers/Interface";
import Sidebar from "./containers/Sidebar";

export default props => {
  render(
    <Provider store={createAppStore(rootReducer, props)}>
      <div className="narrow-layout">
        <Header />
        <Sidebar />
        <Interface />
      </div>
    </Provider>,
    document.getElementById("equipment-and-systems-interface")
  );
};
