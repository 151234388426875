import PropTypes from "prop-types";
import React from "react";

import Spinner from "./Spinner";

export default class extends React.Component {
  static propTypes = {
    loading: PropTypes.string.isRequired
  };

  render() {
    return (
      <div className="loading-message">
        <Spinner /> Loading {this.props.loading}&hellip;
      </div>
    );
  }
}
