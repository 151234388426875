import PropTypes from "prop-types";
import React from "react";

const listStyle = {
  float: "right",
  fontSize: "11px",
  marginTop: "-11px"
};

export default class extends React.Component {
  static propTypes = {
    displayND: PropTypes.bool.isRequired,
    displayNM: PropTypes.bool.isRequired
  };

  render() {
    const { displayND, displayNM } = this.props;

    return (
      <ul style={listStyle}>
        {displayND && <li>ND: No data</li>}
        {displayNM && <li>NM: Not metered</li>}
      </ul>
    );
  }
}
