import { path } from "ramda";
import moment from "moment-timezone";
import { LOAD_SITE_SUCCESS } from "../constants/actionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case LOAD_SITE_SUCCESS:
      const startDataLimit = path(["site", "start_data_limit"], action);

      return startDataLimit ? moment(startDataLimit) : state;
    default:
      return state;
  }
};
